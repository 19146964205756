const deviceType = 'web';
const appVersion = '2.2.5';
const apiVersion = '2';
const resetAfterWaitSeconds = 15;

const storeKeyAuthToken = '@CriaStore:authToken';
const storeKeyRefreshToken = '@CriaStore:refreshToken';
const storeKeyConfirmationCodeToken = '@CriaStore:confirmationCodeToken';

const storeKeyConfirmationCodeSendMailAddress =
  '@CriaStore:confirmationCodeSendMailAddress';
const storeKeyConfirmationCodeSendPhoneNumber =
  '@CriaStore:confirmationCodeSendPhoneNumber';

const storeKeyRecreatablesAt = '@CriaStore:recreatablesAt';
const storeKeyInstanceID = '@CriaStore:instanceID';
const storeKeyAppPassword = '@CriaStore:appPassword';
const storeKeyLanguage = '@CriaStore:language';
const storeKeySelectedFranchiseStore = '@CriaStore:selectedFranchiseStore';
const storeShowTutorial = '@CriaStore:showTutorial';

const storeKeyRequestCustomerNoDate = '@CriaStore:requestCustomerNoDate';
const storeKeyResetPasswordDate = '@CriaStore:resetPasswordDate';

const themeColor = '#4ac2b8';

const DATE_RFC2822 = 'ddd, DD MMM YYYY HH:mm:ss ZZ';
const confirmationCodeRecreateIntervalSeconds = 60;

const oemCodeNameMap = {
  1: 'cria',
  2: 'prekyu',
};

const appDisplayNameMap = {
  1: 'CRIA（クリア）',
  2: 'プリ給',
};

const appIdMap = {
  1: '1417678395',
  2: '6572287922',
};

const oemCodeName = oemCodeNameMap[process.env.REACT_APP_OEM_CODE];
const appDisplayName = appDisplayNameMap[process.env.REACT_APP_OEM_CODE];
const appId = appIdMap[process.env.REACT_APP_OEM_CODE];

const constants = {
  appDisplayName,
  appId,
  oemCodeName,
  deviceType,
  appVersion,
  apiVersion,
  storeKeyAuthToken,
  storeKeyRefreshToken,
  storeKeyInstanceID,
  storeKeyAppPassword,
  storeKeyLanguage,
  storeKeySelectedFranchiseStore,
  storeKeyRequestCustomerNoDate,
  storeKeyResetPasswordDate,
  storeShowTutorial,
  themeColor,
  storeKeyConfirmationCodeToken,
  storeKeyRecreatablesAt,
  storeKeyConfirmationCodeSendMailAddress,
  storeKeyConfirmationCodeSendPhoneNumber,
  resetAfterWaitSeconds,
  DATE_RFC2822,
  confirmationCodeRecreateIntervalSeconds,
  oemCodeNameMap,
};

export default constants;
