import {i18n} from '../../../locale/i18n';
import '../../Overlay.scss';
import './Common.scss';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import closeApplicationResult from '../../../actions/closeApplicationResult';
import {
  ApplicationSummaryState,
  IApplicationSummary,
} from '../../../reducers/applicationSummary';
import ReactModal from 'react-modal';
import WhiteOutButton from '../../../components/parts/WhiteOutButton';
import {GlobalState} from '../../../reducers/index';
import {getOemName} from '../../../util/oem';

interface DispatchProps {
  close: typeof closeApplicationResult;
}

type StatesFromReducer = ApplicationSummaryState & IApplicationSummary;
type Props = StatesFromReducer & DispatchProps;

const Result = ({close, showsApplicationResult, paymentMethodIndex}: Props) => {
  const oemName = getOemName();
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={paymentMethodIndex === 0 && showsApplicationResult}
      onRequestClose={() => {
        close();
      }}
      contentLabel='Settings'
      overlayClassName='overlay'
      className='home-result-bank'
    >
      <section>
        <h2>
          <img alt='checked' src={`/img/home/result/${oemName}/checked.svg`} />
          <p>{i18n.t('result.annotation')}</p>
        </h2>
        <p>{i18n.t('result.thanks')}</p>
        <p>{i18n.t('result.confirmation')}</p>

        <WhiteOutButton
          onClick={() => {
            close();
          }}
        >
          {'✕ ' + i18n.t('button.close')}
        </WhiteOutButton>
      </section>
    </ReactModal>
  );
};

const mapStateToProps = (state: GlobalState): StatesFromReducer => ({
  ...state.applicationSummary.applicationSummary,
  ...state.applicationSummary,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  close: () => dispatch(closeApplicationResult()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Result);
