export const INIT_PIN = 'INIT_PIN';

const initPin = (subtitle?: React.ReactElement<any>) => ({
  type: INIT_PIN as typeof INIT_PIN,
  payload: {
    subtitle,
  },
});

export type InitPinAction = ReturnType<typeof initPin>;

export default initPin;
