import {put, call, takeEvery} from 'redux-saga/effects';
import {AUTHENTICATION_FAILURE_COMPLETE} from '../actions/authFailure';
import {
  NotificationItem,
  NotificationType,
} from '../actions/fetchNotifications';
import fetchCriaNotifications, {
  FETCH_CRIA_NOTIFICATIONS,
  FETCH_CRIA_NOTIFICATIONS_SUCCESS,
  FETCH_CRIA_NOTIFICATIONS_ERROR,
} from '../actions/fetchCriaNotifications';

import getNotifications from '../api/getNotifications';
import localStorage from '../util/localStorage';
import reportError from './reportError';

function* handleFetchCriaNotifications(
  action: ReturnType<typeof fetchCriaNotifications>
) {
  try {
    const authToken: string = yield localStorage.authToken;
    const resp: {data: {cursor: string | null; items: NotificationItem[]}} =
      yield call(
        getNotifications,
        action.payload.cursor,
        authToken,
        NotificationType.cria
      );
    yield put({
      type: FETCH_CRIA_NOTIFICATIONS_SUCCESS,
      payload: {
        notificationItems: resp.data.items,
        cursor: resp.data.cursor,
      },
    });
  } catch (e: any) {
    if (e.response && e.response.status === 403) {
      yield put({
        type: AUTHENTICATION_FAILURE_COMPLETE,
      });
      return;
    }

    yield reportError(e, FETCH_CRIA_NOTIFICATIONS_ERROR);
  }
}

function* criaNotificationsSaga() {
  yield takeEvery(FETCH_CRIA_NOTIFICATIONS, handleFetchCriaNotifications);
}

export default criaNotificationsSaga;
