import {v4 as uuid} from 'uuid';
import constants from '../constants';

const localStorage = {
  set instanceID(instanceID: string) {
    window.localStorage.setItem(constants.storeKeyInstanceID, instanceID);
  },

  get instanceID(): string {
    let instanceID = window.localStorage.getItem(constants.storeKeyInstanceID);
    if (!instanceID) {
      instanceID = uuid();
      this.instanceID = instanceID;
    }

    return instanceID;
  },

  set authToken(authToken: string) {
    window.localStorage.setItem(constants.storeKeyAuthToken, authToken);
  },

  get authToken(): string {
    return window.localStorage.getItem(constants.storeKeyAuthToken) || '';
  },

  removeAuthToken() {
    window.localStorage.removeItem(constants.storeKeyAuthToken);
  },

  set refreshToken(refreshToken: string) {
    window.localStorage.setItem(constants.storeKeyRefreshToken, refreshToken);
  },

  get refreshToken(): string {
    return window.localStorage.getItem(constants.storeKeyRefreshToken) || '';
  },

  set confirmationCodeToken(confirmationCodeToken: string | null) {
    if (confirmationCodeToken) {
      window.localStorage.setItem(
        constants.storeKeyConfirmationCodeToken,
        confirmationCodeToken
      );
    } else {
      window.localStorage.removeItem(constants.storeKeyConfirmationCodeToken);
    }
  },

  get confirmationCodeToken(): string {
    return (
      window.localStorage.getItem(constants.storeKeyConfirmationCodeToken) || ''
    );
  },

  set confirmationCodeSendMailAddress(mailAddress: string | null) {
    if (mailAddress) {
      window.localStorage.setItem(
        constants.storeKeyConfirmationCodeSendMailAddress,
        mailAddress
      );
    } else {
      window.localStorage.removeItem(
        constants.storeKeyConfirmationCodeSendMailAddress
      );
    }
  },

  get confirmationCodeSendMailAddress(): string {
    return (
      window.localStorage.getItem(
        constants.storeKeyConfirmationCodeSendMailAddress
      ) || ''
    );
  },

  set confirmationCodeSendPhoneNumber(phoneNumber: string | null) {
    if (phoneNumber) {
      window.localStorage.setItem(
        constants.storeKeyConfirmationCodeSendPhoneNumber,
        phoneNumber
      );
    } else {
      window.localStorage.removeItem(
        constants.storeKeyConfirmationCodeSendPhoneNumber
      );
    }
  },

  get confirmationCodeSendPhoneNumber(): string {
    return (
      window.localStorage.getItem(
        constants.storeKeyConfirmationCodeSendPhoneNumber
      ) || ''
    );
  },

  set recreatablesAt(recreatablesAt: string | null) {
    if (recreatablesAt) {
      window.localStorage.setItem(
        constants.storeKeyRecreatablesAt,
        recreatablesAt
      );
    } else {
      window.localStorage.removeItem(constants.storeKeyRecreatablesAt);
    }
  },

  get recreatablesAt(): string {
    return window.localStorage.getItem(constants.storeKeyRecreatablesAt) || '';
  },

  removeRefreshToken() {
    window.localStorage.removeItem(constants.storeKeyRefreshToken);
  },

  set appPassword(appPassword: string) {
    window.localStorage.setItem(constants.storeKeyAppPassword, appPassword);
  },

  get appPassword(): string {
    return window.localStorage.getItem(constants.storeKeyAppPassword) || '';
  },

  removeAppPass() {
    window.localStorage.removeItem(constants.storeKeyAppPassword);
  },

  set showTutorial(showTutorial: boolean) {
    window.localStorage.setItem(
      constants.storeShowTutorial,
      showTutorial.toString()
    );
  },

  get showTutorial(): boolean {
    return !!window.localStorage.getItem(constants.storeShowTutorial);
  },

  set currentLanguageCode(currentLanguageCode: string) {
    window.localStorage.setItem(
      constants.storeKeyLanguage,
      currentLanguageCode
    );
  },

  get currentLanguageCode(): string {
    return window.localStorage.getItem(constants.storeKeyLanguage) || '';
  },

  set currentCompanyCode(companyCode: string) {
    window.localStorage.setItem(
      constants.storeKeySelectedFranchiseStore,
      companyCode
    );
  },

  get currentCompanyCode(): string {
    return (
      window.localStorage.getItem(constants.storeKeySelectedFranchiseStore) ||
      ''
    );
  },

  set resetPasswordDate(resetPasswordDate: Date | null) {
    if (resetPasswordDate) {
      window.localStorage.setItem(
        constants.storeKeyResetPasswordDate,
        resetPasswordDate.toString()
      );
    }
  },

  get resetPasswordDate(): Date | null {
    const date = window.localStorage.getItem(
      constants.storeKeyResetPasswordDate
    );

    if (date) {
      return new Date(date);
    }
    return null;
  },

  set requestCustomerNoDate(requestCustomerNoDate: Date | null) {
    if (requestCustomerNoDate) {
      window.localStorage.setItem(
        constants.storeKeyRequestCustomerNoDate,
        requestCustomerNoDate.toString()
      );
    }
  },

  get requestCustomerNoDate(): Date | null {
    const date = window.localStorage.getItem(
      constants.storeKeyRequestCustomerNoDate
    );

    if (date) {
      return new Date(date);
    }
    return null;
  },
};

export default localStorage;
