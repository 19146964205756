const dictionary = {
  common: {
    customerNumber: 'Mã Khách hàng',
    customerNumberAnd8: 'Mã Khách Hàng (8 chữ số)',
    password: 'Mật khẩu',
    version: 'Phiên bản',
    update: ' Cập nhật',
    userInfo: 'Thông tin Người dùng',
    withdrawalAmount: 'Số tiền rút',
    charge: 'Phí dịch vụ',
    systemUsageFee: 'Phí sử dụng hệ thống',
    transferFee: 'Phí chuyển tiền',
    bank: 'Ngân hàng',
    branch: 'Chi nhánh',
    accountType: 'Loại tài khoản',
    savingsAccount: 'Tài khoản Tiết kiệm',
    checkingAccount: 'Tài khoản Giao dịch',
    accountNumber: 'Số tài khoản',
    expectedTransferDate: 'Ngày chuyển tiền mong muốn',
    partnerCode: 'Mã đối tác',
    confirmationNumber: 'Mã xác nhận',
    requestNumber: 'Mã Yêu cầu',
    deadline: 'Hạn cuối rút tiền',
    yen: 'Yên',
    language: 'ngôn ngữ',
  },
  button: {
    login: 'Đăng nhập',
    send: 'Gửi',
    close: 'Đóng',
    delete: 'Xóa',
    cancel: 'Hủy',
    request: 'Yêu cầu rút tiền',
    confirm: 'Xác nhận',
    back: 'Trở lại',
    change: 'Thay đổi',
    next: 'Tiếp',
  },
  login: {
    linkCustomerNumberReminder: 'Bạn quên Mã Khách hàng?',
    linkCustomerNumberReminderTitle: 'Thông báo lại Mã Khách Hàng',
    linkPasswordReset: 'Bạn quên mật khẩu?',
    linkPasswordResetTitle: 'Cài đặt lại password',
  },
  customerNoReminder: {
    title: 'Thông báo lại Mã Khách Hàng',
    description:
      'Hướng dẫn Mã Khách hàng sẽ được gửi qua email hoặc tin nhắn SMS đã đăng kí',
    contact: 'Địa chỉ email hoặc số điện thoại',
    linkPasswordReminder: 'Click vào đây nếu bạn quên mật khẩu',
    linkLogin: 'Trở về Đăng nhập',
    failureTitle: 'Không thể gửi',
    failureDescription:
      'Nội dung đầu vào có thể không chính xác hoặc chưa được đăng ký. Xin vui lòng kiểm tra và nhập lại. Để xác nhận thông tin đăng ký, vui lòng liên hệ với công ty liên kết ',
  },
  customerNoReminderDone: {
    title: 'Đã gửi',
    description:
      'Hướng dẫn mã khách hàng đã được gửi. Nếu bạn không nhận được email hoặc tin nhắn hướng dẫn trong vài phút, xin vui lòng xác nhận lại thông tin đăng kí với công ty liên kết',
    resend: 'Gửi lại',
    linkLogin: 'Quay về màn hình đăng nhập',
  },
  passwordReset: {
    title: 'Cài đặt lại mật khẩu',
    description:
      'Xin mời nhập Mã khách hàng của bạn. Hướng dẫn cài đặt lại mật khẩu sẽ được gửi đến email hoặc tin nhắn đã đăng kí',
    linkLogin: 'Quay về màn hình đăng nhập',
    linkCustomerNumberReminder: 'Click vào đây nếu bạn quên mật khẩu',
    failureTitle: 'Mã khách hàng chưa được đăng kí',
    failureDescription:
      'Vui lòng kiểm tra và nhập lại mã số. Xin vui lòng liên hệ với công ty liên kết nếu mã số khách hàng của bạn chưa được phát hành',
  },
  passwordResetDone: {
    title: 'Đã gửi',
    description:
      'Hướng dẫn mã khách hàng đã được gửi. Nếu bạn không nhận được email hoặc tin nhắn hướng dẫn trong vài phút, xin vui lòng xác nhận lại thông tin đăng kí với công ty liên kết',
    resend: 'Gửi lại',
    linkLogin: 'Quay trở về màn hình đăng nhập',
  },
  appPassword: {
    setUp: 'Đặt mật khẩu ứng dụng',
    annotation: `Để bảo mật tốt hơn, vui lòng chọn mật khẩu cho ứng dụng này gồm 4 chữ số.${'\n'}・Bạn sẽ cần đến mật khẩu này khi đăng nhập và yêu cầu rút tiền.${'\n'}・Phương pháp xác thực sinh trắc học, nếu sử dụng, sẽ được ưu tiên.`,
    enter: 'Nhập mật khẩu ứng dụng',
    set: 'Cài mật khẩu ',
    bioAuth: 'Bạn có thể sử dụng xác nhận sinh trắc học',
    input: 'Nhập mật khẩu cho ứng dụng',
    inputPasscode: ' Nhập mật khẩu cũ',
    forgotPasscode: 'Click vào đây nếu bạn quên mật khẩu ứng dụng',
    error: 'Sai mật khẩu',
    errorInputPasscode: 'Nhập mật khẩu đã được cài đặt',
    reset: 'Cài đặt lại mật khẩu và thoát',
    resetNextLogin: 'Tự động cài đặt cho lần đăng nhập tiếp theo',
    goBack: 'Quay lại màn hình đăng nhập',
    lock: 'Mật khẩu bị khóa tạm thời do không xác thực được thông tin ',
    later: 'Quay lại màn hình đăng nhập sau 30 giây ',
    notMatch: 'Mật khẩu không khớp',
    incorrect: 'Sai mật khẩu',
    lockAnnotation:
      'Không thể xác thực. Tài khoản đã bị khóa. Vui lòng thử lại sau 30 giây.',
    resetConfirm: 'Đặt lại mật khẩu ứng dụng?',
    resetSuccess:
      'Đặt lại mật khẩu ứng dụng thành công. Vui lòng đăng nhập lại và tạo mật khẩu mới.',
  },
  fingerprint: {
    verify: 'Xác minh bằng điện thoại',
    userIdentification: 'ID người dùng',
    touch: 'Chạm vào nút cảm biến vân tay',
    notMatch: 'Không trùng khớp',
    enterAppPassword: 'Nhập mật khẩu',
  },
  application: {
    requestLimit: 'Hạn mức rút tiền',
    requestAnnotation: 'Đăng kí số tiền',
    selectAnnotation: 'Phương thức nhận tiền',
    transferToBankAccount: 'Chuyển khoản',
    receiveAtSeven: 'Nhận tại cây ATM 7-Eleven',
    receiveAtSeven2: ' Nhận tiền mặt tại ATM 7-Eleven',
    bankAccountAnnotation: 'Tiền sẽ được chuyển về tài khoản sau.',
    sevenReceiveAnnotation:
      'Nhập mã này cùng mã xác nhận (sẽ xuất hiện sau khi bạn gửi yêu cầu) vào ATM Seven Bank để nhận được tiền.',
    transferAnnotation:
      'Các yêu cầu sau 8 giờ sáng sẽ được xử lý vào ngày làm việc tiếp theo.',
    sevenAnnotation:
      'Bạn có thể xem mã xác nhận cho các yêu cầu rút tiền tại ATM 7-Eleven trong phần Lịch sử và Thông tin Chi tiết.',
    requestAdvancePayment: `Yêu cầu`,
    withCharge: 'Đã bao gồm phí',
    recipient: ' Người nhận',
    switchCompany: 'Chuyển công ty liên kết ',
    updateButtonDialog:
      'Công ty liên kết sẽ thiết lập và cập nhật hạn mức đăng kí tối đa cho bạn. Nếu hạn mức không được cập nhật ngay cả khi bạn đã ấn nút cập nhật, mời bạn vui lòng liên hệ để xác nhận lại với công ty của mình',
  },
  confirm: {
    annotation: 'Thực hiện yêu cầu thanh toán trước với nội dung như sau',
    sevenAnnotation: 'Nhận tiền mặt từ cây ATM 7-Eleven',
  },
  result: {
    annotation: 'Yêu cầu thanh toán trước đã được nhận',
    thanks: 'Cảm ơn bạn đã sử dụng dịch vụ của chúng tôi',
    confirmation:
      'Chi tiết giao dịch có thể được kiểm tra bất kì lúc nào trong mục Lịch sử',
    withdrawalMethod: 'Phương thức rút tiền',
  },
  history: {
    history: 'Lịch sử',
    totalAmount: `Tổng số tiền${'\n'}Yêu cầu Rút về`,
    requestNo: 'Mã Yêu cầu',
    amountDeducted: 'Số tiền Rút Trước từ Lương',
    details: 'Chi tiết',
    status: 'Trạng thái',
    waiting: 'Đang chờ chuyển khoản',
    reversing: 'Chuyển lỗi',
    transferComplete: 'Hoàn tất chuyển khoản',
    awating: 'Đang chờ rút tiền',
    expired: 'Quá hạn',
    withdrawalComplete: 'Hoàn tất rút tiền',
    date: 'Ngày gửi yêu cầu',
    method: 'Phương thức rút tiền',
    bank: 'Tên ngân hàng',
    branch: 'Tên chi nhánh',
    transferDate: 'Ngày chuyển khoản',
    transfer: 'Chuyển khoản ngân hàng',
    seven: 'ATM Ngân hàng 7-Eleven ',
    requestAmount: 'Số tiền thể yêu cầu',
    receivedYet: 'Có đăng kí chưa nhận tiền',
    includeFee: 'Đã bao gồm phí',
  },
  notification: {
    notice: 'Thông báo',
    detail: 'Thông tin chi tiết',
    company: 'Company',
    cria: 'CRIA',
    emptyCompany:
      'Hạn mức đăng kí tối đa sau khi cập nhật sẽ được hiển thị tại đây',
    emptyCria: 'Các thông báo từ CRIA sẽ được hiển thị tại đây',
  },
  settings: {
    settings: 'Cài đặt',
    account: 'Tài khoản',
    logout: 'Đăng xuất',
    infomation: 'Thông tin',
    withdrawalMethod: 'Cách Rút Tiền mặt từ 7-Eleven',
    termOfUse: 'Điều khoản Sử dụng',
    needAgreeWithTheTerms: 'Vui lòng đồng ý với các điều khoản sử dụng',
    agreeWithTheTermsFailure: 'sự đồng ý không thành công',
    agreeWithTheTerms: 'đồng ý',
    disagreeWithTheTerms: 'Không đồng ý',
    termOfUseFirstSentence:
      '“Điều khoản sử dụng dịch vụ “Chi trả ngay tiền lương (CRIA)” (dành cho người dùng)” này (sau đây gọi là “Điều khoản này”) quy định các điều kiện và các nội dung cần tuân thủ liên quan đến việc sử dụng dịch vụ “Chi trả ngay tiền lương (CRIA)” (sau đây gọi là “Dịch vụ này”) do Metaps Payment Inc. (sau đây gọi là “Công ty chúng tôi”) cung cấp và vận hành.Người dùng (được định nghĩa ở điều 3) sử dụng Dịch vụ này sau khi đã đồng ý với nội dung của Điều khoản này, và nội dung của Điều khoản này là nội dung tạo thành một phần các thỏa thuận về Dịch vụ này giữa người dùng và Công ty chúng tôi. ',
    termOfUses: [
      {
        title: 'Điều 1 (Áp dụng)',
        content:
          'Điều khoản này được áp dụng cho toàn bộ việc sử dụng Dịch vụ này bởi người dùng. Công ty chúng tôi có thể quy định riêng các quy định chi tiết về việc sử dụng Dịch vụ này trong phạm vi không trái với mục đích của Điều khoản này, và sau khi Hợp đồng này (định nghĩa ở Khoản 3 Điều 4) được thiết lập, các quy định chi tiết đó sẽ tạo thành một phần của Hợp đồng này.',
        list: [],
        bracketList: [],
      },
      {
        title: 'Điều 2 (Dịch vụ này)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Dịch vụ này là dịch vụ cung cấp một hệ thống để thực hiện thanh toán tiền công, v.v… mà người dùng được chi trả từ doanh nghiệp tham gia (không bao gồm trợ cấp thôi việc và các khoản trả 1 lần như tiền thưởng. Sau đây gọi là “Tiền công”) trước ngày chi trả lương thông thường, theo nguyện vọng của người dùng, trong phạm vi mà doanh nghiệp tham gia quy định, dưới dạng một phần công việc mà Công ty chúng tôi được ủy thác riêng từ doanh nghiệp tham gia (định nghĩa tại điều 3).',
          },
          {
            index: 2,
            content:
              ' Số tiền mà người dùng có thể được chi trả bởi Dịch vụ này là số tiền trong phạm vi được doanh nghiệp tham gia quy định riêng (sau đây gọi là “số tiền yêu cầu tối đa”) trong số tiền công đã phát sinh tại doanh nghiệp tham gia dưới dạng kết quả mà người dùng đã làm việc. Tiền công mà chưa được xác định là tiền công doanh nghiệp tham gia đang nợ người dùng với các lý do như chưa làm việc, v.v… thì không phải là đối tượng chi trả của Dịch vụ này.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Điều 3 (Định nghĩa)',
        content:
          'Các thuật ngữ được sử dụng trong Điều khoản này (được nêu dưới đây) có ý nghĩa như được quy định dưới đây:',
        list: [],
        bracketList: [
          {
            index: 1,
            content:
              ' “Doanh nghiệp tham gia” là doanh nghiệp đã đưa vào sử dụng Dịch vụ này bằng việc ký kết hợp đồng sử dụng Dịch vụ này với Công ty chúng tôi (sau đây gọi là “Hợp đồng doanh nghiệp tham gia”).',
          },
          {
            index: 2,
            content:
              ' “Người dùng” là cá nhân đang làm việc tại doanh nghiệp tham gia và đã hoàn thành việc đăng ký sử dụng theo điều 4, dựa trên sự phê duyệt việc sử dụng Dịch vụ này bởi doanh nghiệp tham gia.',
          },
          {
            index: 3,
            content:
              ' “Ứng dụng chỉ định” là ứng dụng máy tính để sử dụng Dịch vụ này bởi người dùng, do Công ty chúng tôi cung cấp cho người dùng.',
          },
        ],
      },
      {
        title: 'Điều 4 (Đăng ký người dùng)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Để sử dụng dịch vụ này, trước tiên, người dùng phải hoàn thành việc đăng ký người dùng. Việc đăng ký người dùng phải tuân theo thông báo tới người dùng từ Công ty chúng tôi theo chỉ định của doanh nghiệp tham gia, và được thực hiện từ ứng dụng chỉ định sau khi đã tải ứng dụng chỉ định về.',
          },
          {
            index: 2,
            content:
              ' Người thực hiện đăng ký người dùng phải gửi yêu cầu đăng ký người dùng sau khi đã đồng ý với nội dung của Điều khoản này.',
          },
          {
            index: 3,
            content:
              ' Khi đã hoàn thành việc đăng ký theo quy định ở 2 khoản trên, thì coi như đã thiết lập một hợp đồng về việc sử dụng Dịch vụ này theo Điều khoản này giữa người đã đăng ký người dùng và Công ty chúng tôi (sau đây gọi là “Hợp đồng này”).',
          },
          {
            index: 4,
            content:
              ' Tài khoản ngân hàng để nhận thanh toán theo Dịch vụ này là tài khoản mà người dùng đã đăng ký là tài khoản nhận lương với doanh nghiệp tham gia, và là tài khoản mà doanh nghiệp tham gia đã thông báo cho Công ty chúng tôi. Trường hợp người dùng muốn thay đổi tài khoản này thì phải thực hiện các thủ tục cần thiết với doanh nghiệp tham gia.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Điều 5 (Cách sử dụng)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Để sử dụng Dịch vụ này thì cần phải có ứng dụng chỉ định. Người dùng phải tải ứng dụng chỉ định về từ trang web được chỉ định hoặc trang web mà Công ty chúng tôi chấp nhận riêng, rồi cài đặt vào điện thoại thông minh, v.v… của bản thân. Việc chuẩn bị và duy trì điện thoại thông minh hay các thiết bị khác, đường truyền dẫn hay môi trường truyền dẫn khác, v.v… thuộc trách nhiệm của người dùng và do người dùng tự chịu chi phí; người dùng phải có trách nhiệm và tự chịu chi phí cho việc thực hiện các biện pháp bảo mật phù hợp với môi trường sử dụng Dịch vụ này của bản thân để ngăn ngừa nhiễm vi-rút, ngăn chặn truy cập trái phép và rò rỉ thông tin. Ngoài ra, khi bắt đầu hoặc khi đang sử dụng Dịch vụ này, nếu người dùng cài đặt phần mềm, v.v… vào máy tính, v.v… của bản thân bằng phương pháp tải ứng dụng về hoặc phương pháp khác, thì người dùng phải chú ý một cách đầy đủ để không bị mất hoặc thay đổi các thông tin mà người dùng đang có, hoặc bị hỏng hóc, hư hại thiết bị, Công ty chúng tôi không chịu bất cứ trách nhiệm nào đối với các tổn thất như vậy phát sinh cho người dùng.',
          },
          {
            index: 2,
            content:
              ' Khi muốn sử dụng Dịch vụ này, người dùng khởi động ứng dụng chỉ định, đăng nhập bằng cách nhập các thông tin xác thực như ID và mật khẩu, v.v… vào ứng dụng chỉ định, thực hiện nhập trên ứng dụng chỉ định số tiền yêu cầu về việc sử dụng Dịch vụ này (sau đây gọi là “Số tiền yêu cầu sử dụng”) và các nội dung cần thiết khác vào mẫu có sẵn để yêu cầu thanh toán. Số tiền yêu cầu sử dụng là tổng của số tiền mà người dùng yêu cầu Công ty chúng tôi thanh toán dưới dạng trả trước tiền công và tiền phí sử dụng dịch vụ này mà được quy định ở điều sau điều này.',
          },
          {
            index: 3,
            content:
              ' Công ty chúng tôi sẽ thực hiện thanh toán dưới dạng trả trước tiền công với tư cách là bên đại diện về công việc đã được ủy thác bởi doanh nghiệp tham gia, sau khi đã xác nhận nội dung được nhập theo quy định ở khoản trên và xác nhận rằng đó là yêu cầu hợp lý. Ngoài ra, Công ty chúng tôi cũng kiểm tra việc ID, mật khẩu được nhập khi đăng nhập vào ứng dụng chỉ định bởi người dùng và ID, mật khẩu đã được đăng ký với Công ty chúng tôi là trùng khớp. Trường hợp xác nhận được là đã trùng khớp, thì Công ty chúng tôi coi yêu cầu nói đến ở khoản trên là yêu cầu từ người dùng.',
          },
          {
            index: 4,
            content:
              ' Số tiền yêu cầu sử dụng phải ở trong phạm vi số tiền yêu cầu tối đa ở thời điểm đó, với đơn vị thấp nhất là 1000 Yên. Ngoài ra, cũng có trường hợp Công ty chúng tôi sẽ đặt ra giới hạn nhất định về số lần yêu cầu sử dụng, số tiền sử dụng, v.v… cho từng khoảng thời gian nhất định.',
          },
          {
            index: 5,
            content:
              ' Đồng thời với việc thực hiện thanh toán dưới dạng trả trước tiền công, số tiền sử dụng sẽ được thông báo tới doanh nghiệp tham gia, và doanh nghiệp tham gia sẽ coi số tiền mà công ty chúng tôi đã thanh toán dưới dạng trả trước tiền công (phần tương ứng với số tiền công mà đã trừ đi tiền phí sử dụng (quy định ở điều sau) từ số tiền yêu cầu sử dụng) là số tiền đã được chi trả tiền công. Người dùng đồng ý với doanh nghiệp tham gia về việc số tiền tương ứng sẽ được khấu trừ vào ngày trả lương định trước. Bất kể quy định ở các khoản trên, nếu thuộc các trường hợp quy định sau đây, Công ty chúng tôi có thể từ chối việc sử dụng Dịch vụ này đối với người dùng.                    ',
            innerList: [
              {
                index: 1,
                content:
                  ' Trường hợp tài khoản ngân hàng (mà doanh nghiệp tham gia đã thông báo tới Công ty chúng tôi rằng đó là tài khoản nhận thanh toán) đã bị tịch thu, tịch thu tạm thời hoặc bị chấm dứt',
              },
              {
                index: 2,
                content:
                  ' Trường hợp doanh nghiệp tham gia bị dừng thanh toán, hoặc các trường hợp khác khi Công ty chúng tôi đánh giá rằng tình hình kinh tế của doanh nghiệp tham gia bị xấu đi rõ rệt',
              },
              {
                index: 3,
                content: ' Trường hợp tiền công của người dùng đã bị tịch thu',
              },
              {
                index: 4,
                content:
                  ' Trường hợp không thể xử lý được do mất điện hoặc sự cố, v.v…',
              },
              {
                index: 5,
                content:
                  ' Trường hợp doanh nghiệp tham gia đã yêu cầu Công ty chúng tôi dừng việc sử dụng của người dùng',
              },
              {
                index: 6,
                content:
                  ' Trường hợp Công ty chúng tôi có thể từ chối việc sử dụng Dịch vụ này đối với người dùng theo hợp đồng doanh nghiệp tham gia',
              },
              {
                index: 7,
                content:
                  ' Các trường hợp khác khi có tình hình bất khả kháng và Công ty chúng tôi đánh giá rằng việc xử lý là không phù hợp hoặc không thể thực hiện.',
              },
            ],
          },
          {
            index: 6,
            content:
              ' Trường hợp cần phải có thời gian để xác nhận theo quy định ở khoản 3, hoặc trường hợp đã từ chối việc sử dụng theo quy định ở khoản trên, dù cho phát sinh thiệt hại đối với người dùng do việc sử dụng Dịch vụ này bởi người dùng bị chậm trễ hay việc không thể sử dụng Dịch vụ này, thì Công ty chúng tôi không chịu bất cứ trách nhiệm nào đối với điều đó.',
          },
          {
            index: 7,
            content:
              ' Trường hợp phát sinh việc chi trả vượt mức trong số tiền thanh toán vì lý do không thể quy về trách nhiệm của doanh nghiệp tham gia, thì người dùng phải trả lại cho Công ty chúng tôi phần tương ứng đã được chi trả vượt mức đó.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Điều 6 (Phí sử dụng)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Người dùng phải chi trả phí sử dụng do Công ty chúng tôi quy định riêng và đã thông báo từ trước thông qua ứng dụng chỉ định, v.v…',
          },
          {
            index: 2,
            content:
              ' Liên quan đến việc thanh toán cho người dùng theo khoản 3 điều trước, Công ty chúng tôi có thể được chi trả phí sử dụng quy định ở khoản trước và số tiền tương ứng với thuế tiêu thụ liên quan đến khoản tiền này.',
          },
          {
            index: 3,
            content:
              ' Phí sử dụng quy định ở các khoản trước và số tiền được thanh toán từ Công ty chúng tôi dưới dạng trả trước tiền công sẽ được hiển thị trên ứng dụng chỉ định mỗi khi yêu cầu thanh toán. Sau khi xác nhận số tiền tương ứng và thực hiện sự đồng ý rõ ràng với số tiền đó, thì người dùng có thể chuyển sang yêu cầu thanh toán. Người dùng có thể thực hiện “Sự đồng ý rõ ràng” được quy định ở khoản này đối với nội dung được hiển thị trên ứng dụng chuyên dụng thông qua việc bấm vào nút (đường link) như “Đồng ý”, “Chấp thuận”, v.v...',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Điều 7 (Nhận chi trả từ doanh nghiệp tham gia, v.v…)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Công ty chúng tôi có thể được chi trả từ doanh nghiệp tham gia số tiền là tổng của khoản tiền mà đã thanh toán cho người dùng dưới dạng trả trước của tiền công theo quy định tại khoản 3 điều 5 và khoản phí sử dụng mà người dùng phải chịu liên quan tới việc sử dụng dịch vụ này theo quy định tại điều trước. Người dùng đồng ý với việc doanh nghiệp tham gia sẽ khấu trừ khoản tiền tổng hợp đó khi chi trả tiền công cho người dùng.',
          },
          {
            index: 2,
            content:
              ' Bằng việc thanh toán dưới dạng trả trước tiền công mà Công ty chúng tôi thực hiện theo khoản 3 điều 5, Công ty chúng tôi sẽ được thế quyền (mà người dùng là đối tượng thanh toán tương ứng sở hữu đối với doanh nghiệp tham gia) đối với tiền công tương ứng với khoản tiền đã thanh toán đó (phần tiền tương ứng với tiền công mà đã trừ đi tiền phí sử dụng được quy định ở khoản 1 điều trên từ số tiền mà người dùng yêu cầu thanh toán). Trong trường hợp này, Công ty chúng tôi có thể thực thi một cách độc lập tất cả các quyền mà người dùng sở hữu đối với doanh nghiệp tham gia như 1 sự hiệu lực và đảm bảo của quyền đối với tiền công đó, trong phạm vi có thể yêu cầu doanh nghiệp tham gia bồi hoàn và chi trả đối với khoản tiền mà Công ty chúng tôi đã thực hiện thanh toán dưới dạng trả trước tiền công theo khoản trước; người dùng chấp nhận và đồng ý đối với việc Công ty chúng tôi thực thi quyền đối với tiền công đó.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Điều 8 (Nghĩa vụ và hành vi bị cấm của người dùng)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Người dùng phải xác nhận và đảm bảo rằng tất cả thông tin đã cung cấp cho Công ty chúng tôi và doanh nghiệp tham gia khi đăng kí sử dụng dịch vụ này là chính xác và không có sai sót ở những điểm quan trọng.',
          },
          {
            index: 2,
            content:
              ' Người dùng phải sử dụng dịch vụ này với sự chú ý của một người quản lý cẩn trọng.',
          },
          {
            index: 3,
            content:
              ' Người dùng phải quản lý bảo mật thông tin xác thực cần thiết để sử dụng Dịch vụ này như ID, mật khẩu, v.v... (dưới đây, gọi là “Thông tin tài khoản”) và sử dụng sao cho không để rò rỉ cho người khác. Người dùng phải chịu trách nhiệm đối với những tổn thất phát sinh do lỗi quản lý, sơ suất trong sử dụng hay do việc sử dụng của bên thứ ba, v.v... đối với thông tin tài khoản, Công ty chúng tôi sẽ không chịu bất kì trách nhiệm nào đối với những tổn thất đó.',
          },
          {
            index: 4,
            content:
              ' Trường hợp người dùng chắc chắn rằng thông tin tài khoản đang bị sử dụng trái phép bởi bên thứ ba, hoặc trường hợp nhận thấy có nguy cơ đó, thì ngay lập tức thông báo điều đó cho Công ty chúng tôi và làm theo chỉ thị từ Công ty chúng tôi.',
          },
          {
            index: 5,
            content:
              ' Người dùng không được thực hiện những hành vi nêu dưới đây về việc sử dụng Dịch vụ này.                    ',
            innerList: [
              {
                index: 1,
                content:
                  ' Cho phép bên thứ ba sử dụng Dịch vụ này dưới danh nghĩa của mình.',
              },
              {
                index: 2,
                content:
                  ' Sử dụng trang web chỉ định, trang web khác của Công ty chúng tôi liên quan đến dịch vụ này, ứng dụng mà Công ty chúng tôi cung cấp phục vụ cho việc sử dụng dịch vụ này, v.v... cho mục đích khác ngoài mục đích sử dụng dịch vụ này, hoặc thử kết nối trái phép vào các trang web và ứng dụng này.',
              },
              {
                index: 3,
                content:
                  ' Hành vi vi phạm pháp luật hoặc vi phạm quy tắc trật tự và đạo đức công cộng, hành vi liên quan đến hành vi phạm tội, hành vi gây trở ngại cho việc vận hành dịch vụ của Công ty chúng tôi, hoặc hành vi sử dụng Dịch vụ này trái với mục đích chính của Dịch vụ này.',
              },
              {
                index: 4,
                content:
                  ' Các hành vi khác mà Công ty chúng tôi đánh giá là không phù hợp.',
              },
            ],
          },
          {
            index: 6,
            content:
              ' Trường hợp phát sinh tranh chấp giữa người dùng với doanh nghiệp tham gia hoặc với bên thứ ba liên quan đến việc sử dụng Dịch vụ này của người dùng, hoặc mối quan hệ quyền - nghĩa vụ hay mối quan hệ lao động liên quan đến Dịch vụ này, v.v... thì người dùng phải giải quyết tranh chấp đó bằng trách nhiệm và chi phí của mình, và bảo đảm để Công ty chúng tôi được miễn trách nhiệm đối với những tranh chấp đó.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Điều 9 (Dừng cung cấp Dịch vụ này)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Công ty chúng tôi có thể dừng tạm thời việc cung cấp Dịch vụ này sau khi đã thông báo trước cho người dùng, với lý do kiểm tra, bảo trì hệ thống và thiết bị (dưới đây gọi là “Thiết bị”) để vận hành dịch vụ này. Ngoài ra, trong các trường hợp nêu dưới đây, Công ty chúng tôi có thể dừng cung cấp Dịch vụ này mà không cần thông báo trước.                    ',
            innerList: [
              {
                index: 1,
                content:
                  ' Trường hợp phát sinh sự cố, v.v… không lường trước được ở thiết bị và cần phải dừng cung cấp dịch vụ để ngăn chặn sự phát sinh hoặc lan rộng của thiệt hại.',
              },
              {
                index: 2,
                content:
                  ' Trường hợp thiết bị được đánh giá là đang bị tấn công từ bên thứ ba và cần phải dừng cung cấp dịch vụ để duy trì bảo mật thông tin, v.v...',
              },
              {
                index: 3,
                content:
                  ' Trường hợp không thể tiếp tục vận hành Dịch vụ này do hỏa hoạn, mất điện, thiên tai hoặc các nguyên nhân bất khả kháng khác (là các sự việc vượt quá khả năng kiểm soát hợp lý của bên tương ứng. Dưới đây cũng tương tự vậy).',
              },
              {
                index: 4,
                content:
                  ' Các trường hợp khác mà cần phải thực hiện bảo trì, v.v... khẩn cấp để vận hành bình thường Dịch vụ này hoặc duy trì bảo mật thông tin, v.v...',
              },
            ],
          },
          {
            index: 2,
            content:
              ' Trong trường hợp sau khi hợp đồng này được thiết lập, Công ty chúng tôi nhận định rằng việc vận hành Dịch vụ này trở nên khó khăn do sự thay đổi của pháp luật hoặc hướng dẫn giải thích luật, hoặc do các tình hình khác, thì Công ty chúng tôi có thể thay đổi một phần nội dung Dịch vụ này, hoặc dừng vận hành Dịch vụ này.',
          },
          {
            index: 3,
            content:
              ' Về việc thay đổi một phần hoặc dừng Dịch vụ này theo quy định ở 2 khoản trên, Công ty chúng tôi sẽ không phải chịu trách nhiệm bồi thường thiệt hại dưới bất kì danh nghĩa nào.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Điều 10 (Trách nhiệm của Công ty chúng tôi)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Ngoài các quy định trong Điều khoản này, Công ty chúng tôi sẽ chú ý như một người quản lý cẩn trọng để vận hành Dịch vụ này.',
          },
          {
            index: 2,
            content:
              ' Công ty chúng tôi không có nghĩa vụ đảm bảo người dùng sẽ đạt được mục đích nhất định thông qua Dịch vụ này.',
          },
          {
            index: 3,
            content:
              ' Về nguyên tắc, ứng dụng mà Công ty chúng tôi cung cấp liên quan đến Dịch vụ này là ứng dụng được cung cấp theo trạng thái hiện tại, và Công ty chúng tôi không có bất kỳ nghĩa vụ đảm bảo nào về chức năng của ứng dụng đó, hoặc về việc không tồn tại lỗi phần mềm.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Điều 11 (Quản lý thông tin)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Công ty chúng tôi chỉ sử dụng thông tin cá nhân của người dùng mà đã có được khi vận hành Dịch vụ này (bao gồm thông tin liên quan đến trạng thái thanh toán) trong phạm vi mục đích sử dụng được nêu rõ trên trang web chỉ định. Ngoài ra, trong trường hợp nhận được sự đồng ý riêng từ người dùng, thì Công ty chúng tôi có thể sử dụng thông tin cá nhân tương ứng cho mục đích sử dụng mà đã nhận được sự đồng ý đó.',
          },
          {
            index: 2,
            content:
              ' Ngoài quy định ở khoản trên, đối với việc xử lý thông tin cá nhân, Công ty chúng tôi tuân thủ các quy định pháp luật và các quy định liên quan đến chính sách bảo vệ thông tin cá nhân do Công ty chúng tôi quy định.',
          },
          {
            index: 3,
            content:
              ' Dù trong trường hợp Công ty chúng tôi đã lưu giữ các thông tin liên quan đến việc sử dụng Dịch vụ này của người dùng trong một thời gian nhất định để vận hành, thì Công ty chúng tôi cũng không có nghĩa vụ phải lưu giữ các thông tin đó, mà có thể xóa bỏ các thông tin đó bất cứ lúc nào. Ngoài ra, Công ty chúng tôi hoàn toàn không chịu trách nhiệm đối với những thiệt hại xảy ra cho người dùng do việc xóa bỏ các thông tin đó.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Điều 12 (Liên lạc, thông báo)',
        content:
          'Người dùng và Công ty chúng tôi phải thực hiện liên lạc, thông báo cho nhau liên quan đến Hợp đồng này bằng cách thức do Công ty chúng tôi chỉ định riêng. Về nguyên tắc, những liên lạc, thông báo mà không theo cách thức này sẽ bị coi là vô hiệu, trừ trường hợp được người nhận đồng ý. Ngoài ra, Công ty chúng tôi có thể sử dụng cách thức đăng trên ứng dụng chỉ định như một cách thức liên lạc đến người dùng.',
        list: [],
        bracketList: [],
      },
      {
        title: 'Điều 13 (Quyền sở hữu trí tuệ)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Tất cả các quyền sở hữu trí tuệ như quyền sáng chế, quyền mẫu hữu ích, quyền thiết kế, quyền tác giả, quyền thương hiệu, v.v…(bao gồm quyền đăng ký xin cấp các quyền này) liên quan đến hệ thống, trang web, ứng dụng (bao gồm ứng dụng chỉ định) do Công ty chúng tôi cung cấp mà có liên quan đến việc vận hành Dịch vụ này đều thuộc sở hữu của Công ty chúng tôi hoặc đơn vị liên kết của Công ty chúng tôi.',
          },
          {
            index: 2,
            content:
              ' Người dùng không được thực hiện biên dịch ngược (decompile), kỹ thuật đảo ngược (reverse engineering), dịch ngược ra mã nguồn (disassemble), v.v.. đối với các ứng dụng do Công ty chúng tôi cung cấp liên quan đến việc sử dụng Dịch vụ này (bao gồm ứng dụng chỉ định). Ngoài ra, không được sử dụng ứng dụng này với mục đích khác với việc sử dụng Dịch vụ này.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Điều 14 (Bảo mật)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Người dùng và Công ty chúng tôi phải quản lý bảo mật tất cả các thông tin mật về mặt công nghệ, kinh doanh và các mặt khác của bên đối phương mà đã biết được thông qua việc thực hiện Hợp đồng này (sau đây gọi là “Thông tin mật”); không chỉ trong thời gian hiệu lực của Hợp đồng này mà cả sau khi kết thúc Hợp đồng này, nếu không được sự đồng ý trước bằng văn bản của bên đối phương thì không được tiết lộ, công khai với bất kỳ Bên thứ ba nào, ngoại trừ cán bộ, nhân viên của mình, hoặc các chuyên gia như luật sư, kế toán, v.v.. mà có liên quan đến việc thực hiện Hợp đồng này, đồng thời không được sử dụng thông tin mật cho mục đích khác không phải là để thực hiện Hợp đồng này.',
          },
          {
            index: 2,
            content:
              ' Bất kể quy định tại khoản 1 ở trên, những thông tin thuộc bất kỳ mục nào dưới đây đều không phải là đối tượng của nghĩa vụ bảo mật.                    ',
            innerList: [
              {
                index: 1,
                content:
                  ' Thông tin đã được biết đến rộng rãi trước khi bên đương sự biết',
              },
              {
                index: 2,
                content:
                  ' Thông tin đã được biết đến rộng rãi sau khi bên đương sự biết mà không phải do lỗi của bên đương sự',
              },
              {
                index: 3,
                content:
                  ' Thông tin bên mà bản thân bên đương sự đã sở hữu một cách hợp pháp trước khi bên đương sự biết',
              },
              {
                index: 4,
                content:
                  ' Thông tin có được một cách hợp pháp mà không có nghĩa vụ bảo mật từ Bên thứ ba có quyền chính đáng.',
              },
              {
                index: 5,
                content:
                  ' Thông tin mà bên đương sự đã phát triển một cách độc lập mà không tham khảo thông tin mật.',
              },
            ],
          },
        ],
        bracketList: [],
      },
      {
        title: 'Điều 15 (Sửa đổi Điều khoản này)',
        content:
          'Công ty chúng tôi có thể sửa đổi Điều khoản này hoặc dừng Dịch vụ này bằng cách thông báo cho người dùng bằng văn bản (bao gồm thư điện tử), đăng lên ứng dụng chỉ định, trang web do Công ty chúng tôi quy định, hoặc bằng cách khác mà người dùng có thể biết được nội dung thay đổi một cách hợp lý, trước ít nhất một tháng của ngày thực hiện sửa đổi. Ngoài ra, liên quan đến việc sửa đổi Điều khoản này, trường hợp người dùng sử dụng dịch vụ này sau khi Điều khoản này được sửa đổi, thì được coi rằng người dùng đó đã đồng ý với nội dung sửa đổi của Điều khoản này.',
        list: [],
        bracketList: [],
      },
      {
        title: 'Điều 16 (Tuân thủ pháp luật)',
        content:
          'Đối với việc thực hiện Hợp đồng này, người dùng và Công ty chúng tôi phải tuân thủ pháp luật, nghị định, thông tư, điều lệ, quy tắc và mệnh lệnh, v.v… liên quan.',
        list: [],
        bracketList: [],
      },
      {
        title: 'Điều 17 (Chuyển nhượng quyền lợi)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Người dùng không được chuyển nhượng vai trò của bản thân trong Hợp đồng này cho Bên thứ ba mà không có sự đồng ý trước bằng văn bản của Công ty chúng tôi.',
          },
          {
            index: 2,
            content:
              ' Người dùng không được chuyển nhượng trái quyền đối với Công ty chúng tôi theo Hợp đồng này, hoặc không được cấp trái quyền đó với mục đích thế chấp như là cầm cố cho Bên thứ ba mà không có sự đồng ý trước bằng văn bản của Công ty chúng tôi.',
          },
          {
            index: 3,
            content:
              ' Trường hợp Công ty chúng tôi đã chuyển nhượng hoạt động kinh doanh liên quan đến Dịch vụ này cho Bên thứ ba (bất kể là chuyển nhượng kinh doanh, chia tách Công ty hay các loại hình khác) thì cùng với việc chuyển nhượng tương ứng đó, Công ty chúng tôi có thể chuyển nhượng vai trò trong Hợp đồng này, quyền lợi và nghĩa vụ theo Điều khoản này, cũng như thông tin cá nhân của người dùng và các thông tin khách hàng khác cho người nhận chuyển nhượng của việc chuyển nhượng đó; và người dùng được coi là đã đồng ý trước với khoản này đối với việc chuyển nhượng đó.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Điều 18 (Bài trừ các thế lực chống đối xã hội)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Người dùng đảm bảo và cam kết rằng hiện tại và trong tương lai, bản thân không thuộc bất kỳ thế lực chống đối xã hội nào được nêu sau đây:                    ',
            innerList: [
              {
                index: 1,
                content: ' Tổ chức tội phạm',
              },
              {
                index: 2,
                content: ' Thành viên của tổ chức tội phạm',
              },
              {
                index: 3,
                content: ' Thành viên cấu kết với tổ chức tội phạm',
              },
              {
                index: 4,
                content:
                  ' Người chưa trải qua 5 năm kể từ khi không còn là tổ chức tội phạm, thành viên của tổ chức tội phạm',
              },
              {
                index: 5,
                content: ' Doanh nghiệp có quan hệ với tổ chức tội phạm',
              },
              {
                index: 6,
                content:
                  ' Tổ chức uy hiếp để tống tiền (Sokaiya), người chủ trương ủng hộ vận động xã hội hoặc tổ chức tội phạm trí tuệ đặc biệt, v.v..',
              },
              {
                index: 7,
                content: ' Tổ chức và cá nhân khác tương tự với các điểm trên',
              },
            ],
          },
          {
            index: 2,
            content:
              ' Người dùng đảm bảo và cam kết rằng hiện tại và trong tương lai, bản thân không có bất kỳ mối quan hệ nào được nêu dưới đây với thế lực chống đối xã hội quy định ở khoản trên, hoặc với người ở trong mối quan hệ thân cận, mật thiết với thế lực chống đối xã hội (Sau đây gọi là “Các thế lực chống đối xã hội”).                    ',
            innerList: [
              {
                index: 1,
                content:
                  ' Mối quan hệ trong đó các thế lực chống đối xã hội chi phối hoạt động kinh doanh',
              },
              {
                index: 2,
                content:
                  ' Mối quan hệ trong đó các thế lực chống đối xã hội tham gia thực chất vào hoạt động kinh doanh',
              },
              {
                index: 3,
                content:
                  ' Mối quan hệ mà có cung cấp tiền vốn hoặc tạo điều kiện thuận lợi cho các thế lực chống đối xã hội',
              },
              {
                index: 4,
                content:
                  ' Mối quan hệ khác cần bị lên án về mặt xã hội với các thế lực chống đối xã hội',
              },
            ],
          },
          {
            index: 3,
            content:
              ' Người dùng cam kết rằng bản thân không thực hiện bất kỳ hành vi nào được nêu dưới đây:                    ',
            innerList: [
              {
                index: 1,
                content: ' Hành vi yêu cầu một cách bạo lực',
              },
              {
                index: 2,
                content:
                  ' Hành vi yêu cầu không phù hợp, vượt quá trách nhiệm pháp lý',
              },
              {
                index: 3,
                content:
                  ' Hành vi dùng ngôn từ, hành động mang tính đe dọa, hoặc sử dụng bạo lực trong giao dịch',
              },
              {
                index: 4,
                content:
                  ' Hành vi truyền bá tin đồn; làm tổn hại đến uy tín của Công ty chúng tôi hoặc gây cản trở cho công việc Công ty chúng tôi bằng cách sử dụng âm mưu hoặc quyền lực.',
              },
              {
                index: 5,
                content: ' Các hành vi khác tương tự với các điểm trên',
              },
            ],
          },
          {
            index: 4,
            content:
              ' Trong trường hợp có sự việc hoặc có nhiều khả năng người dùng vi phạm các khoản trên thì Công ty chúng tôi có thể ngay lập tức hủy bỏ Hợp đồng này mà không cần thông báo. Ngoài ra, ngay cả trường hợp phát sinh thiệt hại cho người dùng do việc hủy bỏ đó thì Công ty chúng tôi cũng không có trách nhiệm bồi thường thiệt hại.',
          },
        ],
        bracketList: [],
      },
      {
        title:
          'Điều 19 (Thời hạn có hiệu lực và các điều khoản tiếp tục hiệu lực)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Hợp đồng này tiếp tục có hiệu lực kể từ thời điểm được thiết lập theo quy định tại khoản 3 điều 4 cho đến khi phát sinh sự việc quy định ở các điểm sau:                    ',
            innerList: [
              {
                index: 1,
                content:
                  ' Trường hợp người dùng nghỉ việc tại doanh nghiệp tham gia mà đã đăng ký người dùng theo doanh nghiệp đó',
              },
              {
                index: 2,
                content:
                  ' Trường hợp người dùng đề nghị hủy đăng ký người dùng bằng ứng dụng chuyên dụng',
              },
              {
                index: 3,
                content:
                  ' Trường hợp Công ty chúng tôi thực hiện quyền hủy bỏ Hợp đồng này theo quy định tại khoản 4 của điều 18 và điều 20',
              },
              {
                index: 4,
                content:
                  ' Trường hợp Hợp đồng doanh nghiệp tham gia kết thúc với bất cứ lý do gì.',
              },
            ],
          },
          {
            index: 2,
            content:
              ' Trường hợp Hợp đồng này kết thúc với bất cứ lý do gì, thì sau đó người dùng không được sử dụng Dịch vụ này. Ngoài ra, dù việc đăng ký sử dụng Dịch vụ này theo điều 5 được thực hiện trong thời gian có hiệu lực của Hợp đồng này, nhưng nếu việc thanh toán chưa được thực hiện vào thời điểm Hợp đồng này kết thúc, thì việc đăng ký đó được xử lý là vô hiệu.',
          },
          {
            index: 3,
            content:
              ' Bất kể lý do là mãn hạn hợp đồng, chấm dứt giữa chừng hay là lý do kết thúc khác thì các quy định của điều 13, điều 14, điều 21 đến điều 26 vẫn tiếp tục có hiệu lực sau khi Hợp đồng này kết thúc.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Điều 20 (Hủy bỏ hợp đồng)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Trường hợp xảy ra bất kỳ sự việc nào dưới đây đối với người dùng, Công ty chúng tôi có thể hủy bỏ Hợp đồng này ngay lập tức mà không cần thông báo:                    ',
            innerList: [
              {
                index: 1,
                content: ' Khi vi phạm bất kỳ điều khoản nào của Hợp đồng này',
              },
              {
                index: 2,
                content:
                  ' Khi được đánh giá là có giả mạo trong thông tin đăng ký hoặc thông tin khác mà người dùng cung cấp cho Công ty chúng tôi',
              },
              {
                index: 3,
                content:
                  ' Khi sử dụng hoặc có ý định sử dụng Dịch vụ này với mục đích hoặc phương pháp có khả năng gây thiệt hại cho Công ty chúng tôi, người dùng khác hoặc Bên thứ ba khác',
              },
              {
                index: 4,
                content:
                  ' Khi gây trở ngại hoặc có ý định gây trở ngại cho việc kinh doanh Dịch vụ này với bất kỳ phương thức nào',
              },
              {
                index: 5,
                content: ' Khi đăng ký hoặc được đăng ký thủ tục phá sản',
              },
              {
                index: 6,
                content:
                  ' Khi rơi vào tình trạng ngừng thanh toán như là chịu sự xử lý không đủ khả năng thanh toán đối với các hối phiếu hoặc ngân phiếu mà mình tự phát hành',
              },
              {
                index: 7,
                content:
                  ' Khi có yêu cầu về tịch thu, tịch thu tạm thời, xử lý tạm thời, cưỡng chế thi hành hoặc bán đấu giá',
              },
              {
                index: 8,
                content: ' Khi chịu xử lý nộp chậm các khoản thuế và lệ phí',
              },
              {
                index: 9,
                content:
                  ' Khi đã chết, hoặc khi nhận phán quyết về việc mở thủ tục giám hộ, thủ tục bảo hộ hoặc thủ tục hỗ trợ',
              },
              {
                index: 10,
                content: ' Khi không trả lời các liên lạc từ Công ty chúng tôi',
              },
              {
                index: 11,
                content:
                  ' Khi công ty chúng tôi đánh giá là không phù hợp để tiếp tục đăng ký người dùng',
              },
              {
                index: 12,
                content:
                  ' Khi xảy ra các sự việc khác tương tự với các điểm trên',
              },
            ],
          },
          {
            index: 2,
            content:
              ' Trường hợp xảy ra sự việc đối với doanh nghiệp tham gia mà Công ty chúng tôi nên hủy bỏ hoặc chấm dứt Hợp đồng doanh nghiệp tham gia như tình trạng bất ổn về tín dụng, hoặc trường hợp khác mà Công ty chúng tôi không thể cung cấp Dịch vụ này cho doanh nghiệp tham gia, thì Công ty chúng tôi có thể hủy bỏ Hợp đồng này ngay lập tức đối với người dùng mà không cần thông báo.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Điều 21 (Bồi thường thiệt hại)',
        content:
          'Trường hợp người dùng gây ra các thiệt hại cho Công ty chúng tôi liên quan đến Hợp đồng này do hành vi bất hợp pháp, không thực hiện nghĩa vụ hoặc các nguyên nhân khác thuộc trách nhiệm của người dùng, thì người dùng phải chịu trách nhiệm bồi thường thiệt hại đó.',
        list: [],
        bracketList: [],
      },
      {
        title: 'Điều 22 (Miễn trách nhiệm)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Công ty chúng tôi hoàn toàn không chịu trách nhiệm đối với các thiệt hại xảy ra cho người dùng do các nguyên nhân sau:                    ',
            innerList: [
              {
                index: 1,
                content:
                  ' Không thể thực hiện nghĩa vụ, chậm thực hiện nghĩa vụ hoặc không thực hiện các nghĩa vụ thanh toán khác do hỏa hoạn, mất điện, thiên tai hoặc các nguyên nhân bất khả kháng.',
              },
              {
                index: 2,
                content:
                  ' Rò rỉ các thông tin xác thực như ID, mật khẩu, v.v… mặc dù đã thực hiện biện pháp an ninh hợp lý đối với Dịch vụ này do Công ty chúng tôi cung cấp (bao gồm nhưng không giới hạn trường hợp tấn công mạng bởi Bên thứ ba, hoặc rò rỉ thông tin do thu nhập trái phép.)',
              },
              {
                index: 3,
                content:
                  ' Lỗi truyền dẫn xảy ra mặc dù đã thực hiện các biện pháp an toàn hợp lý đối với hệ thống của Dịch vụ này do Công ty chúng tôi cung cấp',
              },
            ],
          },
          {
            index: 2,
            content:
              ' Trường hợp có các ý kiến phản hồi hoặc tranh chấp liên quan đến việc chi trả tiền công, mối quan hệ quyền - nghĩa vụ và mối quan hệ lao động khác giữa người dùng và doanh nghiệp tham gia liên quan đến Dịch vụ này, thì sẽ được giải quyết giữa người dùng và doanh nghiệp tham gia; Công ty chúng tôi hoàn toàn không chịu trách nhiệm.',
          },
          {
            index: 3,
            content:
              ' Khi người dùng sử dụng Dịch vụ này, nếu Công ty chúng tôi gây ra thiệt hại cho người dùng thì trách nhiệm bồi thường của Công ty chúng tôi không vượt quá tổng số tiền của phí sử dụng mà người dùng đã chịu trên thực tế trong khoảng thời gian là 1 năm trước đó kể từ thời điểm xảy ra sự việc thiệt hại. Tuy nhiên, điều này không áp dụng cho trường hợp Công ty chúng tôi cố ý hoặc có lỗi nghiêm trọng.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Điều 23 (Hiệu lực từng phần)',
        content:
          'Dù bất kỳ hạng mục nào trong Điều khoản này vô hiệu hoặc không có khả năng thực hiện, thì việc vô hiệu và không thể thực hiện đó không gây ảnh hưởng đến hiệu lực và tính ràng buộc của các điều khoản khác.',
        list: [],
        bracketList: [],
      },
      {
        title: 'Điều 24 (Luật áp dụng)',
        content:
          'Hợp đồng này được thiết lập, giải thích và áp dụng theo Luật Nhật Bản.',
        list: [],
        bracketList: [],
      },
      {
        title: 'Điều 25 (Cơ quan giải quyết tranh chấp)',
        content:
          'Trường hợp tranh chấp giữa các bên đương sự theo Hợp đồng này được giải quyết bởi tòa án, thì người dùng và Công ty chúng tôi phải chấp nhận án phí đó; và coi Tòa án địa phương Tokyo hoặc Tòa án sơ thẩm Tokyo là tòa án chuyên trách có thẩm quyền xét xử đầu tiên.',
        list: [],
        bracketList: [],
      },
      {
        title: 'Điều 26 (Thỏa thuận, giải quyết)',
        content:
          'Khi có thắc mắc về các hạng mục không được quy định trong Điều khoản này hoặc về cách giải thích của các hạng mục trong Điều khoản này, thì người dùng và Công ty chúng tôi phải thiện chí thỏa thuận trên tinh thần tin tưởng lẫn nhau để giải quyết vấn đề.',
        list: [],
        bracketList: [],
      },
    ],
    endOfDocument: 'Hết',
    corporateName: 'Metaps Payment Inc.',
    forUsers: 'dành cho người dùng',
    privacyPolicy: 'Chính sách bảo vệ thông tin cá nhân',
    privacyPolicies: [
      {
        title: '1. Về việc lấy và sử dụng thông tin cá nhân',
        content:
          'Công ty chúng tôi chỉ lấy và sử dụng thông tin cá nhân trong phạm vi mục đích mà công ty chúng tôi đã chỉ định rõ từ trước. Ngoài ra, công ty chúng tôi cũng thực hiện các biện pháp để không sử dụng thông tin cá nhân ngoài mục đích đó. Trong trường hợp cần phải sử dụng thông tin cá nhân vượt quá phạm vi mục đích đã chỉ định rõ, công ty chúng tôi sẽ liên lạc trước với chính chủ của thông tin cá nhân về mục đích tương ứng. Nếu không nhận được sự đồng ý cho phép sử dụng thông tin cá nhân cho mục đích mới, thì công ty chúng tôi có thể từ chối việc sử dụng tương ứng theo quyết định của chính chủ thể đó. (Vui lòng xem mục 1 của các hạng mục được công bố để biết về mục đích sử dụng thông tin cá nhân của công ty chúng tôi)',
        list: [],
        bracketList: [],
      },
      {
        title: '2. Về việc tiết lộ, cung cấp thông tin cá nhân cho bên thứ ba',
        content:
          'Công ty chúng tôi không tiết lộ hay cung cấp thông tin cá nhân cho bên thứ ba trừ các trường hợp tương ứng dưới đây:',
        list: [
          {
            index: 1,
            content: 'Trường hợp có sự đồng ý của chính chủ.',
          },
          {
            index: 2,
            content:
              'Trường hợp tiết lộ thông tin cá nhân ở mức độ không thể định danh được cá nhân.',
          },
          {
            index: 3,
            content:
              'Trường hợp tiết lộ, cung cấp ở mức độ cần thiết cho doanh nghiệp đã kí kết hợp đồng bảo mật với công ty chúng tôi từ trước (ví dụ: đơn vị ủy thác nghiệp vụ)',
          },
          {
            index: 4,
            content:
              'Trường hợp sử dụng chung thông tin cá nhân (vui lòng xem mục 1(4) của các hạng mục được công bố để biết về trường hợp công ty chúng tôi sử dụng chung thông tin cá nhân).',
          },
          {
            index: 5,
            content:
              'Trường hợp cần phải quyết toán cho mục đích sử dụng dịch vụ có phí hay việc đặt hàng sản phẩm, v.v… của chủ thể. (Có trường hợp công ty chúng tôi trao đổi thông tin cá nhân với cơ quan tài chính, v.v... Mục đích là để xác nhận tính chính xác của tài khoản ngân hàng và hiệu lực của thẻ tín dụng.)',
          },
          {
            index: 6,
            content:
              'Trường hợp được yêu cầu bởi pháp luật, v.v...(Vui lòng xem mục 3 của các hạng mục được công bố để biết về việc xử lý các yêu cầu về tiết lộ thông tin cá nhân)',
          },
        ],
        bracketList: [],
      },
      {
        title: '3. Về quản lý thông tin cá nhân',
        content:
          'Công ty chúng tôi tiến hành các biện pháp phòng ngừa và khắc phục để ngăn chặn việc rò rỉ, thiệt hại, mất mát thông tin cá nhân, và thực hiện quản lý thông tin cá nhân một cách an toàn.',
        list: [],
        bracketList: [],
      },
      {
        title: '4. Về tuân thủ pháp luật liên quan và các quy phạm khác',
        content:
          'Công ty chúng tôi tuân thủ các luật áp dụng, các phương châm do nhà nước quy định và các quy phạm khác liên quan đến thông tin cá nhân người dùng đã cung cấp.',
        list: [],
        bracketList: [],
      },
      {
        title: '5. Về việc xem lại hệ thống quản lý bảo vệ thông tin cá nhân',
        content:
          'Công ty chúng tôi trang bị một hệ thống nội bộ trong công ty để bảo vệ thông tin cá nhân mà công ty chúng tôi sở hữu và vận hành hệ thống đó một cách hiệu quả. Ngoài ra, công ty cũng luôn nỗ lực trong việc cải tiến một cách liên tục và bất cứ lúc nào hệ thống quản lý bảo vệ thông tin cá nhân của công ty chúng tôi (bao gồm quy định bảo vệ thông tin cá nhân và các quy định liên quan).',
        list: [],
        bracketList: [],
      },
      {
        title:
          '6. Về xử lý khiếu nại, thắc mắc liên quan đến thông tin các nhân',
        content:
          'Công ty chúng tôi tiếp nhận những khiếu nại, thắc mắc liên quan đến thông tin cá nhân mà công ty chúng tôi đang sở hữu thông qua cổng tư vấn dưới đây.\nCổng tư vấn dành cho khách hàng: e-mail: c-check@metaps-payment.com\nCổng tư vấn dành cho nhà tuyển dụng: e-mail: saiyo@metaps-payment.com',
        list: [],
        bracketList: [],
      },
    ],
    updateHistories: [
      'Ban hành lần đầu: Ngày 5 tháng 6 năm 2018',
      'Sửa đổi ngày 22 tháng 6 năm 2018',
      'Sửa đổi ngày 19 tháng 7 năm 2018',
      'Sửa đổi ngày 7 tháng 9 năm 2018',
      'Sửa đổi ngày 26 tháng 8 năm 2019',
    ],
    faq: 'Câu hỏi thường gặp',
    logoutConfirm: 'Chuẩn bị đăng xuất. Bạn chắc chứ?',
    logoutConfirmDescription:
      'Bạn cần nhập lại Mã khách hàng và mật khẩu nếu muốn đăng nhập lại',
    languageSettings: 'Cài đặt ngôn ngữ',
    japanese: 'Tiếng Nhật',
    english: 'Tiếng Anh',
    vietnamese: 'Tiếng Việt',
    howTo: 'Cách dùng CRIA',
    bioAuth: 'Xác thực nhân trắc học',
    bioAuthNotification:
      'Hệ thống xác thực nhân trắc học sẽ được khởi động cùng lúc bạn nhập mật khẩu vào màn hình đăng kí nếu bạn chọn ON',
    appVersion: 'phiên bản ứng dụng',
    goBack: 'Quay lại màn hình cài đặt',
    needUpdate:
      'Bạn cần khởi động lại ứng dụng để cài đặt mới có thể hoạt động',
    showJa:
      'Không phải tất cả mọi thông báo đều được dịch sang ngôn ngữ của bạn. Sẽ có một số thông báo hiển thị bằng tiếng Nhật',
  },
  userInfo: {
    information: 'Thông tin đăng kí',
    company: 'Tên công ty trực thuộc',
    partners: 'Đối tác',
    employeeCode: 'Mã Khách hàng',
    account: 'Tài khoản đăng kí',
    mailAddress: 'Địa chỉ email',
    branchCode: 'Mã Chi nhánh',
    depositType: 'Loại Tiền gửi',
    disabled: 'Yêu cầu',
    accountHolder: 'Chủ Tài khoản',
    phoneNumber: 'Số điện thoại',
    status: 'Hiện không hoạt động',
  },
  howToCash: {
    annotation: 'Cách Rút Tiền mặt từ 7-Eleven',
    anyone: `Mọi người đều${'\n'}có thể sử dụng.${'\n'}Thuận lợi, dễ dàng!`,
    selectTop:
      'Chọn “Giao dịch dùng mã không cần thẻ” ở góc trên bên phải màn hình máy ATM Seven Bank.',
    enterPartnerCode: 'Nhập mã đối tác.',
    enterCustomerNumber: 'Nhập Mã Khách hàng.',
    enterConfirmationNumber: 'Nhập mã xác nhận.',
    checkAndConfirm:
      'Kiểm tra và xác nhận thông tin chi tiết và số tiền giao dịch.',
    takeMoney: 'Lấy tiền từ máy rút tiền.',
    takeReceipt: 'Nhận biên lai.',
  },
  alert: {
    error: 'Lỗi',
    inputError: 'Lỗi Thông tin Đăng nhập',
    notice: 'Thông báo',
    confirm: 'Xác nhận',
    customerNumberEmpty: 'Vui lòng nhập Mã Khách hàng của bạn',
    passwordEmpty: 'Vui lòng nhập mật khẩu của bạn',
    customerNumberNotInt: 'Mã Khách hàng chỉ có thể gồm các chữ số',
    customerNumberNoMatchLength: 'Mã Khách hàng phải có độ dài 8 ký tự chữ số',
    passwordTooLong: 'Mật khẩu phải ít hơn 256 ký tự',
    loginNotAllowed: 'Mã khách hàng hoặc mật khẩu của bạn không đúng',
    contactEmpty: 'Nhập địa chỉ email hoặc số điện thoại',
    mailAddressInvalid: 'Định dạng địa chỉ email không đúng',
    forceUpdate: 'Vui lòng cập nhật phiên bản mới nhất của ứng dụng',
    moveAppStore: 'Sẽ chuyển đến App Store. Bạn muốn tiếp tục?',
    movePlayStore: 'Sẽ chuyển đến Google Play. Bạn muốn tiếp tục?',
    maintenance: 'Ứng dụng đang được bảo trì. Vui lòng thử lại sau.',
    serverError: 'Đã xảy ra lỗi máy chủ',
  },
  initial: {
    first: 'Khởi động',
    didGetMail: 'Bạn đã nhận được email mời chưa?',
    gotMail:
      'Sẽ nhận được email mời sau khi công ty liên kết của bạn đăng kí thông tin khách hàng với CRIA',
    received: 'Received',
    notReceived: 'NOT Received',
    contactCompany: 'Xin vui lòng liên hệ công ty liên kết của bạn',
    contactCompanyDescription:
      'Email mời sẽ được gửi từ công ty liên kết của bạn. Xin vui lòng liên hệ công ty của bạn và khởi động lại CRIA ',
    didSetPassword: 'Bạn đã cài đặt xong mật khẩu chưa?',
    setPassword:
      'Bạn có thể cài đặt mật khẩu thông qua đường link được gửi trong thư mời',
    set: 'Hoàn thành cài đặt',
    notSet: 'chưa cài đặt',
    settingPassword: 'Xin vui lòng cài đặt mật khẩu',
    settingPasswordDescription:
      'Bạn cần phải có mật khẩu để có thể đăng nhập. Xin vui lòng cài đặt mật khẩu của mình thông qua đường link đã được cung cấp trong email mời. Nếu bạn không tìm được email mời, xin vui lòng yêu cầu công ty liên kết của bạn gửi lại mail',
  },
  initial2: {
    title: 'Chú ý',
    description:
      'Hạn mực đăng kí được hiển thị sẽ được cập nhật bởi công ty liên kết. Vui lòng liên hệ với công ty liên kết của bạn để được giải đáp về hạn mức được gia hạn cũng như xác nhận / thay đổi thông tin đăng ký.',
    start: 'Bắt đầu',
  },
};

export default dictionary;
