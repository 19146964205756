export const CONFIRM_PIN = 'CONFIRM_PIN';
export const CONFIRM_PIN_SUCCESS = 'CONFIRM_PIN_SUCCESS';
export const CONFIRM_PIN_FAILURE = 'CONFIRM_PIN_FAILURE';

const confirmPin = (
  pin: string,
  retypedPin?: string,
  subtitle?: React.ReactElement<any>
) => ({
  type: CONFIRM_PIN as typeof CONFIRM_PIN,
  payload: {
    pin,
    retypedPin,
    subtitle,
  },
});

interface ConfirmPinFailure {
  type: typeof CONFIRM_PIN_FAILURE;
  payload: {
    subtitle: React.ReactElement<any>;
  };
}

interface ConfirmPinSuccess {
  type: typeof CONFIRM_PIN_SUCCESS;
}

export type ConfirmPinAction =
  | ReturnType<typeof confirmPin>
  | ConfirmPinFailure
  | ConfirmPinSuccess;

export default confirmPin;
