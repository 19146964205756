export const REDIRECT_TO_LOGIN = 'REDIRECT_TO_LOGIN';

const redirectToLogin = (pathname: string) => ({
  type: REDIRECT_TO_LOGIN as typeof REDIRECT_TO_LOGIN,
  payload: {
    pathname,
  },
});

export type RedirectToLoginAction = ReturnType<typeof redirectToLogin>;

export default redirectToLogin;
