import {
  NotificationItem,
  FetchNotificationsAction,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
} from '../actions/fetchNotifications';

export interface NotificationsState {
  notificationItems: NotificationItem[];
  cursor: string | null;
  loading: boolean;
}

const initialState: NotificationsState = {
  notificationItems: [],
  cursor: null,
  loading: false,
};

type NotificationsAction = FetchNotificationsAction;

const notificationsReducer = (
  state = initialState,
  action: NotificationsAction
) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return Object.assign({}, state, {});
    case FETCH_NOTIFICATIONS_SUCCESS:
      return Object.assign({}, state, {
        notificationItems: [
          ...state.notificationItems,
          ...action.payload.notificationItems,
        ],
        cursor: action.payload.cursor,
      });
    case FETCH_NOTIFICATIONS_ERROR:
      return Object.assign({}, state, {});
    default:
      return state;
  }
};

export default notificationsReducer;
