import {all} from 'redux-saga/effects';
import authTokenSaga from './authToken';
import userInfoSaga from './userInfo';
import pinCodeSaga from './pinCode';
import notificationsSaga from './notifications';
import criaNotificationsSaga from './criaNotifications';
import companyNotificationsSaga from './companyNotifications';
import franchiseStoresSaga from './franchiseStores';
import applicationSummarySaga from './applicationSummary';
import {History} from 'history';
import balanceHistoriesSaga from './balanceHistories';
import analyticsSaga from './analytics';
import financialInstitutionSaga from './financialInstitution';
import financialInstitutionBranchSaga from './financialInstitutionBranches';
import languageSaga from './languages';
import confirmationCodeSaga from './confirmationCode';
import agreeWithTheTermsSaga from './agreeWithTheTerms';

export interface Context {
  history: History;
}

function* rootSaga(context: Context) {
  yield all([
    authTokenSaga(context),
    userInfoSaga(),
    pinCodeSaga(),
    notificationsSaga(),
    criaNotificationsSaga(),
    companyNotificationsSaga(),
    franchiseStoresSaga(),
    applicationSummarySaga(),
    balanceHistoriesSaga(),
    analyticsSaga(),
    financialInstitutionSaga(),
    financialInstitutionBranchSaga(),
    languageSaga(),
    confirmationCodeSaga(context),
    agreeWithTheTermsSaga(),
  ]);
}

export default rootSaga;
