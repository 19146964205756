import ReportError from './reportError';

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR';

export interface NotificationItem {
  publishedAt: string;
  title: string;
  read: boolean;
  body: string;
}

export class NotificationType {
  static company = 1;
  static cria = 2;
}

const fetchNotifications = (cursor: string | null) => ({
  type: FETCH_NOTIFICATIONS as typeof FETCH_NOTIFICATIONS,
  payload: {
    cursor,
  },
});

interface FetchNotificationsSuccess {
  type: typeof FETCH_NOTIFICATIONS_SUCCESS;
  payload: {
    notificationItems: NotificationItem[];
    cursor: string | null;
  };
}

export type FetchNotificationsAction =
  | ReturnType<typeof fetchNotifications>
  | FetchNotificationsSuccess
  | ReportError<typeof FETCH_NOTIFICATIONS_ERROR>;

export default fetchNotifications;
