import {
  fetchFinancialInstitutionAction,
  FinancialInstitution,
  FETCH_FINANCIAL_INSTITUTION,
  FETCH_FINANCIAL_INSTITUTION_SUCCESS,
  FETCH_FINANCIAL_INSTITUTION_ERROR,
} from '../actions/fetchFinancialInstitutions';

type FinancialInstitutionAction = fetchFinancialInstitutionAction;

export interface FinancialInstitutionState {
  financialInstitutions: FinancialInstitution[];
}

const initialState: FinancialInstitutionState = {
  financialInstitutions: [],
};

const financialInstitutionReducer = (
  state = initialState,
  action: FinancialInstitutionAction
) => {
  switch (action.type) {
    case FETCH_FINANCIAL_INSTITUTION:
      return Object.assign({}, state, {});
    case FETCH_FINANCIAL_INSTITUTION_SUCCESS:
      return Object.assign({}, state, {
        financialInstitutions: action.payload.financialInstitutions,
      });
    case FETCH_FINANCIAL_INSTITUTION_ERROR:
      return Object.assign({}, state, {});
    default:
      return state;
  }
};

export default financialInstitutionReducer;
