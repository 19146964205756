import {post} from './http';

const postPrepaymentApplication = (
  paymentMethod: number,
  applicationAmount: number,
  applicationFeeVersion: string | null,
  authToken: string,
  employeeID: number | null
) => {
  const data: {
    paymentMethod: number;
    applicationAmount: number;
    applicationFeeVersion?: any | null;
  } = {paymentMethod, applicationAmount};
  if (applicationFeeVersion) {
    data.applicationFeeVersion = applicationFeeVersion;
  }
  return post('/prepayment_applications', data, authToken, null, employeeID);
};

export default postPrepaymentApplication;
