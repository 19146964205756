import ReportError from './reportError';

export const FETCH_FINANCIAL_INSTITUTION_BRANCHES =
  'FETCH_FINANCIAL_INSTITUTION_BRANCHES';
export const FETCH_FINANCIAL_INSTITUTION_BRANCHES_SUCCESS =
  'FETCH_FINANCIAL_INSTITUTION_BRANCHES_SUCCESS';
export const FETCH_FINANCIAL_INSTITUTION_BRANCHES_ERROR =
  'FETCH_FINANCIAL_INSTITUTION_BRANCHES_ERROR';

const fetchFinancialInstitutionBranches = (
  employeeID: number | null,
  financialCode: string,
  branchName: string
) => ({
  type: FETCH_FINANCIAL_INSTITUTION_BRANCHES as typeof FETCH_FINANCIAL_INSTITUTION_BRANCHES,
  payload: {employeeID, financialCode, branchName},
});

export interface FinancialInstitutionBranch {
  branchCode: string;
  branchName: string;
}

export interface FinancialInstitutionBranchesSuccess {
  type: typeof FETCH_FINANCIAL_INSTITUTION_BRANCHES_SUCCESS;
  payload: {
    financialInstitutionBranches: FinancialInstitutionBranch[];
  };
}

export type fetchFinancialInstitutionBranchesAction =
  | ReturnType<typeof fetchFinancialInstitutionBranches>
  | FinancialInstitutionBranchesSuccess
  | ReportError<typeof FETCH_FINANCIAL_INSTITUTION_BRANCHES_ERROR>;

export default fetchFinancialInstitutionBranches;
