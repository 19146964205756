import {
  FETCH_ACCOUNTING_HISTORIES_SUCCESS,
  FETCH_ACCOUNTING_HISTORIES_ERROR,
  FetchBalanceHistoriesAction,
  FETCH_ACCOUNTING_HISTORIES,
  // BalanceHistoriesItem,
} from '../actions/fetchBalanceHistories';

export interface BankDetail {
  accountType: number;
  branchCode: string;
  branchName: string;
  accountNumber: string;
  financialInstitutionCode: string;
  financialInstitutionName: string;
  transferDate: string;
}

export interface SevenDetail {
  partnerCode: string;
  customerNo: string;
  confirmNo: string;
  deadlineDate: string;
  receivedAt: string;
}

export interface AttendanceDetail {
  workDate: string;
  expirationDate: string;
}

export interface BalanceHistory {
  historyId: number;
  historyStatus: string;
  transferStatus: string | null;
  historyDate: string;
  changedAmount: number;
  balance: number;
  paymentMethod: number;
  applicationNumber: string;
  transferAmount: number;
  systemFee: number;
  transferFee: number;
  bankDetail: BankDetail | {};
  sevenDetail: SevenDetail | {};
  attendanceDetail: AttendanceDetail | {};
}

export interface BalanceHistoriesState {
  balanceHistories: BalanceHistory[];
  success: boolean;
  reset: boolean;
  loadingHistories: boolean;
  cursor: string | null;
  nextCursor: string | null;
}

const initialState: BalanceHistoriesState = {
  balanceHistories: [
    {
      historyId: 1,
      historyStatus: '2-2',
      transferStatus: '1-1',
      historyDate: '2022-11-20T00:00:00+09:00',
      changedAmount: 5550,
      balance: 15000,
      paymentMethod: 2,
      applicationNumber: '12345678',
      transferAmount: 5000,
      systemFee: 100,
      transferFee: 450,
      bankDetail: {},
      sevenDetail: {
        partnerCode: '1234',
        customerNo: '12345678',
        confirmNo: '5678',
        deadlineDate: '2022-11-18T00:00:00+09:00',
        receivedAt: '2022-11-18T00:00:00+09:00',
      },
      attendanceDetail: {
        workDate: '2022-11-18T00:00:00+09:00',
        expirationDate: '2022-11-18T00:00:00+09:00',
      },
    },
  ],
  loadingHistories: false,
  success: false,
  reset: false,
  cursor: null,
  nextCursor: null,
};

type BalanceHistoriesAction = FetchBalanceHistoriesAction;

const balanceHistoriesReducer = (
  state = initialState,
  action: BalanceHistoriesAction
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_HISTORIES:
      return Object.assign({}, state, {loadingHistories: true});
    case FETCH_ACCOUNTING_HISTORIES_SUCCESS:
      return Object.assign({}, state, {
        balanceHistories: action.payload.balanceHistories,
        reset: !action.payload.cursor,
        success: true,
        loadingHistories: false,
        cursor: action.payload.cursor,
        nextCursor: action.payload.nextCursor,
      });
    case FETCH_ACCOUNTING_HISTORIES_ERROR:
      return Object.assign({}, state, {loadingHistories: false});
    default:
      return Object.assign({}, state, {
        success: false,
      });
  }
};

export default balanceHistoriesReducer;
