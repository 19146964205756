export const REMOVE_FINANCIAL_INSTITUTION_BRANCHES =
  'REMOVE_FINANCIAL_INSTITUTION_BRANCHES';
export const REMOVE_FINANCIAL_INSTITUTION_BRANCHES_SUCCESS =
  'REMOVE_FINANCIAL_INSTITUTION_BRANCHES_SUCCESS';

const removeFinancialInstitutionBranches = () => ({
  type: REMOVE_FINANCIAL_INSTITUTION_BRANCHES as typeof REMOVE_FINANCIAL_INSTITUTION_BRANCHES,
});

interface RemoveFinancialInstitutionBranchesSuccess {
  type: typeof REMOVE_FINANCIAL_INSTITUTION_BRANCHES_SUCCESS;
}

export type removeFinancialInstitutionBranchesAction =
  | ReturnType<typeof removeFinancialInstitutionBranches>
  | RemoveFinancialInstitutionBranchesSuccess;

export default removeFinancialInstitutionBranches;
