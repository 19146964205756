export const SET_ANALYTICS = 'SET_ANALYTICS';

const setAnalytics = (params: {}) => ({
  type: SET_ANALYTICS as typeof SET_ANALYTICS,
  payload: params,
});

export type SetAnalyticsAction = ReturnType<typeof setAnalytics>;

export default setAnalytics;
