import constants from '../constants';

export const getOemName = () => {
  if (process.env.REACT_APP_OEM_CODE) {
    const {oemCodeNameMap} = constants;
    const oemName = oemCodeNameMap[process.env.REACT_APP_OEM_CODE];
    return oemName;
  }

  return 'cria';
};

export const getOemCode = () => {
  if (process.env.REACT_APP_OEM_CODE) {
    return process.env.REACT_APP_OEM_CODE;
  }

  return null;
};
