import {put, call, takeEvery} from 'redux-saga/effects';
import {AUTHENTICATION_FAILURE_COMPLETE} from '../actions/authFailure';
import fetchNotifications, {
  NotificationItem,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
} from '../actions/fetchNotifications';
import getNotifications from '../api/getNotifications';
import localStorage from '../util/localStorage';
import reportError from './reportError';

function* handleFetchNotifications(
  action: ReturnType<typeof fetchNotifications>
) {
  try {
    const authToken: string = yield localStorage.authToken;
    const resp: {data: {cursor: string | null; items: NotificationItem[]}} =
      yield call(getNotifications, action.payload.cursor, authToken);
    yield put({
      type: FETCH_NOTIFICATIONS_SUCCESS,
      payload: {
        notificationItems: resp.data.items,
        cursor: resp.data.cursor,
      },
    });
  } catch (e: any) {
    if (e.response && e.response.status === 403) {
      yield put({
        type: AUTHENTICATION_FAILURE_COMPLETE,
      });
      return;
    }

    yield reportError(e, FETCH_NOTIFICATIONS_ERROR);
  }
}

function* notificationsSaga() {
  yield takeEvery(FETCH_NOTIFICATIONS, handleFetchNotifications);
}

export default notificationsSaga;
