import ReportError from './reportError';

export const RECREATE_CONFRIRATMION_CODE = 'RECREATE_CONFRIRATMION_CODE';
export const RECREATE_CONFRIRATMION_CODE_SUCCESS =
  'RECREATE_CONFRIRATMION_CODE_SUCCESS';
export const RECREATE_CONFRIRATMION_CODE_EXPIRED =
  'RECREATE_CONFRIRATMION_CODE_EXPIRED';
export const RECREATE_CONFRIRATMION_CODE_EXPIRED_CANCEL =
  'RECREATE_CONFRIRATMION_CODE_EXPIRED_CANCEL';
export const RECREATE_CONFRIRATMION_CODE_ERROR =
  'RECREATE_CONFRIRATMION_CODE_ERROR';

export interface Meta {
  //  routeOnSuccess: {pathname?: string};
}

const recreateConfirmationCode = () => ({
  type: RECREATE_CONFRIRATMION_CODE as typeof RECREATE_CONFRIRATMION_CODE,
  payload: {},
});

export const recreateConfirmationCodeCancel = () => ({
  type: RECREATE_CONFRIRATMION_CODE_EXPIRED_CANCEL as typeof RECREATE_CONFRIRATMION_CODE_EXPIRED_CANCEL,
  payload: {},
});

export interface ConfirmationCodeRecreateSuccess {
  type: typeof RECREATE_CONFRIRATMION_CODE_SUCCESS;
  payload: {
    recreatablesAt: string;
  };
}

interface ConfirmationCodeRecreateExpired {
  type: typeof RECREATE_CONFRIRATMION_CODE_EXPIRED;
  payload: {};
}

interface ConfirmationCodeRecreateExpiredCancel {
  type: typeof RECREATE_CONFRIRATMION_CODE_EXPIRED_CANCEL;
  payload: {};
}

export type ConfirmationCodeRecreateAction =
  | ReturnType<typeof recreateConfirmationCode>
  | ConfirmationCodeRecreateSuccess
  | ConfirmationCodeRecreateExpired
  | ConfirmationCodeRecreateExpiredCancel
  | ReportError<typeof RECREATE_CONFRIRATMION_CODE_ERROR>;

export default recreateConfirmationCode;
