import axios from 'axios';
import moment from 'moment';
import constants from '../constants';
import HttpRequestError from '../Errors/HttpRequestError';
import {v4 as uuid} from 'uuid';
import {getOemCode} from '../util/oem';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

function getInstance(
  authToken: string | null = null,
  instanceID: string | null = null,
  employeeID: number | null = null
) {
  const headers: {
    Accept: string;
    Authorization?: string;
  } = {
    Accept: `application/json; version=${constants.apiVersion}`,
  };

  if (authToken !== null) {
    headers.Authorization = 'Bearer ' + authToken;
  }

  if (instanceID !== null) {
    headers['X-Cria-Instance-Id'] = instanceID;
  }

  if (employeeID !== null) {
    headers['X-Cria-Employee-Id'] = employeeID;
  }

  headers['X-Cria-Device-Type'] = constants.deviceType;
  headers['X-Cria-Timestamp'] = moment().format();
  headers['X-Cria-App-Version'] = constants.appVersion;
  headers['X-Cria-Request-Id'] = uuid();
  headers['X-Cria-Oem-Code'] = getOemCode();

  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/mobile-app-api`,
    headers,
  });
  instance.interceptors.response.use(
    response => Promise.resolve(response),
    error => Promise.reject(new HttpRequestError(error))
  );

  return instance;
}

export function get(
  path: string,
  params: {cursor?: string; limit?: number; text?: string} | null = null,
  authToken: string | null = null,
  employeeID: number | null | null = null
) {
  return getInstance(authToken, null, employeeID).get(path, {params});
}

export function post(
  path: string,
  params: {
    customerNo?: string;
    password?: string;
    limit?: number;
    text?: string;
    paymentMethod?: number;
    applicationAmount?: number;
    mailAddress?: string;
    phoneNumber?: string;
    confirmationCodeToken?: string;
  },
  authToken: string | null = null,
  instanceID: string | null = null,
  employeeID: number | null = null
) {
  return getInstance(authToken, instanceID, employeeID).post(path, params);
}

export function put(
  path: string,
  instanceID: string | null,
  params: {
    limit?: number;
    text?: string;
    refreshToken?: string;
    financialInstitutionCode?: string;
    branchCode?: string;
    accountType?: string;
    accountNumber?: string;
    accountName?: string;
    confirmationCode?: string;
    confirmationCodeToken?: string;
  },
  authToken: string | null = null,
  employeeID: number | null = null
) {
  return getInstance(authToken, instanceID, employeeID).put(path, params);
}

export function doDelete(
  path: string,
  params?: {refreshToken: string},
  authToken: string | null = null,
  instanceID: string | null = null,
  employeeID: number | null = null
) {
  return getInstance(authToken, instanceID, employeeID).delete(path, {params});
}
