export const CHOOSE_PIN = 'CHOOSE_PIN';

const choosePin = (pin: string, subtitle?: React.ReactElement<any>) => ({
  type: CHOOSE_PIN as typeof CHOOSE_PIN,
  payload: {
    pin,
    subtitle,
  },
});

export type ChoosePinAction = ReturnType<typeof choosePin>;

export default choosePin;
