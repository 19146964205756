export const RESET_CUSTOMER_NO_REMINDER = 'RESET_CUSTOMER_NO_REMINDER';

const resetCustomerNoReminder = () => ({
  type: RESET_CUSTOMER_NO_REMINDER as typeof RESET_CUSTOMER_NO_REMINDER,
});

export type ResetCustomerNoReminderAction = ReturnType<
  typeof resetCustomerNoReminder
>;

export default resetCustomerNoReminder;
