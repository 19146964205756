import {put, call, takeEvery} from 'redux-saga/effects';
import {AUTHENTICATION_FAILURE_COMPLETE} from '../actions/authFailure';
import fetchBalanceHistories, {
  FETCH_ACCOUNTING_HISTORIES,
  FETCH_ACCOUNTING_HISTORIES_SUCCESS,
  FETCH_ACCOUNTING_HISTORIES_ERROR,
} from '../actions/fetchBalanceHistories';

import {BalanceHistory} from '../reducers/balanceHistories';

import getBalanceHistories from '../api/getBalanceHistories';
import localStorage from '../util/localStorage';
import reportError from './reportError';

function* handleFetchBalanceHistories(
  action: ReturnType<typeof fetchBalanceHistories>
) {
  try {
    const authToken: string = yield localStorage.authToken;
    const resp: {
      data: {
        items: BalanceHistory[];
        cursor: string;
        nextCursor: string;
      };
    } = yield call(
      getBalanceHistories,
      action.payload.cursor,
      authToken,
      action.payload.employeeID
    );
    yield put({
      type: FETCH_ACCOUNTING_HISTORIES_SUCCESS,
      payload: {
        balanceHistories: resp.data.items,
        cursor: resp.data.cursor,
        nextCursor: resp.data.nextCursor,
      },
    });
  } catch (e: any) {
    if (e.response && e.response.status === 403) {
      yield put({
        type: AUTHENTICATION_FAILURE_COMPLETE,
      });
      return;
    }

    yield reportError(e, FETCH_ACCOUNTING_HISTORIES_ERROR);
  }
}

function* balanceHistoriesSaga() {
  yield takeEvery(FETCH_ACCOUNTING_HISTORIES, handleFetchBalanceHistories);
}

export default balanceHistoriesSaga;
