import ReportError from './reportError';

export const CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_AMOUNT = 'SET_AMOUNT';
export const CHANGE_PAYMENT_METHOD_SUCCESS = 'CHANGE_PAYMENT_METHOD_SUCCESS';
export const CHANGE_PAYMENT_METHOD_ERROR = 'CHANGE_PAYMENT_METHOD_ERROR';

const changePaymentMethod = (paymentMethodIndex: number) => ({
  type: CHANGE_PAYMENT_METHOD as typeof CHANGE_PAYMENT_METHOD,
  payload: {paymentMethodIndex},
});

export interface SetPaymentMethodPayload {
  paymentMethodIndex: number;
}

export interface SetPaymentMethod {
  type: typeof SET_PAYMENT_METHOD;
  payload: SetPaymentMethodPayload;
}

export interface SetAmount {
  type: typeof SET_AMOUNT;
}

export interface ChangePaymentMethodSuccess {
  type: typeof CHANGE_PAYMENT_METHOD_SUCCESS;
}

export const setPaymentMethod = (
  payload: SetPaymentMethodPayload
): SetPaymentMethod => ({
  type: SET_PAYMENT_METHOD,
  payload,
});

export const setAmount = (): SetAmount => ({
  type: SET_AMOUNT,
});

export const changePaymentMethodSuccess = (): ChangePaymentMethodSuccess => ({
  type: CHANGE_PAYMENT_METHOD_SUCCESS,
});

export type ChangePaymentMethodAction =
  | ReturnType<typeof changePaymentMethod>
  | ReturnType<typeof setPaymentMethod>
  | ReturnType<typeof setAmount>
  | ReturnType<typeof changePaymentMethodSuccess>
  | ReportError<typeof CHANGE_PAYMENT_METHOD_ERROR>;

export default changePaymentMethod;
