export const CLOSE_APPLICATION_RESULT = 'CLOSE_APPLICATION_RESULT';

const closeApplicationResult = () => ({
  type: CLOSE_APPLICATION_RESULT as typeof CLOSE_APPLICATION_RESULT,
});

export type CloseApplicationResultAction = ReturnType<
  typeof closeApplicationResult
>;

export default closeApplicationResult;
