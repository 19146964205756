import {put} from './http';

const putAuthToken = (
  instanceID: string,
  refreshToken: string,
  authToken: string
) => {
  return put('/auth_token', instanceID, {refreshToken}, authToken);
};

export default putAuthToken;
