import {put} from 'redux-saga/effects';
import {AxiosResponse} from 'axios';
import {AUTHENTICATION_FAILURE} from '../actions/authFailure';
import {SHOW_ALERT} from '../actions/showAlert';
import HttpRequestError from '../Errors/HttpRequestError';
import * as Sentry from '@sentry/browser';
import {FETCH_AUTH_TOKEN_FAILURE} from '../actions/fetchAuthToken';

function* reportError(e: Error, errorActionType: string) {
  if (e instanceof HttpRequestError && e.response) {
    if (
      e.response.status === 403 &&
      errorActionType !== FETCH_AUTH_TOKEN_FAILURE
    ) {
      yield put({type: AUTHENTICATION_FAILURE});
      return;
    }

    const message = getMessageByResponseStatus(e.response);

    yield put({
      type: errorActionType,
      payload: {message},
    });

    yield put({
      type: SHOW_ALERT,
      payload: {
        config: {
          title: 'エラー',
          message,
        },
      },
    });

    return;
  }

  Sentry.captureException(e);

  yield put({
    type: errorActionType,
    error: true,
    payload: {error: e},
  });
}

const getMessageByResponseStatus = (response: AxiosResponse) => {
  let message = '';
  switch (response.status) {
    case 400:
      message = response.data.message;
      break;
    case 401:
      message = 'お客様番号かパスワードに誤りがあります';
      break;
    case 403:
      message =
        'パスワードの入力誤りが一定回数を超えたためご利用頂けません。しばらく経ってから再度お試しください。';
      break;
    case 406:
      message = 'ブラウザをリロードしてください';
      break;
    case 503:
      message =
        response.data && response.data.message
          ? response.data.message
          : 'メンテナンス中です。時間を置いてご利用ください。';
      break;
  }

  return message || 'サーバーエラーが発生しました';
};

export default reportError;
