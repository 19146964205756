import './RightAllowLink.scss';

interface Props {
  children: string;
}

const Component = ({children}: Props) => {
  return (
    <p className='right-allow-link'>
      <span>{children}</span>
    </p>
  );
};

export default Component;
