import './LeftAllowLink.scss';

interface Props {
  children: string;
  onClick?: () => void;
}

const Component = ({children, onClick}: Props) =>
  onClick ? (
    <p onClick={onClick} className='left-allow-link'>
      <span>{children}</span>
    </p>
  ) : (
    <p className='left-allow-link'>
      <span>{children}</span>
    </p>
  );

export default Component;
