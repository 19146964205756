import {put} from './http';

const putConfirmationCode = (
  confirmationCode: string,
  confirmationCodeToken: string,
  instanceID: string
) => {
  const params = {
    confirmationCode,
    confirmationCodeToken,
  };
  return put('/confirmation_code', instanceID, params);
};

export default putConfirmationCode;
