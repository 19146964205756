import {put, call, takeEvery} from 'redux-saga/effects';
import {AUTHENTICATION_FAILURE_COMPLETE} from '../actions/authFailure';
import fetchFinancialInstitutionBranches, {
  FinancialInstitutionBranch,
  FETCH_FINANCIAL_INSTITUTION_BRANCHES,
  FETCH_FINANCIAL_INSTITUTION_BRANCHES_SUCCESS,
  FETCH_FINANCIAL_INSTITUTION_BRANCHES_ERROR,
} from '../actions/fetchFinancialInstitutionBranches';
import getFinancialInstitutionBranches from '../api/getFinancialInstitutionBranches';
import localStorage from '../util/localStorage';
import reportError from './reportError';

function* handleFetchFinancialInstitutionBranches(
  action: ReturnType<typeof fetchFinancialInstitutionBranches>
) {
  try {
    const authToken: string = yield localStorage.authToken;
    const resp: {data: {items: FinancialInstitutionBranch[]}} = yield call(
      getFinancialInstitutionBranches,
      action.payload.financialCode,
      action.payload.branchName,
      authToken,
      action.payload.employeeID
    );
    yield put({
      type: FETCH_FINANCIAL_INSTITUTION_BRANCHES_SUCCESS,
      payload: {financialInstitutionBranches: resp.data.items},
    });
  } catch (e: any) {
    if (e.response && e.response.status === 403) {
      yield put({
        type: AUTHENTICATION_FAILURE_COMPLETE,
      });
      return;
    }

    yield reportError(e, FETCH_FINANCIAL_INSTITUTION_BRANCHES_ERROR);
  }
}

function* financialInstitutionBranchSaga() {
  yield takeEvery(
    FETCH_FINANCIAL_INSTITUTION_BRANCHES,
    handleFetchFinancialInstitutionBranches
  );
}

export default financialInstitutionBranchSaga;
