import {ShowAlertAction, SHOW_ALERT} from '../actions/showAlert';
import {CloseAlertAction, CLOSE_ALERT} from '../actions/closeAlert';
import {AlertConfig} from '../components/parts/Alert';

type AlertAction = ShowAlertAction | CloseAlertAction;

export interface AlertState {
  open: boolean;
  config: AlertConfig;
}

const initialState = {
  open: false,
  config: {
    title: '',
    message: '',
  },
};

const alertReducer = (state = initialState, action: AlertAction) => {
  switch (action.type) {
    case SHOW_ALERT:
      return Object.assign({}, state, {
        open: true,
        config: action.payload.config,
      });
    case CLOSE_ALERT:
      return Object.assign({}, state, initialState, {
        open: false,
      });
    default:
      return state;
  }
};

export default alertReducer;
