import {get} from './http';

const getBalanceHistories = (
  cursor: string | null,
  authToken: string,
  employeeID: number | null
) => {
  const params: {cursor?: string; limit: number} =
    cursor !== null ? {cursor, limit: 10} : {limit: 10};

  return get('/balance_histories', params, authToken, employeeID);
};

export default getBalanceHistories;
