import {
  Language,
  languages,
  SetLanguageAction,
  SET_LANGUAGE,
  SET_LANGUAGE_SUCCESS,
} from '../actions/setLanguage';
import localStorage from '../util/localStorage';

export interface LanguageState {
  selectedLanguage: Language;
}

type LanguageAction = SetLanguageAction;

const currentLanguageCode = localStorage.currentLanguageCode;

let language;
if (currentLanguageCode) {
  language = languages.filter(({code}) => code === currentLanguageCode)[0];
} else {
  language = languages[0];
}
const initialState: LanguageState = {
  selectedLanguage: language,
};

const languageReducer = (state = initialState, action: LanguageAction) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return Object.assign({}, state, {});
    case SET_LANGUAGE_SUCCESS:
      const selectedLanguage = action.payload.language;
      localStorage.currentLanguageCode = selectedLanguage.code;
      return Object.assign({}, state, {
        selectedLanguage,
      });
    default:
      return state;
  }
};

export default languageReducer;
