import ReportError from './reportError';
import {IApplicationFee} from '../reducers/applicationSummary';

export const FETCH_APPLICATION_SUMMARY = 'FETCH_APPLICATION_SUMMARY';
export const FETCH_APPLICATION_SUMMARY_SUCCESS =
  'FETCH_APPLICATION_SUMMARY_SUCCESS';
export const FETCH_APPLICATION_SUMMARY_ERROR =
  'FETCH_APPLICATION_SUMMARY_ERROR';

export interface ApplicationSummary {
  startDate: string;
  endDate: string;
  latestDeadlineDate: string;
  totalAmount: number;
  financialInstitutionName: string;
  branchName: string;
  accountType: number;
  accountNumber: string;
  nextTransferDate: string;
  partnerCode: string;
  customerNo: string;
  paymentMethods: number[];
  maxTransferAmount: number;
  applicationFees: IApplicationFee[];
}

const fetchApplicationSummary = (employeeID: number) => ({
  type: FETCH_APPLICATION_SUMMARY as typeof FETCH_APPLICATION_SUMMARY,
  payload: {employeeID},
});

interface FetchApplicationSummarySuccess {
  type: typeof FETCH_APPLICATION_SUMMARY_SUCCESS;
  payload: {
    applicationSummary: ApplicationSummary;
  };
}

export type FetchApplicationSummaryAction =
  | ReturnType<typeof fetchApplicationSummary>
  | FetchApplicationSummarySuccess
  | ReportError<typeof FETCH_APPLICATION_SUMMARY_ERROR>;

export default fetchApplicationSummary;
