import {i18n} from '../../locale/i18n';
import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {GlobalState} from '../../reducers/index';
import requestPasswordReset from '../../actions/requestPasswordReset';
import DocumentTitle from '../parts/DocumentTitle';
import {checkInputted, checkLength, checkNumbers} from '../../util/validation';
import sendEventAnalytics from '../../actions/sendEventAnalytics';
import showAlert from '../../actions/showAlert';

import CustomerNumber from '../parts/CustomerNumber';
import WhiteOutButton from '../parts/WhiteOutButton';
import RightAllowLink from '../parts/RightAllowLink';
import LeftAllowLink from '../parts/LeftAllowLink';
import Header from '../parts/Headers/Reminder';

import {useTimer} from 'react-timer-hook';
import moment from 'moment';

import {useNavigate, NavLink} from 'react-router-dom';

import {ResetPasswordResultState} from '../../reducers/resetPasswordResult';
import resetCustomerNoReminder from '../../actions/resetCustomerNoReminder';

import './Reminder.scss';
import './ReminderFailed.scss';
import './ReminderSended.scss';
import '../login/loginAndPassword.scss';

import constants from '../../constants';

interface DispatchProps {
  remindPassword: typeof requestPasswordReset;
  sendEvent: typeof sendEventAnalytics;
  callShowAlert: typeof showAlert;
  resetReminderResults: () => void;
}

type Props = ResetPasswordResultState & DispatchProps;

export const PasswordReminder = ({
  remindPassword,
  callShowAlert,
  sendEvent,
  resetPasswordCompleted,
  passwordResetSucceeded,
  resetPasswordDate,
}: Props) => {
  const navigate = useNavigate();
  const {seconds, minutes, restart} = useTimer({
    expiryTimestamp: moment().toDate(),
  });

  const active = seconds === 0 && minutes === 0;
  const waitSeconds = `もう一度送信するには${seconds}秒おまちください`;

  useEffect(() => {
    if (resetPasswordDate) {
      restart(
        moment(resetPasswordDate)
          .add(constants.resetAfterWaitSeconds, 's')
          .toDate()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPasswordDate]);

  const [customerNo, setCustomerNo] = useState('');
  const [returnToLogin, setReturnToLogin] = useState(false);
  useEffect(() => {
    if (returnToLogin) {
      navigate('/');
    }
  }, [navigate, returnToLogin]);

  const submit = () => {
    sendEvent('PasswordReset', 'password reset button click');

    let message: string | null = null;
    if (checkInputted(customerNo)) {
      message = i18n.t('alert.customerNumberEmpty');
    } else if (checkLength(customerNo, 8)) {
      message = i18n.t('alert.customerNumberNoMatchLength');
    } else if (checkNumbers(customerNo)) {
      message = i18n.t('alert.customerNumberNotInt');
    }
    if (message !== null) {
      sendEvent('PasswordReset', 'password reset validation error');
      callShowAlert({
        title: i18n.t('alert.inputError'),
        message,
      });
      return;
    } else {
      remindPassword(customerNo);
    }
  };
  if (resetPasswordCompleted && passwordResetSucceeded) {
    return (
      <>
        <Header />
        <main className='login-and-password reminder-sended'>
          <DocumentTitle pageNameInTitle='ログイン画面 パスワードを忘れた方' />
          <div className='contents-inner'>
            <h2>{i18n.t('passwordResetDone.title')}</h2>
            <h3>パスワード再設定のご案内をお送りしました。</h3>
            <p>数分経ってもメールまたはメッセージ（SMS）が届かない場合は、</p>
            <p>一度所属会社に登録情報の確認をお願いいたします。</p>

            <form>
              <div className='submit'>
                {seconds > 0 ? <p className='error'>{waitSeconds}</p> : <></>}
                <WhiteOutButton onClick={submit} active={active}>
                  {i18n.t('passwordResetDone.resend')}
                </WhiteOutButton>
              </div>
            </form>

            <LeftAllowLink
              onClick={() => {
                sendEvent('Login', 'login link click');
                setReturnToLogin(true);
              }}
            >
              {i18n.t('passwordResetDone.linkLogin')}
            </LeftAllowLink>
          </div>
        </main>
      </>
    );
  }

  if (resetPasswordCompleted && !passwordResetSucceeded) {
    const failureDescriptions = i18n
      .t('passwordReset.failureDescription')
      .split('\n');
    return (
      <>
        <Header />
        <main className='reminder-failed'>
          <DocumentTitle
            pageNameInTitle={i18n.t('customerNoReminder.linkPasswordReminder')}
          />
          <div className='contents-inner'>
            <img alt='error' src='/img/reminder/error.svg' />

            <h2>{i18n.t('passwordReset.failureTitle')}</h2>
            {failureDescriptions.map((description: string) => (
              <p key={description}>{description}</p>
            ))}

            <form>
              <CustomerNumber
                name='customerNo'
                onChange={e => setCustomerNo(e.target.value)}
                value={customerNo}
              />

              <div className='submit'>
                {seconds > 0 ? <p className='error'>{waitSeconds}</p> : <></>}
                <WhiteOutButton onClick={submit} active={active}>
                  {i18n.t('button.send')}
                </WhiteOutButton>
              </div>
            </form>
            <NavLink
              to='/customer-no-reminder'
              onClick={() =>
                sendEvent(
                  'CustomerNoReminder',
                  'customer no reminder login link click'
                )
              }
            >
              <RightAllowLink>
                {i18n.t('passwordReset.linkCustomerNumberReminder')}
              </RightAllowLink>
            </NavLink>
            <NavLink
              to='/login'
              onClick={() => sendEvent('Login', 'login link click')}
            >
              <LeftAllowLink>
                {i18n.t('passwordResetDone.linkLogin')}
              </LeftAllowLink>
            </NavLink>
          </div>
        </main>
      </>
    );
  }

  const descriptions = i18n.t('passwordReset.description').split('\n');
  return (
    <>
      <Header />
      <main className='reminder'>
        <DocumentTitle pageNameInTitle='ログイン画面 パスワードを忘れた方' />
        <div className='contents-inner'>
          <h2>{i18n.t('passwordReset.title')}</h2>
          {descriptions.map((description: string) => (
            <p key={description}>{description}</p>
          ))}

          <form>
            <CustomerNumber
              name='customerNo'
              onChange={e => setCustomerNo(e.target.value)}
              value={customerNo}
            />

            <div className='submit'>
              {seconds > 0 ? <p className='error'>{waitSeconds}</p> : <></>}
              <WhiteOutButton onClick={submit} active={active}>
                {i18n.t('button.send')}
              </WhiteOutButton>
            </div>
          </form>
          <NavLink
            to='/customer-no-reminder'
            onClick={() =>
              sendEvent(
                'CustomerNoReminder',
                'customer no reminder login link click'
              )
            }
          >
            <RightAllowLink>
              {i18n.t('passwordReset.linkCustomerNumberReminder')}
            </RightAllowLink>
          </NavLink>
          <NavLink
            to='/login'
            onClick={() => sendEvent('Login', 'login link click')}
          >
            <LeftAllowLink>
              {i18n.t('passwordResetDone.linkLogin')}
            </LeftAllowLink>
          </NavLink>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  ...state.authToken,
  ...state.resetPasswordResult,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  resetReminderResults: () => {
    dispatch(resetCustomerNoReminder());
  },
  remindPassword: (customerNo, meta) =>
    dispatch(requestPasswordReset(customerNo, meta)),
  sendEvent: (category, action) =>
    dispatch(sendEventAnalytics(category, action)),
  callShowAlert: config => dispatch(showAlert(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReminder);
