export const OPEN_HISTORY_DETAIL = 'OPEN_HISTORY_DETAIL';

const openHistoryDetail = (applicationNumber: string) => ({
  type: OPEN_HISTORY_DETAIL as typeof OPEN_HISTORY_DETAIL,
  payload: {
    applicationNumber,
  },
});

export type OpenHistoryDetailAction = ReturnType<typeof openHistoryDetail>;

export default openHistoryDetail;
