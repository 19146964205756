import {i18n} from '../../locale/i18n';
import './Settings.scss';

import {AlertState} from '../../reducers/alert';
import {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {GlobalState} from '../../reducers/index';
import {FranchiseStoresState} from '../../reducers/franchiseStores';
import {UserInfoState} from '../../reducers/userInfo';
import SideBar, {selectedPages} from '../../components/parts/SideBar';
import {accountTypes} from '../home/constants';
import WhiteOutButton from '../../components/parts/WhiteOutButton';
import LanguageSelector from './LanguageSelector';
import Header from '../../components/parts/Headers/UpperLimitAmount';
import {customerNoWithSpace} from '../../util/number';
import LogoutConfirm from './LogoutConfirm';
import fetchUserInfo from '../../actions/fetchUserInfo';
import fetchApplicationSummary from '../../actions/fetchApplicationSummary';
import {Dispatch} from 'redux';
import DocumentTitle from '../parts/DocumentTitle';
import RestrictedPage from '../auth/RestrictedPage';
import {getOemName} from '../../util/oem';

interface DispatchProps {
  loadUserInfo: typeof fetchUserInfo;
  reload: typeof fetchApplicationSummary;
}
type StatesFromReducer = FranchiseStoresState & UserInfoState & AlertState;

const HistoryList = ({
  selected,
  user,
  open,
  loadUserInfo,
  reload,
}: StatesFromReducer & DispatchProps) => {
  const {
    financialInstitutionName,
    branchName,
    accountType,
    accountNumber,
    accountName,
    givenName,
    surname,
    mailAddress,
    customerNo,
    employeeCode,
  } = user;
  const [confirmOpened, setConfirmOpened] = useState(false);

  useEffect(() => {
    if (selected) {
      loadUserInfo(selected.employeeID);
      reload(selected.employeeID);
    }
  }, [reload, open, loadUserInfo, selected]);

  if (!selected) {
    return <RestrictedPage />;
  }
  const {franchiseStoreName} = selected;

  const html = document.querySelector('html');
  if (html) {
    html.scrollTop = 0;
  }

  const oemName = getOemName();

  return (
    <RestrictedPage>
      <DocumentTitle pageNameInTitle={i18n.t('settings.settings')} />
      <Header />
      <main id='settings'>
        <link
          href='https://fonts.googleapis.com/css?family=Manrope'
          rel='stylesheet'
        />
        <section>
          <section>
            <section>
              <div>
                <h2>{i18n.t('userInfo.information')}</h2>
              </div>
              <div>
                <h2>
                  <img
                    alt='person'
                    src={`/img/settings/${oemName}/person.svg`}
                  />
                  <p>
                    {surname || ''} {givenName || ''}
                  </p>
                </h2>
                <dl>
                  <dt>{i18n.t('userInfo.company')}</dt>
                  <dd>{franchiseStoreName}</dd>
                  <dt>{i18n.t('common.customerNumber')}</dt>
                  <dd>{customerNoWithSpace(customerNo || '')}</dd>
                  <dt>{i18n.t('userInfo.employeeCode')}</dt>
                  <dd>{employeeCode}</dd>
                  <dt>{i18n.t('userInfo.mailAddress')}</dt>
                  <dd>{mailAddress}</dd>
                  <dt>{i18n.t('userInfo.account')}</dt>
                  <dd>
                    <ul>
                      <li>
                        <p>{i18n.t('common.bank')}</p>
                        <p>{financialInstitutionName}</p>
                      </li>
                      <li>
                        <p>{i18n.t('common.branch')}</p>
                        <p>{branchName}</p>
                      </li>
                      <li>
                        <p>{i18n.t('common.accountType')}</p>
                        <p>{accountType ? accountTypes[accountType] : ''}</p>
                      </li>
                      <li>
                        <p>{i18n.t('common.accountNumber')}</p>
                        <p>{accountNumber}</p>
                      </li>
                      <li>
                        <p>{i18n.t('userInfo.accountHolder')}</p>
                        <p>{accountName}</p>
                      </li>
                    </ul>
                  </dd>
                </dl>
                {selected && selected.allowEmployeeEditAccount ? (
                  <NavLink to='/settings/bank'>
                    <WhiteOutButton>登録口座の変更</WhiteOutButton>
                  </NavLink>
                ) : (
                  <></>
                )}
              </div>
            </section>
            <div className='languages'>
              <h3>
                <p>{i18n.t('settings.languageSettings')}</p>
              </h3>
              <LanguageSelector />
              <img alt='language' src='/img/settings/arrow-bottom.svg' />
            </div>
            <a rel='noopener noreferrer' href='/terms' target='_blank'>
              <div className='terms'>
                <h3>
                  <p>{i18n.t('settings.termOfUse')}</p>
                  <img alt='logo' src='/img/settings/logo.svg' />
                </h3>
                <img alt='right-allow' src='/img/settings/arrow-right.svg' />
              </div>
            </a>
            <a rel='noopener noreferrer' href='/privacy-policy' target='_blank'>
              <div className='privacy-policy'>
                <h3>
                  <p>{i18n.t('settings.privacyPolicy')}</p>
                  <img alt='logo' src='/img/settings/logo.svg' />
                </h3>
                <img alt='right-allow' src='/img/settings/arrow-right.svg' />
              </div>
            </a>
            <div className='logout'>
              <button
                onClick={() => {
                  setConfirmOpened(true);
                }}
              >
                {i18n.t('settings.logout')}
              </button>
            </div>
          </section>
          <SideBar selectedPage={selectedPages.settings} />
        </section>
        <footer>© Metaps Payment Inc.</footer>
        <LogoutConfirm
          confirmOpened={confirmOpened}
          setConfirmOpened={setConfirmOpened}
        />
      </main>
    </RestrictedPage>
  );
};

const mapStateToProps = (state: GlobalState): StatesFromReducer => ({
  ...state.alert,
  ...state.franchiseStores,
  ...state.userInfo,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadUserInfo: employeeID => dispatch(fetchUserInfo(employeeID)),
  reload: employeeID => dispatch(fetchApplicationSummary(employeeID)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HistoryList);
