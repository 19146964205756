import {routerMiddleware} from 'connected-react-router';
import {History} from 'history';
import {applyMiddleware, createStore, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';

import reducer from './reducers/index';
import rootSaga from './sagas/index';

const configureStore = (history: History) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

  const store = createStore(
    reducer(history),
    compose(applyMiddleware(...middlewares), sentryReduxEnhancer)
  );

  sagaMiddleware.run(rootSaga, {history});

  return store;
};

export default configureStore;
