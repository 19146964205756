export const REMOVE_AUTH_TOKEN = 'REMOVE_AUTH_TOKEN';
export const REMOVE_AUTH_TOKEN_COMPLETED = 'REMOVE_AUTH_TOKEN_COMPLETED';

const removeAuthToken = () => ({
  type: REMOVE_AUTH_TOKEN as typeof REMOVE_AUTH_TOKEN,
});

interface RemoveAuthTokenCompleted {
  type: typeof REMOVE_AUTH_TOKEN_COMPLETED;
}

export type RemoveAuthTokenAction =
  | ReturnType<typeof removeAuthToken>
  | RemoveAuthTokenCompleted;

export default removeAuthToken;
