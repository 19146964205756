import {createBrowserHistory} from 'history';
import {Provider} from 'react-redux';
import App from './components/App';
import configureStore from './configureStore';
import './index.css';
import * as ReactGA from 'react-ga';
import {createRoot} from 'react-dom/client';

import {getOemName} from './util/oem';
import {HelmetProvider} from 'react-helmet-async';

const history = createBrowserHistory();
const store = configureStore(history);

ReactGA.initialize('UA-141198593-1');

// history.listen(({pathname}) => {
//   ReactGA.pageview(pathname);
// });

const container = document.getElementById('root');

const body = document.querySelector('body');
if (body) {
  body.classList.add(getOemName());
}

if (container) {
  const root = createRoot(container);
  root.render(
    <HelmetProvider>
      <Provider store={store}>
        <App history={history} />
      </Provider>
    </HelmetProvider>
  );
}
