import {useState} from 'react';
import {connect} from 'react-redux';
import './LanguageSelector.scss';
import {Dispatch} from 'redux';
import ReactModal from 'react-modal';
import {GlobalState} from '../../reducers/index';
import {LanguageState} from '../../reducers/languages';
import setLanguages, {languages, Language} from '../../actions/setLanguage';

const Component = ({
  selectLanguage,
  selectedLanguage,
}: LanguageState & DispatchProps) => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <ReactModal
        ariaHideApp={false}
        isOpen={opened}
        onRequestClose={() => {
          setOpened(false);
        }}
        contentLabel='Settings'
        overlayClassName='overlay'
        className='language-pulldowns'
      >
        <ul>
          {languages.map(language => (
            <li
              onClick={() => {
                selectLanguage(language);
                setOpened(false);
              }}
              key={language.code}
            >
              {selectedLanguage === language ? (
                <>
                  <img
                    alt='selected'
                    src='/img/parts/language-selector/selected.png'
                  />
                  <div className='selected'>{language.name}</div>
                </>
              ) : (
                <div>{language.name}</div>
              )}
            </li>
          ))}
        </ul>
      </ReactModal>
      <p className='current-language' onClick={() => setOpened(true)}>
        <div>{selectedLanguage.name}</div>
      </p>
    </>
  );
};

interface DispatchProps {
  selectLanguage: typeof setLanguages;
}

const mapStateToProps = (state: GlobalState): LanguageState => state.language;
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  selectLanguage: (language: Language) => dispatch(setLanguages(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
