import {i18n} from '../../locale/i18n';
import './Terms.scss';
import './PrivacyPolicyAndTerms.scss';
import Header from '../../components/parts/Headers/OnlyLogo';
import DocumentTitle from '../parts/DocumentTitle';
import {languages} from '../../actions/setLanguage';
interface Props {
  noDetails?: boolean;
}
const Component = ({noDetails = false}: Props) => {
  const termBody = renderTermBody();
  const match = window.location.href.match(/language_code=(\w+)/);
  let foreignLanguageTermBody = <></>;
  let translaterCompany = <></>;
  if (match) {
    languages.forEach(language => {
      if (language.code === match[1] && language.code !== 'ja') {
        i18n.locale = match[1];
        foreignLanguageTermBody = renderTermBody();
        translaterCompany = (
          <div className='translater-company'>
            <p>【翻訳会社情報】</p>
            <p>会社名 ： ワールドアイ株式会社</p>
            <p>代表者名 ： 木戸　秀典</p>
            <p>企業HP　：{'https://www.world-i-english.net/english/'}</p>
          </div>
        );
      }
    });
  }
  const noDetailsMatch =
    window.location.href.match(/no_details=1/) || noDetails;
  return (
    <>
      <DocumentTitle pageNameInTitle={i18n.t('settings.termOfUse')} />
      <div
        id='terms'
        className={
          noDetailsMatch
            ? 'privacy-policy-and-terms no-details'
            : 'privacy-policy-and-terms'
        }
      >
        {noDetailsMatch ? <></> : <Header />}
        <main>
          <link
            href='https://fonts.googleapis.com/css?family=Manrope'
            rel='stylesheet'
          />
          <section>
            {foreignLanguageTermBody}
            {translaterCompany}
            {termBody}
          </section>
        </main>
        {noDetailsMatch ? <></> : <footer>© Metaps Payment Inc.</footer>}
      </div>
    </>
  );
};

const renderTermBody = () => {
  const termOfUses: any = i18n.t('settings.termOfUses');
  const updateHistories: any = i18n.t('settings.updateHistories');
  return (
    <section>
      <div className='term-title'>
        <h2>{i18n.t('settings.termOfUse')}</h2>
        <p>{i18n.t('settings.forUsers')}</p>
      </div>

      <p className='term-first-sentence'>
        {i18n.t('settings.termOfUseFirstSentence')}
      </p>

      {(
        termOfUses as {
          title: string;
          content: string;
          list: {
            index: number;
            content: string;
            innerList?: {index: number; content: string}[];
          }[];
          bracketList: {index: number; content: string}[];
        }[]
      ).map(({title, content, list, bracketList}) => (
        <section key={title}>
          <h3>{title}</h3>
          <p>{content}</p>
          <ul className='number'>
            {list.map(row => (
              <li key={row.index}>
                <div>
                  <p>{row.index}.</p>
                  <p>{row.content}</p>
                </div>
                <ul className='bracket'>
                  {(row.innerList || []).map(innerRow => {
                    const contents = innerRow.content.split('\n');
                    const firstRow = contents.shift();
                    return (
                      <li key={innerRow.index}>
                        <div>
                          <p>{innerRow.index}）</p>
                          <p>{firstRow}</p>
                        </div>
                        {contents.map(contentRow => (
                          <div key={contentRow}>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;{contentRow}</p>
                          </div>
                        ))}
                      </li>
                    );
                  })}
                </ul>
              </li>
            ))}
          </ul>
          <ul className='bracket'>
            {bracketList.map(row => (
              <li key={row.index}>
                <div>
                  <p>{row.index}）</p>
                  <p>{row.content}</p>
                </div>
              </li>
            ))}
          </ul>
          <br />
        </section>
      ))}
      <p>
        ―{i18n.t('settings.endOfDocument')}―
        <br />
        {i18n.t('settings.corporateName')}
      </p>

      <ul className='update-history'>
        {(updateHistories as string[]).map((history: string) => (
          <li key={history}>{history}</li>
        ))}
      </ul>
    </section>
  );
};

export default Component;
