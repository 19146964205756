import reportError from './reportError';

import {call, put, takeEvery} from 'redux-saga/effects';
import localStorage from '../util/localStorage';
import verifyConfirmationCode, {
  VERIFY_CONFRIRATMION_CODE,
  VERIFY_CONFRIRATMION_CODE_SUCCESS,
  VERIFY_CONFRIRATMION_CODE_EXPIRED,
  VerifyConfirmationCodeSuccess,
  VERIFY_CONFRIRATMION_CODE_ERROR,
} from '../actions/verifyConfirmationCode';

import recreateConfirmationCode, {
  RECREATE_CONFRIRATMION_CODE,
  RECREATE_CONFRIRATMION_CODE_SUCCESS,
  RECREATE_CONFRIRATMION_CODE_EXPIRED,
} from '../actions/recreateConfirmationCode';

import {AGREE_WITH_THE_TERMS} from '../actions/agreeWithTheTerms';
import {FETCH_FRANCHISE_STORES} from '../actions/fetchFranchiseStores';
import {Context} from '../sagas/index';
import {SHOW_ALERT} from '../actions/showAlert';

import putConfirmationCode from '../api/putConfirmationCode';
import postConfirmationCode from '../api/postConfirmationCode';
import moment from 'moment';
import constants from '../constants';
import {i18n} from '../locale/i18n';

function* handleVerifyConfirmationCode(
  action: ReturnType<typeof verifyConfirmationCode>
) {
  const {confirmationCode} = action.payload;
  const confirmationCodeToken = localStorage.confirmationCodeToken;

  const instanceID: string = yield localStorage.instanceID;

  try {
    const resp: {data: {refreshToken: string; authToken: string}} = yield call(
      putConfirmationCode,
      confirmationCode,
      confirmationCodeToken,
      instanceID
    );

    yield (localStorage.refreshToken = resp.data.refreshToken);

    const newAuthToken = resp.data.authToken;
    yield (localStorage.authToken = newAuthToken);
    const isAppPasswordSet = !!localStorage.appPassword;

    yield put({
      type: FETCH_FRANCHISE_STORES,
    });

    yield (localStorage.confirmationCodeToken = null);
    yield (localStorage.recreatablesAt = null);
    yield (localStorage.confirmationCodeSendMailAddress = null);
    yield (localStorage.confirmationCodeSendPhoneNumber = null);

    // pass auth token for notification detail
    yield put({
      type: VERIFY_CONFRIRATMION_CODE_SUCCESS,
      payload: {authToken: newAuthToken, isAppPasswordSet},
      meta: action.meta,
    });
  } catch (e: any) {
    if (e.response.data && e.response.status === 451) {
      yield (localStorage.refreshToken = e.response.data.authToken);
      yield (localStorage.authToken = e.response.data.refreshToken);
      yield put({
        type: AGREE_WITH_THE_TERMS,
      });
      yield put({
        type: SHOW_ALERT,
        payload: {
          config: {
            title: null,
            message: i18n.t('settings.needAgreeWithTheTerms'),
          },
        },
      });
      return;
    } else if (e.response && e.response.status === 401) {
      yield put({
        type: SHOW_ALERT,
        payload: {
          config: {
            title: 'エラー',
            message: '確認コードが誤っています',
          },
        },
      });
      yield put({
        type: VERIFY_CONFRIRATMION_CODE_ERROR,
      });
    } else if (e.response && e.response.status === 403) {
      // パスワード入力を規定回数以上誤った場合
      yield put({
        type: SHOW_ALERT,
        payload: {
          config: {
            title: 'エラー',
            message:
              '確認コードの入力を規定回数以上誤りました。\nお客様番号/パスワードの入力からやりなおしてください。',
          },
        },
      });

      yield put({
        type: VERIFY_CONFRIRATMION_CODE_EXPIRED,
        payload: {},
      });
    } else {
      yield reportError(e, VERIFY_CONFRIRATMION_CODE_ERROR);
    }
  }
}

function* handleVerifyConfirmationCodeSuccess(
  context: Context,
  action: VerifyConfirmationCodeSuccess
) {
  const {meta} = action;
  if (meta.routeOnSuccess) {
    yield call(context.history.replace, meta.routeOnSuccess);
  }
}

function* handleConfirmationCodeRecreate(
  action: ReturnType<typeof recreateConfirmationCode>
) {
  const confirmationCodeToken = localStorage.confirmationCodeToken;

  const instanceID: string = yield localStorage.instanceID;

  try {
    const resp: {
      data: {
        confirmationCodeToken: string;
      };
    } = yield call(postConfirmationCode, confirmationCodeToken, instanceID);

    const {DATE_RFC2822, confirmationCodeRecreateIntervalSeconds} = constants;
    const recreatablesAt = moment()
      .add(confirmationCodeRecreateIntervalSeconds, 's')
      .format(DATE_RFC2822);
    yield (localStorage.confirmationCodeToken =
      resp.data.confirmationCodeToken);
    yield (localStorage.recreatablesAt = recreatablesAt);

    yield put({
      type: SHOW_ALERT,
      payload: {
        config: {
          title: '',
          message: '確認コードを更新しました',
        },
      },
    });

    yield put({
      type: RECREATE_CONFRIRATMION_CODE_SUCCESS,
      payload: {recreatablesAt},
    });
  } catch (e: any) {
    if (e.response && e.response.status === 409) {
      yield put({
        type: SHOW_ALERT,
        payload: {
          config: {
            title: 'エラー',
            message:
              '確認コード作成がロックされています。\n少し待って再試行してください。',
          },
        },
      });
      return;
    }

    if (e.response && e.response.status === 403) {
      // たぶんexpireしてる時
      yield put({
        type: SHOW_ALERT,
        payload: {
          config: {
            title: 'エラー',
            message:
              '確認コードが失効しています。\nお客様番号/パスワードの入力からやりなおしてください。',
          },
        },
      });

      yield put({
        type: RECREATE_CONFRIRATMION_CODE_EXPIRED,
        payload: {},
      });
    } else {
      yield reportError(e, VERIFY_CONFRIRATMION_CODE_ERROR);
    }
  }
}

function* confirmationCodeSaga(context: Context) {
  yield takeEvery(VERIFY_CONFRIRATMION_CODE, handleVerifyConfirmationCode);
  yield takeEvery(
    VERIFY_CONFRIRATMION_CODE_SUCCESS,
    handleVerifyConfirmationCodeSuccess,
    context
  );

  yield takeEvery(RECREATE_CONFRIRATMION_CODE, handleConfirmationCodeRecreate);
}

export default confirmationCodeSaga;
