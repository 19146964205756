import {put} from './http';

const putUser = (
  authToken: string,
  employeeID: number | null,
  instanceID: string,
  financialInstitutionCode: string,
  branchCode: string,
  accountType: string,
  accountNumber: string,
  accountName: string
) => {
  return put(
    `/user`,
    instanceID,
    {
      financialInstitutionCode,
      branchCode,
      accountType,
      accountNumber,
      accountName,
    },
    authToken,
    employeeID
  );
};

export default putUser;
