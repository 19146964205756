const dictionary = {
  common: {
    customerNumber: 'お客様番号',
    customerNumberAnd8: 'お客様番号（8桁数字）',
    password: 'パスワード',
    version: 'バージョン',
    update: '更新',
    userInfo: 'ユーザー情報',
    withdrawalAmount: '受取金額',
    charge: '手数料',
    systemUsageFee: 'システム利用料',
    transferFee: '振込手数料',
    bank: '金融機関',
    branch: '支店',
    accountType: '口座種別',
    savingsAccount: '普通',
    checkingAccount: '当座',
    accountNumber: '口座番号',
    expectedTransferDate: '振込予定日',
    partnerCode: '提携先コード',
    confirmationNumber: '確認番号',
    requestNumber: '申請番号',
    deadline: '受取期限日時',
    yen: '円',
    language: '言語',
  },
  button: {
    login: 'ログイン',
    send: '送信',
    close: '閉じる',
    delete: '削除',
    cancel: 'キャンセル',
    request: '申請する',
    confirm: '決定',
    back: '戻る',
    change: '変更',
    next: '次へ',
  },
  login: {
    linkCustomerNumberReminder: 'お客様番号をお忘れの方はこちら',
    linkCustomerNumberReminderTitle: 'お客様番号の再通知',
    linkPasswordReset: 'パスワードをお忘れの方はこちら',
    linkPasswordResetTitle: 'パスワードの再設定',
  },
  customerNoReminder: {
    title: 'お客様番号の再通知',
    description:
      'ご登録のメールアドレスまたは\nメッセージ（SMS）に\nお客様番号のご案内を送信します',
    contact: 'メールアドレスまたは電話番号',
    linkPasswordReminder: 'パスワードを忘れた方はこちら',
    linkLogin: 'ログイン画面に戻る',
    failureTitle: '送信できませんでした',
    failureDescription:
      '入力内容が間違っているか、登録されていない可能性があります。\n入力内容をご確認の上、再度ご入力ください。\n登録情報の確認は、\n所属企業にお問い合わせください。',
  },
  customerNoReminderDone: {
    title: '送信しました',
    description:
      'お客様番号のご案内をお送りしました\n\n数分経ってもメールまたはメッセージ（SMS）が届かない場合は、\n一度所属会社に登録情報の確認を\nお願いいたします。',
    resend: 'もう一度送信する',
    linkLogin: 'ログイン画面に戻る',
  },
  passwordReset: {
    title: 'パスワードの再設定',
    description:
      'お客様番号を入力してください。\nご登録のメールアドレスまたは\nメッセージ（SMS）にパスワード再設定のご案内を送信します。',
    linkLogin: 'ログイン画面に戻る',
    linkCustomerNumberReminder: 'お客様番号を忘れた方はこちら',
    failureTitle: '入力されたお客様番号は\n登録されていません',
    failureDescription:
      '番号をお確かめの上、再度ご入力ください。\nお客様番号が発行されていない場合は\n所属会社にお問い合わせください。',
  },
  passwordResetDone: {
    title: '送信しました',
    description:
      'パスワード再設定のご案内を\nお送りしました。\n\n数分経ってもメールまたはメッセージ（SMS）が届かない場合は、\n一度所属会社に登録情報の確認を\nお願いいたします。',
    resend: 'もう一度送信する',
    linkLogin: 'ログイン画面に戻る',
  },
  appPassword: {
    setUp: 'アプリパスワードの設定',
    annotation: '申請時のパスワードを設定します\n4桁の数字を設定してください',
    enter: '入力',
    set: 'この番号で設定する',
    bioAuth: '生体認証でも設定できます',
    input: 'アプリパスワードの入力',
    inputPasscode: '設定したアプリパスワードを\n入力してください',
    forgotPasscode: 'アプリパスワードを忘れた方はこちら',
    error: 'パスワードが違います',
    errorInputPasscode: '設定したアプリパスワードを\n入力してください',
    reset: 'アプリパスワードをリセットして\nログアウトします',
    resetNextLogin: '次回ログイン時に再設定してください',
    goBack: '入力画面に戻る',
    lock: '認証に失敗したため一時的\nパスワードをロックしました',
    later: '30秒後に入力画面に戻ります',
    notMatch: 'アプリパスワードが一致しません',
    incorrect: 'アプリパスワードが違います',
    lockAnnotation:
      '認証に失敗したためロックしました。30秒後に入力画面に戻ります。',
    resetConfirm: 'アプリパスワードを再設定しますか？',
    resetSuccess:
      'アプリパスワードをリセットしました。再度ログインして設定してください。',
  },
  fingerprint: {
    verify: '端末で認証をしてください',
    userIdentification: '本人確認',
    touch: 'センサーに触れてください',
    notMatch: '一致しません',
    enterAppPassword: 'アプリパスワードを入力する',
  },
  application: {
    requestLimit: '申請上限額',
    requestAnnotation: '申請金額',
    selectAnnotation: '受取方法',
    transferToBankAccount: '口座への振込',
    receiveAtSeven: 'セブン銀行ATMで\n受け取り',
    receiveAtSeven2: 'セブン銀行\nATMで現金を\n受け取る',
    bankAccountAnnotation: '受取金額はこちらの口座に振り込まれます',
    sevenReceiveAnnotation:
      '申請後に表示される確認番号を、こちらの番号と一緒にセブン銀行ATMに入力し現金をお受け取りください',
    transferAnnotation: '午前8時以降の申請は\n翌日以降の振込となります',
    sevenAnnotation:
      '申請済みのセブン‐イレブン受取の確認番号は「履歴」の「詳細」から確認可能です',
    requestAdvancePayment: '前払い申請',
    withCharge: '手数料を含んでいます',
    recipient: '受取先',
    switchCompany: '所属会社の切り替え',
    updateButtonDialog:
      '申請上限金額の設定・更新は\n所属会社が行います。\n\n更新ボタンを押しても\n金額が更新されない場合は\n一度所属会社に\nお問い合わせください',
  },
  confirm: {
    annotation: 'この内容で\n前払い申請を行います',
    sevenAnnotation: 'セブン‐イレブンのATMで現金を受け取る',
  },
  result: {
    annotation: '前払い申請を\n受け付けました！',
    thanks: 'ご利用ありがとうございました',
    confirmation: '受取に必要な情報は\n履歴からいつでも確認できます',
    withdrawalMethod: '現金受取方法',
  },
  history: {
    history: '履歴',
    totalAmount: '申請総額',
    requestNo: '申請番号',
    amountDeducted: '給与天引き額',
    details: '詳細',
    status: '受取状況',
    waiting: '送金待ち',
    reversing: '振込エラー',
    transferComplete: '送金完了',
    awating: '受取待ち',
    expired: '期限切れ',
    withdrawalComplete: '受取完了',
    date: '申請日時',
    method: '受取方法',
    bank: '金融期間名',
    branch: '支店名',
    transferDate: '振込日',
    transfer: '銀行振込',
    seven: 'セブン銀行ATM',
    requestAmount: '申請金額',
    receivedYet: '未受取の申請があります',
    includeFee: '内手数料',
  },
  notification: {
    notice: 'お知らせ',
    detail: 'お知らせ詳細',
    company: '所属会社',
    cria: 'CRIA',
    emptyCompany: '申請上限額が更新されると\nここに表示されます',
    emptyCria: 'CRIAからのお知らせがあると\nここに表示されます',
  },
  settings: {
    settings: '設定',
    account: 'アカウント',
    logout: 'ログアウト',
    infomation: '情報',
    withdrawalMethod: 'セブン‐イレブン現金受取方法',
    termOfUse: '利用規約',
    needAgreeWithTheTerms: '利用規約に同意してください',
    agreeWithTheTermsFailure: '同意に失敗しました',
    agreeWithTheTerms: '同意する',
    disagreeWithTheTerms: '同意しない',
    termOfUseFirstSentence:
      '本「【給与即時払い『CRIA』】利用規約（ユーザー様向け）」（以下「本規約」といいます）は、株式会社メタップスペイメント（以下「当社」といいます）が運営・提供する「給与即時払い『CRIA』」（以下「本サービス」といいます）の利用に関する条件、遵守事項等を定めるものです。ユーザー（第 3 条に定義します）は、予め本規約について同意のうえで本サービスを利用するものとし、本規約の内容は、本サービスに関するユーザーと当社間の合意事項の一部を形成するものとします。',
    termOfUses: [
      {
        title: '第1条（適用）',
        content:
          '本規約は、本サービスのユーザーによる利用のすべてについて適用するものとします。なお、当社は、本規約の趣旨に反しない範囲において、本サービスの利用に関する細則等を別途定めることができるものとし、当該細則等は、本契約（第 4 条第 3 項に定義します）の成立後、本契約の一部を構成するものとします。',
        list: [],
        bracketList: [],
      },
      {
        title: '第2条（本サービス）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' 本サービスは、当社が加盟企業（第 3 条に定義します）から別途受託した事務の一環として、ユーザーが加盟企業から支払を受ける賃金等（賞与等の一時金、及び退職手当は含まれません。以下「賃金等」といいます）について、加盟企業の定める範囲において、ユーザーの希望に応じ、通常の支払日以前に払い出すためのシステムを提供するものです。',
            innerList: [],
          },
          {
            index: 2,
            content:
              ' 本サービスによりユーザーが支払いを受けることができる金額は、加盟企業においてユーザーが就労した結果として既に債権として発生した賃金等のうち、加盟企業が別途定める範囲の金額（以下「申請上限額」といいます）とします。未就労などの理由により加盟企業のユーザーに対する賃金債務として確定していない賃金等は、本サービスの対象とはなりません。',
            innerList: [],
          },
        ],
        bracketList: [],
      },
      {
        title: '第3条（定義）',
        content:
          '本規約において使用する以下各号の用語は、それぞれ当該各号に定める意味を有するものとします。',
        list: [],
        bracketList: [
          {
            index: 1,
            content:
              ' 「加盟企業」とは、当社との間で本サービス利用に係る契約（以下「加盟企業契約」といいます。）を締結することにより、本サービスを導入している法人をいいます。',
          },
          {
            index: 2,
            content:
              ' 「ユーザー」とは、加盟企業において就労する個人であって、加盟企業による本サービスの利用に係る承認に基づき、第 4 条に従って利用登録を完了した方をいいます。',
          },
          {
            index: 3,
            content:
              ' 「指定アプリ」とは、ユーザーによる本サービスの利用のためのコンピューターアプリケーションであって、当社がユーザーに提供するものをいいます。',
          },
        ],
      },
      {
        title: '第4条（ユーザー登録）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' 本サービスを利用する場合、ユーザーは、最初にユーザー登録を完了する必要があります。ユーザー登録は、加盟企業の指定に基づく当社からのユーザーへの通知に従い、指定アプリをダウンロードし、指定アプリより行うものとします。',
            innerList: [
              {
                index: 1,
                content:
                  ' 加盟企業の指定に基づく当社からのユーザーへの通知に従い、指定アプリをダウンロードし、指定アプリより登録する方法',
              },
              {
                index: 2,
                content:
                  ' 当社が加盟企業ごとに用意したQRコード又はURLの案内に従い登録する方法',
              },
            ],
          },
          {
            index: 2,
            content:
              ' ユーザー登録をする者は、本規約に同意のうえで、ユーザー登録を申し込むものとします。',
            innerList: [],
          },
          {
            index: 3,
            content:
              ' 前二項による登録が完了したときに、ユーザー登録をした者と当社の間に、本規約に基づく本サービスの利用に関する契約（以下「本契約」といいます）が成立するものとします。',
            innerList: [],
          },
          {
            index: 4,
            content:
              ' 本サービスに基づく払出を受けるための金融機関口座は、ユーザーが加盟企業に給与受取口座として登録した口座として、加盟企業が当社に対し通知した口座とします。ユーザーは、当該口座を変更する場合には、加盟企業との間で必要な手続きを行うものとします。',
            innerList: [],
          },
        ],
        bracketList: [],
      },
      {
        title: '第5条（利用方法）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' 本サービスの利用は、次のいずれかの方法によるものとします。',
            innerList: [
              {
                index: 1,
                content:
                  ' 指定アプリを利用する方法\nユーザーは、指定アプリを指定サイト又は当社が別途認めるWeb サイトからダウンロードして、自己のスマートフォン等にインストールするものとします。',
              },
              {
                index: 2,
                content:
                  ' ウェブブラウザを利用する方法\n当社が指定する Web サイトより、別に当社が案内する ID 及びパスワードを用いてログインするものとします。',
              },
            ],
          },
          {
            index: 2,
            content:
              ' 本サービスの提供を受けるために必要なスマートフォン、パソコン等その他の機器、通信回線その他の通信環境等の準備及び維持は、ユーザーの費用と責任において行うものとし、ユーザーは自己の本サービスの利用環境に応じて、ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。また、ユーザーは、本サービスの利用開始に際し又は本サービスの利用中に、アプリのダウンロードその他の方法によりソフトウェア等をユーザーのコンピューター等にインストールする場合及びウェブブラウザを利用する場合には、ユーザーが保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社はユーザーに発生したかかる損害について一切責任を負いません。',
            innerList: [],
          },
          {
            index: 3,
            content:
              ' ユーザーは、本サービスを利用する場合には、指定アプリを起動し、又はウェブブラウザ上において、ID 及びパスワード等の認証情報を入力することによりログインして、所定のフォームに本サービスの利用に関する申請金額（以下「利用申請金額」といいます）その他必要な事項を指定アプリ上又はウェブブラウザ上で入力し、払出を申請するものとします。なお、利用申請金額は、ユーザーが当社に対し賃金の前払としての払出を申請する金額と次条に定める本サービスの利用料額の合計額とします。',
            innerList: [],
          },
          {
            index: 4,
            content:
              ' 当社は、前項により入力された内容を確認し、適正な申込みであることを確認のうえ、加盟企業から受託した事務代行として、賃金の前払としての払出しを行います。なお、当社は、ユーザーによる指定アプリ又はウェブブラウザへのログインに際して入力された ID 及びパスワードが、当社に登録されたユーザーの ID 及びパスワードと一致することを確認します。当社が一致を確認した場合、当社は前項の申込みはユーザーからのものであるとみなして取扱います。',
            innerList: [],
          },
          {
            index: 5,
            content:
              ' 利用申請金額は、その時点における申請上限額の範囲内である必要があり、その最低単位は 1,000 円とします。また、当社は、一定期間あたりの利用申請回数、利用金額等について、一定の制限を設ける場合があります。',
            innerList: [],
          },
          {
            index: 6,
            content:
              ' 賃金の前払いとしての払出の実施と同時に、利用金額は加盟企業に通知され、加盟企業は、賃金の前払いとして当社が払い出した金額（利用申請金額から次条の利用料額を除いた賃金額に相当する部分）について賃金等の支払がなされたものとして取り扱います。ユーザーは、所定の賃金等支給日において、当該金額が控除されることを加盟企業に対し同意するものとします。前各項にかかわらず、次の各号に該当する場合、当社は、ユーザーに対する本サービスの利用の実施を拒絶することができるものとします。',
            innerList: [
              {
                index: 1,
                content:
                  '払出先口座として加盟企業が当社に通知した金融機関口座が差押えもしくは仮差押えを受け、又は解約されたとき',
              },
              {
                index: 2,
                content:
                  '加盟企業が支払停止になったとき、その他加盟企業の経済状態が著しく悪化したと当社が判断したとき',
              },
              {
                index: 3,
                content: 'ユーザーの賃金債権が差押えられたとき',
              },
              {
                index: 4,
                content: ' 停電、事故などにより取り扱いができないとき',
              },
              {
                index: 5,
                content:
                  ' 加盟企業が当社に対してユーザーの使用の中止を依頼したとき',
              },
              {
                index: 6,
                content:
                  ' 加盟企業契約に基づき当社がユーザーに対する本サービスの利用を拒絶できると',
              },
              {
                index: 7,
                content:
                  ' その他やむを得ない事情があり、取扱が不適当又は不可能であると当社が判断したとき',
              },
            ],
          },
          {
            index: 7,
            content:
              ' 第 4 項に基づく確認に時間を要した場合又は前項に基づく利用拒絶が行われた場合において、ユーザーによる本サービスの利用が遅れたこと又は本サービスの利用ができなかったことによりユーザーに損害が生じたとしても、当社は一切の責任を負わないものとします。',
            innerList: [],
          },
          {
            index: 8,
            content:
              ' 加盟企業の責に帰すことのできない事由により、払出金額において過払いが生じた場合、ユーザーは、当該過払い相当分について当社に対し返金するものとします。',
            innerList: [],
          },
        ],
        bracketList: [],
      },
      {
        title: '第6条（利用料）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' ユーザーは、本サービスの利用について、別途当社が定め、指定アプリ等を通じて事前に提示した利用料を支払うものとします。',
            innerList: [],
          },
          {
            index: 2,
            content:
              ' 当社は、前条第 3 項に基づくユーザーに対する払出しに関して、前項に定める利用料及びこれに係る消費税相当額の支払いを受けることができるものとします。',
            innerList: [],
          },
          {
            index: 3,
            content:
              ' 前項に基づく利用料及び当社から賃金の前払として払い出される金額は、払出申込みの都度、指定アプリ上又はウェブブラウザ上で表示されます。ユーザーは、当該金額を確認し、これに明示的な同意を行ったうえで、払出の申込みを進めるものとします。本項の「明示的な同意」は、指定アプリ上又はウェブブラウザ上アプリ上で表示した内容に対し、「同意」「承認」等のボタン（リンク）をユーザーがクリックすることによって行うことができるものとします。',
            innerList: [],
          },
        ],
        bracketList: [],
      },
      {
        title: '第7条（加盟企業からの支払いの受領等）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' 当社は、第 5 条第 3 項に基づき賃金の前払としてユーザーに払い出しを行った金額及び前条に基づき本サービスの利用に関してユーザーが負担すべき利用料の合計額につき、加盟企業から支払いを受けることができるものとします。ユーザーは、当該合計額につき、加盟企業がユーザーに対して賃金を支払う際に控除することを承諾するものとします。',
            innerList: [],
          },
          {
            index: 2,
            content:
              ' 当社は、第 5 条第 3 項に基づき当社が実施する立替えによる賃金の前払としての払出により、当該払出の対象であるユーザーが加盟企業に対し有する当該払出金額（ユーザーが申請した金額から前条第 1 項の利用料額を除いた賃金額に相当する部分）に相当する賃金債権について代位します。この場合において、当社は加盟企業に対し、前項に基づき賃金の前払として払出を実行した金額について加盟企業に償還及び支払を請求することができる範囲内で、当該賃金債権の効力及び担保として当該ユーザーが有していた一切の権利を単独で行使できるものとし、ユーザーは当社によるかかる賃金債権の行使について承諾、同意します。',
            innerList: [],
          },
        ],
        bracketList: [],
      },
      {
        title: '第8条（ユーザーの義務、禁止行為等）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' ユーザーは、本サービスの利用登録にあたり当社及び加盟企業に提出した情報が全て正確であり、重要な点において誤りがないことを表明し、保証します。',
            innerList: [],
          },
          {
            index: 2,
            content:
              ' ユーザーは、本サービスを、善良なる管理者の注意をもって利用するものとします。',
            innerList: [],
          },
          {
            index: 3,
            content:
              ' ユーザーは、本サービス利用のために必要となる ID 及びパスワード等の認証情報（以下、「アカウント情報」といいます）を秘密として管理し、他に漏れることの無いように取り扱うものとします。アカウント情報の管理の不備、使用上の過誤、第三者の使用等による損害の責任はユーザーが負うものとし、当社は一切の責任を負いません',
            innerList: [],
          },
          {
            index: 4,
            content:
              ' ユーザーは、アカウント情報が第三者に不正に利用されていることが判明した場合、又はその恐れがあると認める場合には、直ちにその旨を当社に通知し、当社からの指示に従うものとします。',
            innerList: [],
          },
          {
            index: 5,
            content:
              ' ユーザーは、本サービスの利用に関し、次に掲げる行為を行わないものとします。',
            innerList: [
              {
                index: 1,
                content:
                  ' 自己の名義により、第三者に本サービスを利用させること',
              },
              {
                index: 2,
                content:
                  ' 指定サイトその他本サービスに関連する当社の Web サイトや、本サービス利用のために当社が提供するアプリケーション等を本サービスの利用以外の目的で使用し、又はこれらに対し不正なアクセス等を試みること',
              },
              {
                index: 3,
                content:
                  ' 法令又は公序良俗に違反する行為、犯罪行為に関連する行為、当社のサービスの運営を妨害する行為その他本サービスの本旨に反する態様において本サービスを利用すること',
              },
              {
                index: 4,
                content: ' その他当社が不適切と判断する行為',
              },
            ],
          },
          {
            index: 6,
            content:
              ' ユーザーは、ユーザーによる本サービスの利用、本サービスに係る債権債務関係もしくは労使関係等に関し、ユーザーと加盟企業又は第三者との間で紛議が生じた場合、これを自己の責任と費用において解決するものとし、当社を当該紛議から免責防御するものとします。',
            innerList: [],
          },
        ],
        bracketList: [],
      },
      {
        title: '第9条（本サービスの停止）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' 当社は、本サービスを運用するためのシステムや設備（以下「設備等」といいます）の点検・保守等の理由により、事前にユーザーに対し通知のうえ、本サービスの提供を一時的に停止することができるものとします。また、次に掲げる場合においては、事前の通知を要さず、本サービスの提供を停止することができるものとします。',
            innerList: [
              {
                index: 1,
                content:
                  ' 設備等に予期せぬ障害等が生じ、損害の発生又は拡大を防止するため必要となる場合',
              },
              {
                index: 2,
                content:
                  ' 設備等が、第三者からの攻撃を受けていると判断され、情報セキュリティの維持等のため必要となる場合',
              },
              {
                index: 3,
                content:
                  ' 火災、停電、天災地変その他不可抗力（当事者の合理的な支配を超える事象をいいます。以下同じ）により、本サービスの運営ができなくなった場合',
              },
              {
                index: 4,
                content:
                  ' その他、本サービスの正常な運用、情報セキュリティの維持等のため緊急に保守等の実施が必要となる場合',
              },
            ],
          },
          {
            index: 2,
            content:
              ' 本契約の成立後、法令又はその解釈指針等の変更その他の事情により本サービスの運営が困難になったと当社が判断した場合には、当社は本サービス内容を一部変更し、又は本サービスの運営を停止することができるものとします。',
            innerList: [],
          },
          {
            index: 3,
            content:
              ' 前二項に基づく本サービスの一部変更又は停止について、当社は、名目の如何を問わず損害賠償責任を負わないものとします。',
            innerList: [],
          },
        ],
        bracketList: [],
      },
      {
        title: '第10条（当社の責任）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' 当社は、本規約に定めるほか、善良な管理者の注意をもって本サービスを運営するものとします。',
            innerList: [],
          },
          {
            index: 2,
            content:
              ' 当社は、本サービスにより、ユーザーにおいて特定の目的が達成されることを保証するものではありません。',
            innerList: [],
          },
          {
            index: 3,
            content:
              ' 本サービスに関し当社が提供するアプリケーション等は、原則として現状有姿により提供されるものであり、当社は、当該アプリケーションの機能等、又はバグ等が存在しないことについて何ら保証するものではありません。',
            innerList: [],
          },
        ],
        bracketList: [],
      },
      {
        title: '第11条（情報管理）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' 当社は、本サービスの運営に伴い取得したユーザーの個人情報（払出状況に関する情報を含みます）を、指定サイトにおいて明示した利用目的の範囲内でのみ利用します。なお、別途ユーザーの個別同意を得た場合においては、当該同意を得た利用目的においても利用できるものとします。',
            innerList: [],
          },
          {
            index: 2,
            content:
              ' 前項のほか、個人情報の取扱いについては、法令及び当社所定の個人情報保護方針に係る定めに従うものとします。',
            innerList: [],
          },
          {
            index: 3,
            content:
              ' 当社は、ユーザーの本サービスの利用に関する情報を運営上一定期間保存していた場合であっても、かかる情報を保存する義務を負うものではなく、当社はいつでもこれらの情報を削除できるものとします。なお、当社はかかる情報の削除に基づきユーザーに生じた損害について一切の責任を負いません。',
            innerList: [],
          },
        ],
        bracketList: [],
      },
      {
        title: '第12条（連絡・通知）',
        content:
          'ユーザー及び当社は、本契約に関連する相互の連絡、通知を、別途当社が指定する方法によって行うものとします。当該方法に従わない連絡、通知は、受領者が承諾した場合を除き、原則として無効とします。なお、当社は、ユーザーに対する連絡方法として、指定アプリ又はウェブブラウザ上に掲示する方法を利用することができるものとします。',
        list: [],
        bracketList: [],
      },
      {
        title: '第13条（知的財産権等）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' 本サービスの運営に関し当社が提供するシステム、Web サイト、アプリケーション（指定アプリを含む）等に関する特許権、実用新案権、意匠権、著作権、商標等一切の知的財産権（これらについて出願登録する権利を含みます）は、当社又は当社の提携先に帰属します。',
            innerList: [],
          },
          {
            index: 2,
            content:
              ' ユーザーは、本サービス利用に関し当社が提供するアプリケーション等（指定アプリを含む）について、逆コンパイル、リバースエンジニアリング、逆アセンブル等を行わないものとします。また、当該アプリケーション等を本サービス利用以外の目的で使用することはできません。',
            innerList: [],
          },
        ],
        bracketList: [],
      },
      {
        title: '第14条（秘密保持）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' ユーザー及び当社は、本契約の履行を通じて知り得た相手方の技術上、営業上その他一切の機密情報（以下「機密情報」といいます）を機密として管理し、本契約の有効期間中のみならずその終了後においても、相手方の書面による事前の承諾なく、本契約の履行に関係する自己の役員、従業員もしくは弁護士、会計士等の専門家を除くいかなる第三者に対しても開示、漏洩せず、また、本契約の履行以外の目的に利用しないものとします。',
            innerList: [],
          },
          {
            index: 2,
            content:
              ' 第 1 項の定めに拘わらず、次の各号のいずれかに該当する情報は機密保持義務の対象とはならないものとします。',
            innerList: [
              {
                index: 1,
                content: ' 当事者が知り得る以前に既に公知であったもの',
              },
              {
                index: 2,
                content:
                  ' 当事者が知り得た後に、その責によらず公知となったもの',
              },
              {
                index: 3,
                content:
                  ' 当事者が知り得る以前に既に自己が適法に保有していたもの',
              },
              {
                index: 4,
                content:
                  ' 正当な権限を有する第三者から守秘義務を負わずに適法に入手したもの',
              },
              {
                index: 5,
                content:
                  ' 機密情報を参照することなく当事者が独自に開発したもの',
              },
            ],
          },
        ],
        bracketList: [],
      },
      {
        title: '第15条（本規約の改定等）',
        content:
          '当社は、変更実施日の 1 ヶ月前までに、書面（電子メールを含む）、指定アプリ、当社所定の Web サイトにおける掲示、その他ユーザーが合理的に変更の内容を知り得る方法によりユーザーに通知することで、本規約を改定し、又は本サービスを停止することができるものとします。なお、本規約の改定に関しては、本規約の改定後にユーザーが本サービスを利用した場合には、当該ユーザーは、本規約の変更に同意したものとみなされます。',
        list: [],
        bracketList: [],
      },
      {
        title: '第16条（法令の遵守）',
        content:
          'ユーザー及び当社は、本契約の履行に関し、関連する法律、政令、省令、条例、規則及び命令等を遵守するものとします。',
        list: [],
        bracketList: [],
      },
      {
        title: '第17条（権利の譲渡等）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' ユーザーは、本契約上の自己の地位を、事前に当社の書面による承諾を得ることなく、第三者に譲渡できないものとします。',
            innerList: [],
          },
          {
            index: 2,
            content:
              ' ユーザーは、事前に当社の書面による承諾を得ることなく、本契約に基づく当社に対する債権を第三者に譲渡し、又は質入等担保の用に供することはできないものとします。',
            innerList: [],
          },
          {
            index: 3,
            content:
              ' 当社は本サービスにかかる事業を第三者に譲渡（事業譲渡、会社分割その他態様の如何を問わないものとします。）した場合には、当該譲渡に伴い本契約上の地位、本規約に基づく権利及び義務並びにユーザーの個人情報その他の顧客情報を当該譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。',
            innerList: [],
          },
        ],
        bracketList: [],
      },
      {
        title: '第18条（反社会的勢力の排除）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' ユーザーは、自己が現在において次の各号に掲げる反社会的勢力のいずれにも該当しないことを保証し、また、将来においてもこれらに該当しないことを確約します。',
            innerList: [
              {
                index: 1,
                content: ' 暴力団',
              },
              {
                index: 2,
                content: ' 暴力団員',
              },
              {
                index: 3,
                content: ' 暴力団準構成員',
              },
              {
                index: 4,
                content:
                  ' 暴力団、暴力団員でなくなった時から 5 年を経過しない者',
              },
              {
                index: 5,
                content: ' 暴力団関係企業',
              },
              {
                index: 6,
                content:
                  ' 総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等',
              },
              {
                index: 7,
                content: ' その他前各号に準ずる団体又は個人',
              },
            ],
          },
          {
            index: 2,
            content:
              ' ユーザーは、自己が現在において前項の反社会的勢力又は反社会的勢力と密接な交友関係にある者（以下「反社会的勢力等」といいます）と次の各号のいずかに該当する関係を有しないことを保証し、将来においてもかかる関係を有しないことを確約します。',
            innerList: [
              {
                index: 1,
                content: ' 反社会的勢力等によってその経営を支配される関係',
              },
              {
                index: 2,
                content: ' 反社会的勢力等がその経営に実質的に関与している関係',
              },
              {
                index: 3,
                content:
                  ' 反社会的勢力等に対して資金等を提供し又は便宜を供与するなどの関係',
              },
              {
                index: 4,
                content: ' その他反社会的勢力等との社会的に非難されるべき関係',
              },
            ],
          },
          {
            index: 3,
            content:
              ' ユーザーは、自己が次の各号に該当する行為を一切行わないことを確約します。',
            innerList: [
              {
                index: 1,
                content: ' 暴力的な要求行為',
              },
              {
                index: 2,
                content: ' 法的な責任を超えた不当な要求行為',
              },
              {
                index: 3,
                content: ' 取引に関して脅迫的な言動をし、又は暴力を用いる行為',
              },
              {
                index: 4,
                content:
                  ' 風説を流布し、偽計を用い又は威力を用いて当社の信用を毀損し又は当社の業務を妨害する行為',
              },
              {
                index: 5,
                content: ' その他前各号に準ずる行為',
              },
            ],
          },
          {
            index: 4,
            content:
              ' 当社は、ユーザーにおいて前各項に反する事情がある場合又はそのおそれが高い場合には、何ら催告なく、直ちに本契約を解除できるものとします。なお、当社は、当該解除によってユーザーに損害が生じた場合であっても、その賠償の責を負わないものとします。',
            innerList: [],
          },
        ],
        bracketList: [],
      },
      {
        title: '第19条（有効期間／存続条項）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' 本契約は、第 4 条第 3 項に基づく成立後、次の各号に定める事由が生じるときまで、有効に存続するものとします。',
            innerList: [
              {
                index: 1,
                content: ' ユーザーが、ユーザー登録時の加盟企業を退職した場合',
              },
              {
                index: 2,
                content:
                  ' ユーザーが、指定アプリ又はウェブブラウザ上によりユーザー登録の抹消を申し出た場合',
              },
              {
                index: 3,
                content:
                  ' 第 18 条第 4 項又は第 20 条の定めに基づき、当社が本契約の解除権を行使した場合',
              },
              {
                index: 4,
                content: ' 理由の如何を問わず、加盟企業契約が終了した場合',
              },
            ],
          },
          {
            index: 2,
            content:
              ' 理由の如何を問わず、本契約が終了した場合、それ以後、ユーザーは本サービスを利用できません。また、第 5 条による本サービスの利用の申込みが本契約の有効期間中に行われた場合であっても、本契約の終了時点で払出が実行されていない場合には、当該申込みは無効として取り扱うものとします。',
            innerList: [],
          },
          {
            index: 3,
            content:
              ' 期間満了、中途解約その他終了事由の如何に拘わらず、第 13 条、第 14 条、第 21 条乃至第 26 条の各規定は、本契約の終了後も有効に存続するものとします。',
            innerList: [],
          },
        ],
        bracketList: [],
      },
      {
        title: '第20条（契約の解除）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' 当社は、ユーザーに次の各号の事由のいずれかが生じた場合、何等催告することなく直ちに本契約を解除することができるものとします。',
            innerList: [
              {
                index: 1,
                content: ' 本規約のいずれかの条項に違反したとき',
              },
              {
                index: 2,
                content:
                  ' 登録情報その他ユーザーが当社に提出した情報に虚偽の事実があることが判明したとき',
              },
              {
                index: 3,
                content:
                  ' 当社、他のユーザーその他第三者に損害を生じさせるおそれのある目的もしくは方法で本サービスを利用した、又は利用しようとしたとき',
              },
              {
                index: 4,
                content:
                  ' 手段の如何を問わず、本サービスの運営を妨害し、又は妨害しようとしたとき',
              },
              {
                index: 5,
                content: ' 破産手続きを申請し、又は申請されたとき',
              },
              {
                index: 6,
                content:
                  ' 自ら振り出した手形若しくは小切手につき、不渡り処分を受ける等支払停止状態に陥ったとき',
              },
              {
                index: 7,
                content:
                  ' 差押、仮差押、仮処分、強制執行又は競売の申立てがあったとき',
              },
              {
                index: 8,
                content: ' 租税公課の滞納処分を受けたとき',
              },
              {
                index: 9,
                content:
                  ' 死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けたとき',
              },
              {
                index: 10,
                content: ' 当社からの連絡に対して応答がないとき',
              },
              {
                index: 11,
                content: ' 当社がユーザー登録の継続を適当でないと判断したとき',
              },
              {
                index: 12,
                content: ' その他、前各号に準じた事態が生じたとき',
              },
            ],
          },
          {
            index: 2,
            content:
              ' 当社は、加盟企業について、信用不安等、当社が加盟企業契約を解除又は解約すべき事由が生じた場合その他加盟企業に対し本サービスを提供することができなくなった場合には、ユーザーに対し何等催告することなく直ちに本契約を解除することができるものとします。',
            innerList: [],
          },
        ],
        bracketList: [],
      },
      {
        title: '第21条（損害賠償）',
        content:
          'ユーザーは、不法行為、債務不履行その他のユーザーの責に帰すべき事由により、本契約に関して当社に損害を生じさせた場合には、その賠償の責を負うものとします。',
        list: [],
        bracketList: [],
      },
      {
        title: '第22条（免責事項）',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' 次の各号の事由によりユーザーに生じた損害について、当社は一切の責任を負いません。',
            innerList: [
              {
                index: 1,
                content:
                  '火災、停電、天災事変その他不可抗力に起因する履行不能、履行遅滞その他債務の不履行。',
              },
              {
                index: 2,
                content:
                  '当社の提供する本サービスにつき、合理的なセキュリティ対策を講じていたにもかかわらず、ID、パスワード等の認証情報が漏洩したこと（第三者によるサイバー攻撃、不正取得による漏洩を含むが、これに限らない。）',
              },
              {
                index: 3,
                content:
                  '当社の提供する本サービスのシステムについて合理的な安全対策を講じていたにもかかわらず発生した通信障害等',
              },
            ],
          },
          {
            index: 2,
            content:
              ' 本サービスに関して、ユーザーと加盟企業との間で賃金支払その他の債権債務関係及び労使関係等に関するクレーム・紛争等が生じた場合はユーザーと加盟企業との間で解決するものとし、当社は一切の責任を負いません。',
            innerList: [],
          },
          {
            index: 3,
            content:
              ' ユーザーの本サービスの利用にあたって、当社がユーザーに損害を与えた場合には、当社の賠償責任は、損害の事由が生じた時点から遡って過去 1 年間にユーザーが現実に負担した利用料の総額を上限とします。但し、当社に故意又は重過失がある場合はこの限りでありません。',
            innerList: [],
          },
        ],
        bracketList: [],
      },
      {
        title: '第23条（分離独立性）',
        content:
          '本規約のいずれかの条項が無効又は執行不能とされた場合であっても、当該無効又は執行不能は他の条項の有効性及び拘束力に影響を与えないものとします。',
        list: [],
        bracketList: [],
      },
      {
        title: '第24条（準拠法）',
        content:
          '本契約は、日本法に基づき成立し、日本法に従って解釈、運用されるものとします。',
        list: [],
        bracketList: [],
      },
      {
        title: '第25条（合意管轄）',
        content:
          'ユーザー及び当社は、本契約に基づく当事者間の紛争を裁判により解決する場合には、その訴額に応じ、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とするものとします。',
        list: [],
        bracketList: [],
      },
      {
        title: '第26条（協議解決）',
        content:
          'ユーザー及び当社は、本規約等に定めのない事項又は本規約等の条項の解釈につき疑義が生じたときは、信義に基づき誠実に協議の上これを解決するものとします。',
        list: [],
        bracketList: [],
      },
    ],
    endOfDocument: '以上',
    corporateName: '株式会社メタップスペイメント',
    forUsers: '（ユーザー様向け）',
    privacyPolicy: '個人情報保護方針',
    privacyPolicies: [
      {
        title: '1. 個人情報の取得と利用について',
        content:
          '弊社は、個人情報を、あらかじめ明示させて頂きました目的の範囲内に限り取得し、利用致します。また、目的外利用を行わないための措置を講じます。明示した目的の範囲を超えて個人情報を利用する必要が生じた場合には、事前にご本人にその目的をご連絡致します。個人情報を新たな目的に利用させていただくことにご同意頂けない場合には、ご本人の判断により、かかる利用を拒否することができます。(弊社の個人情報の利用目的は、公表事項1をご覧ください)',
        list: [],
        bracketList: [],
      },
      {
        title: '2. 個人情報の第三者への開示・提供について',
        content:
          '以下に該当する場合を除き、個人情報を第三者へ開示・提供することは致しません。',
        list: [
          {
            index: 1,
            content: ' ご本人の同意がある場合。',
          },
          {
            index: 2,
            content: ' 個人を識別することができない状態で開示する場合。',
          },
          {
            index: 3,
            content:
              ' あらかじめ弊社との間で機密保持契約を締結している企業（例えば、業務委託先）等に必要な限度において開示・提供する場合。',
          },
          {
            index: 4,
            content:
              ' 共同利用する場合(弊社が個人情報を共同利用する場合は、公表事項 1(4)をご覧ください)',
          },
          {
            index: 5,
            content:
              ' 有料サービスのご利用や商品のご注文等で決済が必要な場合。（金融機関等との間で個人情報を交換することがあります。これは、銀行口座の正当性やクレジットカードの有効性を確認するためです。）',
          },
          {
            index: 6,
            content:
              ' 法令等により要求された場合。(個人情報に関する開示等の請求への対応は、公表事項 3 をご覧ください)',
          },
        ],
        bracketList: [],
      },
      {
        title: '3. 個人情報の管理について',
        content:
          '弊社は、個人情報の漏洩、毀損、滅失を防止するための予防および是正を行い、個人情報を安全に管理致します。',
        list: [],
        bracketList: [],
      },
      {
        title: '4. 関係法令及びその他の規範の遵守について',
        content:
          '弊社は、ご提供頂きました個人情報に関して適用される法令・国が定める指針・その他の規範等を遵守致します。',
        list: [],
        bracketList: [],
      },
      {
        title: '5. 個人情報保護マネジメントシステムの見直しについて',
        content:
          '弊社が保有する個人情報を保護するための社内体制を整備し、これを実効あるものとして運用致します。また、弊社の個人情報保護マネジメントシステム（個人情報保護規程ならびに関連規程を含みます）を随時継続的な改善に努めます。',
        list: [],
        bracketList: [],
      },
      {
        title: '6. 個人情報に関する苦情および相談への対応について',
        content:
          '弊社が保有する個人情報に関して苦情および相談は以下の相談窓口にて承ります。\nお客様相談窓口 e-mail: c-check@metaps-payment.com\n採用希望者相談窓口 e-mail: saiyo@metaps-payment.com',
        list: [],
        bracketList: [],
      },
    ],
    updateHistories: [
      '初版制定：2018年6月5日',
      '2018年6月22日改定',
      '2018年7月19日改定',
      '2018年9月7日改定',
      '2019年8月26日改定',
      '2024年2月10日改定',
    ],
    faq: 'よくある質問',
    logoutConfirm: '本当にログアウトしますか？',
    logoutConfirmDescription:
      '再ログイン時は、お客様番号とパスワードの再入力が必要になります。',
    languageSettings: '言語',
    japanese: '日本語',
    english: '英語',
    vietnamese: 'ベトナム語',
    howTo: 'CRIAの使い方',
    bioAuth: '生体認証',
    bioAuthNotification:
      'ONにすると申請時のアプリパスワード入力時、生体認証が可能になります。',
    appVersion: 'appバージョン',
    goBack: '設定画面に戻る',
    needUpdate: '設定の反映にはアプリの再起動が必要です。',
    showJa: '一部お知らせ等、翻訳対象外の情報は日本語で表示されます。',
  },
  userInfo: {
    information: '登録情報',
    company: '所属会社名',
    partners: '加盟店名',
    employeeCode: '従業員コード',
    account: '登録口座',
    mailAddress: 'メールアドレス',
    branchCode: '支店コード',
    depositType: '預金種別',
    accountHolder: '口座名義',
    phoneNumber: '電話番号',
    disabled: '利用申請',
    status: '停止中',
  },
  howToCash: {
    annotation: 'セブン‐イレブンでの現金受取方法',
    anyone: `どなたでも${'\n'}ご利用可能です`,
    selectTop:
      'セブン銀行ATMのトップ画面の右上にある「カードを使わない番号入力での取引」を選択します。',
    enterPartnerCode: '提携先コードを入力します。',
    enterCustomerNumber: 'お客様番号を入力します。',
    enterConfirmationNumber: '確認番号を入力します。',
    checkAndConfirm: '取引内容と金額をご確認ください。',
    takeMoney: '紙幣をお受け取りください。',
    takeReceipt: '明細票をお受け取りください。',
  },
  alert: {
    error: 'エラー',
    inputError: '入力エラー',
    notice: 'お知らせ',
    confirm: '確認',
    customerNumberEmpty: 'お客様番号を入力してください',
    passwordEmpty: 'パスワードを入力してください',
    customerNumberNotInt: 'お客様番号は数値を入力してください',
    customerNumberNoMatchLength: 'お客様番号は8桁で入力してください',
    passwordTooLong: 'パスワードは256文字以下にしてください',
    loginNotAllowed: 'お客様番号かパスワードに誤りがあります',
    contactEmpty: 'メールアドレスまたは電話番号を入力してください',
    mailAddressInvalid: 'メールアドレスの形式が正しくありません',
    forceUpdate: 'アプリをバージョンアップしてください',
    moveAppStore: 'AppStoreへ移動しますか？',
    movePlayStore: 'Google Playへ移動しますか？',
    maintenance: 'メンテナンス中です。時間を置いてご利用ください。',
    serverError: 'サーバーエラーが発生しました',
  },
  initial: {
    first: 'はじめに',
    didGetMail: '招待メールは\n届いていますか？',
    gotMail:
      '所属会社がCRIAに\nお客様情報を登録すると\nあなたに招待メールが届きます',
    received: '届いている',
    notReceived: '届いていない',
    contactCompany: '所属会社へ\nお問い合わせください',
    contactCompanyDescription:
      '招待メールは所属会社から送信されます\n所属会社にお問い合わせの上\n再度CRIAの起動をお願いいたします',
    didSetPassword: 'パスワード設定は\nお済みですか？',
    setPassword: '招待メールに記載されたURLから\nパスワード設定ができます',
    set: '設定済み',
    notSet: 'していない',
    settingPassword: 'パスワード設定を\n行ってください',
    settingPasswordDescription:
      'ログインするには予め設定した\nパスワードが必要になります\n招待メールに記載されているURLから\nパスワードを設定してください\n\n招待メールが見つからない場合は\n所属会社に再送してもらう必要が\nあります',
  },
  initial2: {
    title: 'ご注意',
    description:
      '表示される申請可能金額は\n所属会社によって更新されます\n\n更新金額に関するお問い合わせや\n登録情報の確認・変更は\n所属会社にご連絡ください',
    start: 'はじめる',
  },
  terms: {},
};
export default dictionary;
