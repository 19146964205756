import {History} from 'history';
import {connect} from 'react-redux';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import {Dispatch} from 'redux';
import {GlobalState} from '../reducers';
import {AuthTokenState} from '../reducers/authToken';
import {LanguageState} from '../reducers/languages';
import LoginScreen from './login/LoginScreen';
import Home from './home/Home';

import CustomerNoReminder from './login/CustomerNoReminder';
import PasswordReminder from './login/PasswordReminder';
import NotificationList from './notification/List';
import Settings from './settings/Settings';
import BankSetting from './settings/BankSetting';
import Terms from './settings/Terms';
import PrivacyPolicy from './settings/PrivacyPolicy';
import Alert from './parts/Alert';
import UsageSevenAtm from './sideBar/UsageSevenAtm';

import BalanceHistory from './balanceHistory/Index';

import '../css/common.css';
import '../css/common.scss';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

import Passcode from './passcode/Passcode';
import ConfirmationCode from './ConfirmationCode';
import Tutorial from './tutorial/Tutorial';
import {i18n} from '../locale/i18n';
import {ErrorBoundary} from 'react-error-boundary';

import {getOemName} from '../util/oem';

interface AppProps {
  history: History;
}

interface DispatchProps {}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `web-app@${process.env.npm_package_version}`,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

Sentry.configureScope(scope => {
  scope.setTag('service', 'web-app');
  if (process.env.REACT_APP_OEM_CODE) {
    const oemName = getOemName();
    scope.setTag('oem_name', oemName);
  }
});

type FromReducer = AuthTokenState & LanguageState;
type Props = AppProps & FromReducer & DispatchProps;

const ErrorFallback = () => {
  return <></>;
};

export const App = ({history, selectedLanguage}: Props) => {
  document.body.id = 'login';

  i18n.locale = selectedLanguage.code;

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, info) => {
        Sentry.withScope(scope => {
          scope.setExtras(info as any);
          Sentry.captureException(error);
        });
      }}
    >
      <div>
        <BrowserRouter>
          <Routes>
            <Route path='/login' element={<LoginScreen />} />
            <Route
              path='/customer-no-reminder'
              element={<CustomerNoReminder />}
            />
            <Route path='/confirmation-code' element={<ConfirmationCode />} />
            <Route path='/password-reminder' element={<PasswordReminder />} />
            <Route path='/usage-seven-atm' element={<UsageSevenAtm />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/privacy_policy' element={<PrivacyPolicy />} />

            <Route path='/' element={<Home />} />
            <Route path='/tutorial' element={<Tutorial />} />
            <Route path='/passcode' element={<Passcode />} />
            <Route path='/notification-list' element={<NotificationList />} />
            <Route path='/settings' element={<Settings />} />
            <Route path='/settings/bank' element={<BankSetting />} />
            <Route path='/accounting-history' element={<BalanceHistory />} />
          </Routes>
        </BrowserRouter>
        <Alert />
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: GlobalState): FromReducer => ({
  ...state.authToken,
  ...state.language,
});

// state.authToken;
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sentry.withProfiler(App));
