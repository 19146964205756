import {connectRouter, RouterState} from 'connected-react-router';
import {History} from 'history';
import {combineReducers} from 'redux';
import authToken, {AuthTokenState} from './authToken';
import resetPasswordResult, {
  ResetPasswordResultState,
} from './resetPasswordResult';
import requestCustomerNoResult, {
  RequestCustomerNoResultState,
} from './requestCustomerNoResult';
import userInfo, {UserInfoState} from './userInfo';
import pinCode, {PinCodeState} from './pinCode';
import pinCodeFragment, {PinCodeFragmentState} from './pinCodeFragment';
import notifications, {NotificationsState} from './notifications';
import franchiseStores, {FranchiseStoresState} from './franchiseStores';
import criaNotifications from './criaNotifications';
import companyNotifications from './companyNotifications';
import applicationSummary, {
  ApplicationSummaryState,
} from './applicationSummary';
import alert, {AlertState} from './alert';
import financialInstitutions, {
  FinancialInstitutionState,
} from './financialInstitution';
import financialInstitutionBranches, {
  FinancialInstitutionBranchState,
} from './financialInstitutionBranches';
import balanceHistories, {BalanceHistoriesState} from './balanceHistories';
import language, {LanguageState} from './languages';
import confirmationCode, {ConfirmationCodeState} from './confirmationCode';

export interface GlobalState {
  authToken: AuthTokenState;
  userInfo: UserInfoState;
  pinCode: PinCodeState;
  pinCodeFragment: PinCodeFragmentState;
  router: RouterState;
  notifications: NotificationsState;
  criaNotifications: NotificationsState;
  companyNotifications: NotificationsState;
  franchiseStores: FranchiseStoresState;
  applicationSummary: ApplicationSummaryState;
  balanceHistories: BalanceHistoriesState;
  alert: AlertState;
  financialInstitutions: FinancialInstitutionState;
  financialInstitutionBranches: FinancialInstitutionBranchState;
  resetPasswordResult: ResetPasswordResultState;
  requestCustomerNoResult: RequestCustomerNoResultState;
  language: LanguageState;
  confirmationCode: ConfirmationCodeState;
}

declare module 'react-redux' {
  interface DefaultRootState extends GlobalState {}
}

const rootReducer = (history: History) =>
  combineReducers<GlobalState>({
    authToken,
    resetPasswordResult,
    requestCustomerNoResult,
    userInfo,
    pinCode,
    pinCodeFragment,
    notifications,
    criaNotifications,
    companyNotifications,
    franchiseStores,
    applicationSummary,
    alert,
    financialInstitutions,
    financialInstitutionBranches,
    balanceHistories,
    language,
    confirmationCode,
    router: connectRouter(history),
  });

export default rootReducer;
