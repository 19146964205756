import * as React from 'react';
import {PinStatus} from '../components/passcode/pinCode/PinCode';
import {InitPinAction, INIT_PIN} from '../actions/initPin';
import {ChoosePinAction, CHOOSE_PIN} from '../actions/choosePin';
import {
  ConfirmPinAction,
  CONFIRM_PIN,
  CONFIRM_PIN_SUCCESS,
  CONFIRM_PIN_FAILURE,
} from '../actions/confirmPin';
import {
  EnterPinAction,
  ENTER_PIN_SUCCESS,
  ENTER_PIN_FAILURE,
} from '../actions/enterPin';

type PinCodeAction =
  | InitPinAction
  | ChoosePinAction
  | ConfirmPinAction
  | EnterPinAction;

export interface PinCodeState {
  status: PinStatus;
  subtitle: React.ReactElement<any>;
  pin: string;
  hasError: boolean;
  completed: boolean;
}

const initialState: PinCodeState = {
  status: PinStatus.input,
  subtitle: React.createElement('div'),
  pin: '',
  hasError: false,
  completed: false,
};

const pinCodeReducer = (state = initialState, action: PinCodeAction) => {
  switch (action.type) {
    case INIT_PIN:
      return Object.assign({}, state, {
        subtitle: action.payload.subtitle,
        hasError: false,
        completed: false,
      });
    case CHOOSE_PIN:
      return Object.assign({}, state, {
        status: PinStatus.confirm,
        subtitle: action.payload.subtitle,
        pin: action.payload.pin,
        hasError: false,
      });
    case CONFIRM_PIN:
      return Object.assign({}, state, {
        status: PinStatus.input,
        subtitle: action.payload.subtitle,
        pin: action.payload.pin,
      });
    case CONFIRM_PIN_SUCCESS:
      return Object.assign({}, state, {
        completed: true,
      });
    case CONFIRM_PIN_FAILURE:
      return Object.assign({}, state, {
        status: PinStatus.input,
        subtitle: action.payload.subtitle,
        pin: '',
        hasError: true,
      });
    case ENTER_PIN_SUCCESS:
      return Object.assign({}, state, {
        completed: true,
      });
    case ENTER_PIN_FAILURE:
      return Object.assign({}, state, {
        status: PinStatus.input,
        hasError: true,
      });
    default:
      return state;
  }
};

export default pinCodeReducer;
