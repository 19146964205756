import {get} from './http';

const getFinancialInstitutions = (
  financialName: string,
  authToken: string,
  employeeID: number | null
) => {
  return get(
    `/financial_institutions`,
    {text: financialName},
    authToken,
    employeeID
  );
};

export default getFinancialInstitutions;
