import {useEffect} from 'react';
import {Dispatch} from 'redux';
import redirectToLogin from '../../actions/redirectToLogin';
import {connect} from 'react-redux';
import {GlobalState} from '../../reducers/index';
import {AuthTokenState} from '../../reducers/authToken';
import {ConfirmationCodeState} from '../../reducers/confirmationCode';
import {PinCodeState} from '../../reducers/pinCode';
import verifyAuthToken from '../../actions/verifyAuthToken';
import {useNavigate, useLocation} from 'react-router-dom';

import localStorage from '../../util/localStorage';

interface RestrictedPageProps {
  children?: any;
}

interface DispatchProps {
  loadAuthToken: () => {};
  redirect?: typeof redirectToLogin;
}

type Props = RestrictedPageProps &
  AuthTokenState &
  ConfirmationCodeState &
  PinCodeState &
  DispatchProps;

export const RestrictedPage = ({
  loadAuthToken,
  children,
  loggedIn,
  loggedInFromConfirmationCode,
  redirect,
  showPasscodeDialog,
  showPasscodeDialogFromConfirmationCode,
  completed,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!loggedIn) {
      loadAuthToken();
    }
  }, [loadAuthToken, loggedIn]);

  useEffect(() => {
    if (loggedIn || loggedInFromConfirmationCode) {
      if (
        !completed &&
        (showPasscodeDialog || showPasscodeDialogFromConfirmationCode)
      ) {
        if (location.pathname === '/passcode') {
          return;
        } else {
          navigate('/passcode');
          return;
        }
      }
      if (!localStorage.showTutorial) {
        if (location.pathname === '/tutorial') {
          return;
        } else {
          navigate('/tutorial');
          return;
        }
      }
      return;
    } else if (loggedIn === false && loggedInFromConfirmationCode === false) {
      navigate('/login');
      return;
    }
  });

  if (loggedIn) {
    if (!completed && showPasscodeDialog) {
      if (location.pathname === '/passcode') {
        return children;
      } else {
        return <></>;
      }
    }
    if (!localStorage.showTutorial) {
      if (location.pathname === '/tutorial') {
        return children;
      } else {
        return <></>;
      }
    }
    return children;
  } else if (loggedIn === false) {
    return <></>;
  } else {
    return null;
  }
};

const mapStateToProps = (state: GlobalState) => {
  return {
    ...state.authToken,
    ...state.confirmationCode,
    ...state.pinCode,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadAuthToken: () => dispatch(verifyAuthToken()),
  redirect: (pathname: string) => dispatch(redirectToLogin(pathname)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RestrictedPage);
