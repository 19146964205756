import './CancelButton.scss';
import {MouseEvent} from 'react';
import Button from './Button';

const Component = ({
  onClick,
  children,
}: {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | null;
  children: string;
}) => {
  return onClick ? (
    <div className='cancel-button'>
      <Button onClick={onClick} active={true}>
        {children}
      </Button>
    </div>
  ) : (
    <div className='cancel-button'>
      <Button active={true}>{children}</Button>
    </div>
  );
};

export default Component;
