import {
  fetchFinancialInstitutionBranchesAction,
  FinancialInstitutionBranch,
  FETCH_FINANCIAL_INSTITUTION_BRANCHES,
  FETCH_FINANCIAL_INSTITUTION_BRANCHES_SUCCESS,
  FETCH_FINANCIAL_INSTITUTION_BRANCHES_ERROR,
} from '../actions/fetchFinancialInstitutionBranches';
import {
  removeFinancialInstitutionBranchesAction,
  REMOVE_FINANCIAL_INSTITUTION_BRANCHES,
  REMOVE_FINANCIAL_INSTITUTION_BRANCHES_SUCCESS,
} from '../actions/removeFinancialInstitutionBranches';

type FinancialInstitutionBranchesAction =
  | fetchFinancialInstitutionBranchesAction
  | removeFinancialInstitutionBranchesAction;

export interface FinancialInstitutionBranchState {
  financialInstitutionBranches: FinancialInstitutionBranch[];
}

const initialState: FinancialInstitutionBranchState = {
  financialInstitutionBranches: [],
};

const financialInstitutionBranchesReducer = (
  state = initialState,
  action: FinancialInstitutionBranchesAction
) => {
  switch (action.type) {
    case FETCH_FINANCIAL_INSTITUTION_BRANCHES:
      return Object.assign({}, state, {});
    case FETCH_FINANCIAL_INSTITUTION_BRANCHES_SUCCESS:
      return Object.assign({}, state, {
        financialInstitutionBranches:
          action.payload.financialInstitutionBranches,
      });
    case FETCH_FINANCIAL_INSTITUTION_BRANCHES_ERROR:
      return Object.assign({}, state, {});
    case REMOVE_FINANCIAL_INSTITUTION_BRANCHES:
      state = initialState;
      return state;
    case REMOVE_FINANCIAL_INSTITUTION_BRANCHES_SUCCESS:
    default:
      return state;
  }
};

export default financialInstitutionBranchesReducer;
