import {sha256} from 'js-sha256';

export const encode = (password: string, prefix: string, secretKey: string) => {
  return sha256
    .create()
    .update(prefix)
    .update(':')
    .update(secretKey)
    .update(':')
    .update(password)
    .hex();
};
