import {put, call, takeEvery} from 'redux-saga/effects';
import {AUTHENTICATION_FAILURE_COMPLETE} from '../actions/authFailure';
import {SHOW_ALERT} from '../actions/showAlert';
import fetchUserInfo, {
  UserInfo,
  FETCH_USER_INFO,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO_ERROR,
} from '../actions/fetchUserInfo';
import putUser, {PUT_USER, PUT_USER_ERROR} from '../actions/putUser';
import putUserApi from '../api/putUser';
import getUser from '../api/getUser';
import localStorage from '../util/localStorage';
import reportError from './reportError';

function* handleFetchUserInfo(action: ReturnType<typeof fetchUserInfo>) {
  try {
    const authToken: string = yield localStorage.authToken;
    const resp: {data: UserInfo} = yield call(
      getUser,
      authToken,
      action.payload.employeeID
    );
    yield put({
      type: FETCH_USER_INFO_SUCCESS,
      payload: {user: resp.data},
    });
  } catch (e: any) {
    if (e.response && e.response.status === 403) {
      yield put({
        type: AUTHENTICATION_FAILURE_COMPLETE,
      });
      return;
    }

    yield reportError(e, FETCH_USER_INFO_ERROR);
  }
}

function* handlePutUser(action: ReturnType<typeof putUser>) {
  try {
    const authToken: string = yield localStorage.authToken;
    const instanceID: string = yield localStorage.instanceID;
    yield call(
      putUserApi,
      authToken,
      action.payload.employeeID || 0,
      instanceID,
      action.payload.financialInstitutionCode,
      action.payload.branchCode,
      action.payload.accountType,
      action.payload.accountNumber,
      action.payload.accountName
    );

    if (action.alertConfig) {
      yield put({
        type: SHOW_ALERT,
        payload: {
          config: action.alertConfig,
        },
      });
    }
  } catch (e: any) {
    if (e.response && e.response.status === 403) {
      yield put({
        type: AUTHENTICATION_FAILURE_COMPLETE,
      });
      return;
    }

    yield reportError(e, PUT_USER_ERROR);
  }
}

function* userInfoSaga() {
  yield takeEvery(FETCH_USER_INFO, handleFetchUserInfo);
  yield takeEvery(PUT_USER, handlePutUser);
}

export default userInfoSaga;
