import {
  ChangePaymentMethodAction,
  CHANGE_PAYMENT_METHOD,
  SET_PAYMENT_METHOD,
  SET_AMOUNT,
  CHANGE_PAYMENT_METHOD_SUCCESS,
} from '../actions/changePaymentMethod';
import {DecreaseAmountAction, DECREASE_AMOUNT} from '../actions/decreaseAmount';
import {
  FetchApplicationSummaryAction,
  FETCH_APPLICATION_SUMMARY,
  FETCH_APPLICATION_SUMMARY_ERROR,
  FETCH_APPLICATION_SUMMARY_SUCCESS,
} from '../actions/fetchApplicationSummary';
import {
  ApplyPrepaymentAction,
  APPLY_PREPAYMENT,
  APPLY_PREPAYMENT_ERROR,
  APPLY_PREPAYMENT_SUCCESS,
} from '../actions/applyPrepayment';
import {IncreaseAmountAction, INCREASE_AMOUNT} from '../actions/increaseAmount';
import {
  CloseApplicationResultAction,
  CLOSE_APPLICATION_RESULT,
} from '../actions/closeApplicationResult';
import {
  AuthenticationFailureAction,
  AUTHENTICATION_FAILURE,
} from '../actions/authFailure';
import {
  OpenPasscodeDialogAction,
  OPEN_PASSCODE_DIALOG,
} from '../actions/openPasscodeDialog';

type ApplicationSummaryAction =
  | FetchApplicationSummaryAction
  | ChangePaymentMethodAction
  | DecreaseAmountAction
  | IncreaseAmountAction
  | ChangePaymentMethodAction
  | OpenPasscodeDialogAction
  | ApplyPrepaymentAction
  | CloseApplicationResultAction
  | AuthenticationFailureAction;

export interface IBankAccount {
  financialInstitutionName: string;
  branchName: string;
  accountType: number;
  accountNumber: string;
  nextTransferDate: string;
}

export interface ISevenAccount {
  partnerCode: string;
  customerNo: string;
}

export interface IApplicationFee {
  paymentMethod: number;
  systemFee: number;
  transferFee: number;
  totalFee: number;
  version: string;
}

export interface IApplicationSummary {
  totalAmount: number;
  upperLimitAmount: number;
  startDate: string;
  endDate: string;
  latestDeadlineDate: string;
  bankAccount: IBankAccount;
  sevenAccount: ISevenAccount;
  paymentMethods: number[] | null;
  paymentMethodIndex: number;
  maxTransferAmount: number;
  applicationFees: IApplicationFee[];
}

export interface IApplication {
  amount: number;
  transferAmount: number;
  increaseButtonEnabled: boolean;
  decreaseButtonEnabled: boolean;
  applyButtonEnabled: boolean;
}

export interface IApplicationResult {
  transferAmount: number;
  transferDate: string;
  applicationNumber: string;
  confirmNo: string;
  deadlineDate: string;
  partnerCode: string;
  customerNo: string;
}

export interface ApplicationSummaryState {
  applicationSummary: IApplicationSummary;
  applicationFee: IApplicationFee;
  application: IApplication;
  applicationResult: IApplicationResult;
  loading: boolean;
  showBreakdown: boolean;
  opensConfirmDialog: boolean;
  showsPasscodeDialog: boolean;
  showsApplicationResult: boolean;
  message: string;
}

const AMOUNT_UNIT = 1000;
const DEFAULT_TRANSFER_AMOUNT = 10000;
const DEFAULT_APPLICATION = {
  amount: 0,
  transferAmount: DEFAULT_TRANSFER_AMOUNT,
  increaseButtonEnabled: false,
  decreaseButtonEnabled: false,
  applyButtonEnabled: false,
};

const DEFAULT_TIMESTAMP = '1970-01-01T00:00:00+09:00Z';

const DEFAULT_PAYMENT_METHOD_INDEX = 0;

const initialState: ApplicationSummaryState = {
  applicationSummary: {
    totalAmount: 0,
    upperLimitAmount: 0,
    startDate: DEFAULT_TIMESTAMP,
    endDate: DEFAULT_TIMESTAMP,
    latestDeadlineDate: DEFAULT_TIMESTAMP,
    applicationFees: [],
    bankAccount: {
      financialInstitutionName: '',
      branchName: '',
      accountType: 1,
      accountNumber: '0000000',
      nextTransferDate: '',
    },
    sevenAccount: {
      partnerCode: '0000',
      customerNo: '00000000',
    },
    paymentMethods: [],
    paymentMethodIndex: DEFAULT_PAYMENT_METHOD_INDEX,
    maxTransferAmount: 0,
  },
  applicationFee: {
    paymentMethod: 0,
    systemFee: 0,
    transferFee: 0,
    totalFee: 0,
    version: '',
  },
  application: DEFAULT_APPLICATION,
  applicationResult: {
    transferAmount: 0,
    transferDate: '',
    applicationNumber: '',
    confirmNo: '',
    deadlineDate: '',
    partnerCode: '',
    customerNo: '',
  },
  loading: false,
  showBreakdown: false,
  opensConfirmDialog: false,
  showsPasscodeDialog: false,
  showsApplicationResult: false,
  message: '',
};

const applicationSummaryReducer = (
  state = initialState,
  action: ApplicationSummaryAction
) => {
  switch (action.type) {
    case FETCH_APPLICATION_SUMMARY:
      return Object.assign({}, state, {
        loading: true,
        showsPasscodeDialog: false,
        message: null,
      });
    case CHANGE_PAYMENT_METHOD:
      return Object.assign({}, state, {
        loading: true,
      });
    case FETCH_APPLICATION_SUMMARY_SUCCESS: {
      const paymentMethodIndex =
        action.payload.applicationSummary.paymentMethods &&
        action.payload.applicationSummary.paymentMethods.length > 0
          ? action.payload.applicationSummary.paymentMethods[0] - 1
          : DEFAULT_PAYMENT_METHOD_INDEX;

      const applicationSummary: IApplicationSummary = {
        totalAmount: action.payload.applicationSummary.totalAmount,
        upperLimitAmount: action.payload.applicationSummary.totalAmount,
        startDate: action.payload.applicationSummary.startDate,
        endDate: action.payload.applicationSummary.endDate,
        latestDeadlineDate:
          action.payload.applicationSummary.latestDeadlineDate,
        applicationFees: action.payload.applicationSummary.applicationFees,
        bankAccount: {
          financialInstitutionName:
            action.payload.applicationSummary.financialInstitutionName,
          branchName: action.payload.applicationSummary.branchName,
          accountType: action.payload.applicationSummary.accountType,
          accountNumber: action.payload.applicationSummary.accountNumber,
          nextTransferDate: action.payload.applicationSummary.nextTransferDate,
        },
        sevenAccount: {
          partnerCode: action.payload.applicationSummary.partnerCode,
          customerNo: action.payload.applicationSummary.customerNo,
        },
        paymentMethods: action.payload.applicationSummary.paymentMethods,
        paymentMethodIndex,
        maxTransferAmount: action.payload.applicationSummary.maxTransferAmount,
      };

      return Object.assign({}, state, {
        applicationSummary,
        application: DEFAULT_APPLICATION,
      });
    }
    case FETCH_APPLICATION_SUMMARY_ERROR:
      return Object.assign({}, state, {});
    case INCREASE_AMOUNT: {
      const upperLimitAmount = Math.min(
        state.applicationSummary.upperLimitAmount,
        state.applicationSummary.maxTransferAmount +
          state.applicationFee.totalFee
      );

      const increasedAmount = Math.min(
        state.application.amount + AMOUNT_UNIT,
        upperLimitAmount
      );
      const increasedTransferAmount =
        increasedAmount - state.applicationFee.totalFee;

      return Object.assign({}, state, {
        application: {
          amount: increasedAmount,
          transferAmount: increasedTransferAmount,
          increaseButtonEnabled: increaseButtonEnabled(
            increasedAmount,
            upperLimitAmount
          ),
          decreaseButtonEnabled: decreaseButtonEnabled(increasedTransferAmount),
          applyButtonEnabled: applyButtonEnabled(increasedTransferAmount),
        },
      });
    }
    case DECREASE_AMOUNT: {
      const decreasedAmount = Math.max(
        state.application.amount - AMOUNT_UNIT,
        AMOUNT_UNIT + state.applicationFee.totalFee
      );
      const decreasedTransferAmount =
        decreasedAmount - state.applicationFee.totalFee;

      return Object.assign({}, state, {
        application: {
          amount: decreasedAmount,
          transferAmount: decreasedTransferAmount,
          increaseButtonEnabled: true,
          decreaseButtonEnabled: decreaseButtonEnabled(decreasedTransferAmount),
          applyButtonEnabled: applyButtonEnabled(decreasedTransferAmount),
        },
      });
    }
    case APPLY_PREPAYMENT:
      return Object.assign({}, state, {});
    case APPLY_PREPAYMENT_SUCCESS:
      return Object.assign({}, state, {
        opensConfirmDialog: false,
        showsApplicationResult: true,
        applicationResult: action.payload.applicationResult,
        showsPasscodeDialog: false,
        message: null,
      });
    case APPLY_PREPAYMENT_ERROR:
      return Object.assign({}, state, {});
    case CLOSE_APPLICATION_RESULT:
      return Object.assign({}, state, {
        showsApplicationResult: false,
      });
    case AUTHENTICATION_FAILURE:
      return Object.assign({}, state, {
        refreshing: false,
      });
    case OPEN_PASSCODE_DIALOG:
      return Object.assign({}, state, {
        showsPasscodeDialog: true,
        opensConfirmDialog: false,
      });
    case SET_PAYMENT_METHOD: {
      const paymentMethodIndex = action.payload.paymentMethodIndex;
      const applicationSummary = Object.assign({}, state.applicationSummary, {
        paymentMethodIndex,
      });

      const paymentMethod = paymentMethodIndex + 1;
      const applicationFee = state.applicationSummary.applicationFees.find(
        (element: IApplicationFee) => element.paymentMethod === paymentMethod
      );
      if (!applicationFee) {
        throw new Error(
          `paymentMethod: ${paymentMethod} の applicationFee がありません。`
        );
      }

      return Object.assign({}, state, {
        applicationSummary,
        applicationFee,
      });
    }
    case SET_AMOUNT: {
      const totalAmount = state.applicationSummary.totalAmount;
      const maxTransferAmount = state.applicationSummary.maxTransferAmount;
      const totalFee = state.applicationFee.totalFee;
      const transferAmount = state.application.transferAmount;

      const amount = Math.min(
        Math.floor((totalAmount - totalFee) / AMOUNT_UNIT) * AMOUNT_UNIT +
          totalFee,
        transferAmount + totalFee,
        maxTransferAmount + totalFee
      );

      const newTransferAmount = amount - totalFee;
      const application = {
        amount,
        transferAmount: newTransferAmount,
        increaseButtonEnabled: increaseButtonEnabled(
          amount,
          Math.min(totalAmount, maxTransferAmount)
        ),
        decreaseButtonEnabled: decreaseButtonEnabled(newTransferAmount),
        applyButtonEnabled: applyButtonEnabled(newTransferAmount),
      };
      return Object.assign({}, state, {
        application,
      });
    }
    case CHANGE_PAYMENT_METHOD_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
      });
    }
    default:
      return state;
  }
};

const increaseButtonEnabled = (amount: number, totalAmount: number) =>
  amount + AMOUNT_UNIT <= totalAmount;
const decreaseButtonEnabled = (transferAmount: number) =>
  transferAmount > AMOUNT_UNIT;
const applyButtonEnabled = (transferAmount: number) =>
  transferAmount >= AMOUNT_UNIT;

export default applicationSummaryReducer;
