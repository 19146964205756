import {i18n} from '../../locale/i18n';
import {connect} from 'react-redux';
import commaNumber from 'comma-number';
import SubmitButton from '../../components/parts/SubmitButton';
import WhiteOutButton from '../../components/parts/WhiteOutButton';
import BankDetail from '../../components/parts/BankDetail';
import ReactModal from 'react-modal';
import {GlobalState} from '../../reducers/index';
import {IApplicationSummary} from '../../reducers/applicationSummary';
import './Confirm.scss';
import './Modal.scss';

interface Props {
  paymentMethod: number;
  amount: number;
  confirmOpened: boolean;
  setConfirmOpened: (confirmOpened: boolean) => void;
  submit: () => void;
}

const Confirm = ({
  paymentMethod,
  amount,
  confirmOpened,
  setConfirmOpened,
  submit,
  paymentMethods,
  applicationFees,
  bankAccount,
}: Props & IApplicationSummary) => {
  const applicationFee =
    applicationFees.length > 1
      ? applicationFees[paymentMethod]
      : applicationFees[0];
  if (!applicationFee) {
    return <></>;
  }
  const {totalFee} = applicationFee;

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={confirmOpened}
      onRequestClose={() => {
        setConfirmOpened(false);
      }}
      contentLabel='Settings'
      overlayClassName='overlay'
      className='home-confirm'
    >
      <section>
        {i18n
          .t('confirm.annotation')
          .split(/\n/)
          .map((t: string) => (
            <h2 key={t}>{t}</h2>
          ))}
        <div className='prepayment-apply-details'>
          <div className='acceptable'>
            <div>{i18n.t('common.withdrawalAmount')}</div>
            <div>
              <div>{commaNumber(amount - totalFee)}</div>
              <div>{i18n.t('common.yen')}</div>
            </div>
          </div>
          <hr />
          <div className='apply'>
            <div>{i18n.t('application.requestAnnotation')}:</div>
            <div>{commaNumber(amount)}円</div>
            <div>（手数料{totalFee}円）</div>
          </div>
        </div>
        <h3>{i18n.t('application.recipient')}</h3>
        {applicationFee.paymentMethod === 1 ? (
          <>
            <div className='payment-method-bank'>
              <BankDetail bankAccount={bankAccount} />
            </div>
          </>
        ) : (
          <div className='payment-method-seven'>
            <img alt='seven' src='/img/home/seven.png' />
            <img alt='seven-atm' src='/img/home/seven-atm.png' />
            <p>
              セブン銀行ATMで
              <br />
              現金で受け取る
            </p>
          </div>
        )}
        <SubmitButton
          onClick={() => {
            setConfirmOpened(false);
            submit();
          }}
          active={true}
        >
          {i18n.t('button.confirm')}
        </SubmitButton>
        <WhiteOutButton
          onClick={() => {
            setConfirmOpened(false);
          }}
        >
          {i18n.t('button.cancel')}
        </WhiteOutButton>
      </section>
    </ReactModal>
  );
};

const mapStateToProps = (state: GlobalState): IApplicationSummary =>
  state.applicationSummary.applicationSummary;

export default connect(mapStateToProps)(Confirm);
