import ReportError from './reportError';

export const FETCH_FINANCIAL_INSTITUTION = 'FETCH_FINANCIAL_INSTITUTION';
export const FETCH_FINANCIAL_INSTITUTION_SUCCESS =
  'FETCH_FINANCIAL_INSTITUTION_SUCCESS';
export const FETCH_FINANCIAL_INSTITUTION_ERROR =
  'FETCH_FINANCIAL_INSTITUTION_ERROR';

const fetchFinancialInstitutions = (
  employeeID: number | null,
  financialName: string
) => ({
  type: FETCH_FINANCIAL_INSTITUTION as typeof FETCH_FINANCIAL_INSTITUTION,
  payload: {employeeID, financialName},
});

export interface FinancialInstitution {
  financialInstitutionCode: string;
  financialInstitutionName: string;
}

export interface FinancialInstitutionSuccess {
  type: typeof FETCH_FINANCIAL_INSTITUTION_SUCCESS;
  payload: {
    financialInstitutions: FinancialInstitution[];
  };
}

export type fetchFinancialInstitutionAction =
  | ReturnType<typeof fetchFinancialInstitutions>
  | FinancialInstitutionSuccess
  | ReportError<typeof FETCH_FINANCIAL_INSTITUTION_ERROR>;

export default fetchFinancialInstitutions;
