import {i18n} from '../../locale/i18n';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import WhiteOutButton from '../../components/parts/WhiteOutButton';
import ReactModal from 'react-modal';
import removeAuthToken from '../../actions/removeAuthToken';
import './LogoutConfirm.scss';

interface Props {
  confirmOpened: boolean;
  setConfirmOpened: (confirmOpened: boolean) => void;
}

interface DispatchProps {
  logout: () => void;
}

const Confirm = ({
  confirmOpened,
  setConfirmOpened,
  logout,
}: Props & DispatchProps) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={confirmOpened}
      onRequestClose={() => {
        setConfirmOpened(false);
      }}
      contentLabel='Settings'
      overlayClassName='overlay'
      className='settings-logout-confirm'
    >
      <section>
        <h2>{i18n.t('settings.logoutConfirm')}</h2>
        <WhiteOutButton
          onClick={() => {
            logout();
          }}
        >
          {i18n.t('settings.logout')}
        </WhiteOutButton>
        <WhiteOutButton
          onClick={() => {
            setConfirmOpened(false);
          }}
        >
          {i18n.t('button.cancel')}
        </WhiteOutButton>
      </section>
    </ReactModal>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  logout: () => dispatch(removeAuthToken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
