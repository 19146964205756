import {
  SetPincodeFragmentAction,
  SET_PINCODE_FRAGMENT,
} from '../actions/setPinCodeFragment';

type PinCodeFragmentAction = SetPincodeFragmentAction;

export interface PinCodeFragmentState {
  updated: number;
}

const initialState: PinCodeFragmentState = {
  updated: 0,
};

const pinCodeReducer = (
  state = initialState,
  action: PinCodeFragmentAction
) => {
  switch (action.type) {
    case SET_PINCODE_FRAGMENT:
      return Object.assign({}, state, {
        updated: state.updated,
      });
    default:
      return state;
  }
};

export default pinCodeReducer;
