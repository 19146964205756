export const checkInputted = (value: string) => {
  return value.length === 0;
};

export const checkLength = (value: string, length: number) => {
  return value.length !== length;
};

export const checkMaxLength = (value: string, maxLength: number) => {
  return value.length > maxLength;
};

export const checkNumbers = (value: string) => {
  return value.match(/^\d+$/) == null;
};

export const checkPhoneNumber = (value: string) => {
  return value.match(/^0\d{1,3}-\d{1,4}-\d{3,4}$/) == null;
};

export const checkMailAddress = (value: string) => {
  return value.match(/^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/) == null;
};
