import './SevenDetail.scss';
import './Detail.scss';
import moment from 'moment';
import commaNumber from 'comma-number';
import {customerNoWithSpace} from '../../util/number';
import {
  BalanceHistory,
  SevenDetail as SevenDetailType,
} from '../../reducers/balanceHistories';

const SevenDetail = ({
  viewReceiveMethod,
  balanceHistory,
  close,
}: {
  balanceHistory: BalanceHistory;
  viewReceiveMethod: boolean;
  close: () => any;
}) => {
  const {transferAmount, systemFee, transferFee, sevenDetail} = balanceHistory;
  const {partnerCode, customerNo, confirmNo, deadlineDate} =
    sevenDetail as SevenDetailType;

  const table = [
    {
      title: '提携先コード',
      value: partnerCode,
    },
    {
      title: 'お客様番号',
      value: customerNoWithSpace(customerNo || ''),
    },
    {
      title: '確認番号',
      value: confirmNo,
    },
    {
      title: '受取期限',
      value: moment(deadlineDate).format('YYYY年M月D日 HH:mm'),
    },
  ];
  return (
    <div className='seven-detail-wrapper'>
      <div className='vertical-spacer' onClick={close} />
      <div className='seven-detail history-detail'>
        <div className='amount-and-fee'>
          <div className='icon' />
          <div className='amount'>
            <p>受取金額</p>
            <p>{`${commaNumber(transferAmount)}円`}</p>
          </div>
          <div className='fee'>
            <p>手数料</p>
            <p>{`${commaNumber(systemFee + transferFee)}円`}</p>
          </div>
          <div className='expand' />
        </div>
        <div className='account'>
          <h3>
            <div className='icon' />
            <div className='contents'>
              <div className='icon-and-name'>
                <img
                  alt='seven'
                  src='/img/accounting-history/icons/seven.svg'
                />
                <p>セブン銀行振込</p>
              </div>
              <ul>
                {table.map(({title, value}) => (
                  <li key={title}>
                    <ul>
                      <li>{title}</li>
                      <li>{value}</li>
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
            <div />
            <div className='expand' />
          </h3>
        </div>
        <div className='spacer' onClick={close} />
        {viewReceiveMethod ? (
          <>
            <a href='/usage-seven-atm' target='blank'>
              <img
                alt='receive-method'
                className='receive-method'
                src='/img/accounting-history/icons/receive-method.svg'
              />
              <p> 現金の受取方法 </p>
              <img
                alt='right-arrow'
                className='right-arrow'
                src='/img/accounting-history/icons/right-arrow.svg'
              />
            </a>
            <div className='spacer' onClick={close} />
          </>
        ) : (
          <></>
        )}
      </div>
      <div className='vertical-spacer' onClick={close} />
    </div>
  );
};

export default SevenDetail;
