import {i18n} from '../../locale/i18n';
import {useState, useEffect} from 'react';
import {useNavigate, NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import requestCustomerNoReminder from '../../actions/requestCustomerNoReminder';
import {GlobalState} from '../../reducers/index';
import DocumentTitle from '../parts/DocumentTitle';
import Header from '../parts/Headers/Reminder';
import showAlert from '../../actions/showAlert';
import {
  checkInputted,
  checkNumbers,
  checkPhoneNumber,
  checkMailAddress,
} from '../../util/validation';

import {RequestCustomerNoResultState} from '../../reducers/requestCustomerNoResult';

import sendEventAnalytics from '../../actions/sendEventAnalytics';
import resetPasswordReset from '../../actions/resetPasswordReset';

import EmailOrSms from '../parts/EmailOrSms';
import WhiteOutButton from '../parts/WhiteOutButton';
import RightAllowLink from '../parts/RightAllowLink';
import LeftAllowLink from '../parts/LeftAllowLink';

import {useTimer} from 'react-timer-hook';
import moment from 'moment';

import './Reminder.scss';
import './ReminderFailed.scss';
import './ReminderSended.scss';
import '../login/loginAndPassword.scss';

import constants from '../../constants';

interface DispatchProps {
  sendEvent: typeof sendEventAnalytics;
  callShowAlert: typeof showAlert;
  resetReminderResults: () => void;
  remindCustomerNo: typeof requestCustomerNoReminder;
}

type Props = RequestCustomerNoResultState & DispatchProps;

export const CustomerNoReminder = ({
  resetReminderResults,
  sendEvent,
  callShowAlert,
  requestCustomerNoCompleted,
  requestCustomerNoSucceeded,
  remindCustomerNo,
  requestCustomerNoDate,
}: Props) => {
  const navigate = useNavigate();
  resetReminderResults();

  const {seconds, minutes, restart} = useTimer({
    expiryTimestamp: moment().toDate(),
  });

  const active = seconds === 0 && minutes === 0;
  const waitSeconds = `もう一度送信するには${seconds}秒おまちください`;

  useEffect(() => {
    if (requestCustomerNoDate) {
      restart(
        moment(requestCustomerNoDate)
          .add(constants.resetAfterWaitSeconds, 's')
          .toDate()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestCustomerNoDate]);

  const [mailAddressOrPhoneNumber, setMailAddressOrPhoneNumber] =
    useState<string>('');

  const submit = () => {
    sendEvent('CustomerNoReminder', 'customer no reminder button click');

    let message: string | null = null;
    let mailAddress = '';
    let phoneNumber = '';
    if (checkInputted(mailAddressOrPhoneNumber)) {
      message = i18n.t('alert.contactEmpty');
    } else if (
      !checkNumbers(mailAddressOrPhoneNumber) ||
      !checkPhoneNumber(mailAddressOrPhoneNumber)
    ) {
      phoneNumber = mailAddressOrPhoneNumber.replace(/-/g, '');
    } else if (!checkMailAddress(mailAddressOrPhoneNumber)) {
      mailAddress = mailAddressOrPhoneNumber;
    } else {
      message = i18n.t('alert.mailAddressInvalid');
    }

    if (message !== null) {
      sendEvent('CustomerNoReminder', 'customer no reminder validation error');
      callShowAlert({
        title: '入力エラー',
        message,
      });
    } else {
      remindCustomerNo(mailAddress, phoneNumber);
    }
  };
  const [returnToLogin, setReturnToLogin] = useState(false);
  useEffect(() => {
    if (returnToLogin) {
      navigate('/');
    }
  }, [navigate, returnToLogin]);

  if (requestCustomerNoCompleted && requestCustomerNoSucceeded) {
    return (
      <>
        <Header />
        <main className='login-and-password reminder-sended'>
          <DocumentTitle
            pageNameInTitle={i18n.t('passwordReset.linkCustomerNumberReminder')}
          />
          <div className='contents-inner'>
            <h2>{i18n.t('passwordResetDone.title')}</h2>
            <h3>お客様番号のご案内をお送りしました。</h3>
            <p>数分経ってもメールまたはメッセージ（SMS）が届かない場合は、</p>
            <p>一度所属会社に登録情報の確認をお願いいたします。</p>

            <form>
              <div className='submit'>
                {seconds > 0 ? <p className='error'>{waitSeconds}</p> : <></>}
                <WhiteOutButton onClick={submit} active={active}>
                  {i18n.t('passwordResetDone.resend')}
                </WhiteOutButton>
              </div>
            </form>

            <LeftAllowLink
              onClick={() => {
                sendEvent('Login', 'login link click');
                setReturnToLogin(true);
              }}
            >
              {i18n.t('passwordResetDone.linkLogin')}
            </LeftAllowLink>
          </div>
        </main>
      </>
    );
  }

  if (requestCustomerNoCompleted && !requestCustomerNoSucceeded) {
    const failureDescriptions = i18n
      .t('customerNoReminder.failureDescription')
      .split('\n');
    return (
      <>
        <Header />
        <main className='reminder-failed'>
          <DocumentTitle pageNameInTitle='ログイン画面 お客様番号を忘れた方' />
          <div className='contents-inner'>
            <img alt='error' src='/img/reminder/error.svg' />
            <h2>{i18n.t('customerNoReminder.failureTitle')}</h2>
            {failureDescriptions.map((description: string) => (
              <p key={description}>{description}</p>
            ))}

            <form>
              <EmailOrSms
                name='mailphone'
                onChange={e => setMailAddressOrPhoneNumber(e.target.value)}
                value={mailAddressOrPhoneNumber}
              />

              <div className='submit'>
                {seconds > 0 ? <p className='error'>{waitSeconds}</p> : <></>}
                <WhiteOutButton onClick={submit} active={active}>
                  {i18n.t('button.send')}
                </WhiteOutButton>
              </div>
            </form>

            <NavLink
              to='/password-reminder'
              onClick={() =>
                sendEvent(
                  'CustomerNoReminder',
                  'customer no reminder login link click'
                )
              }
            >
              <RightAllowLink>
                {i18n.t('customerNoReminder.linkPasswordReminder')}
              </RightAllowLink>
            </NavLink>
            <NavLink
              to='/login'
              onClick={() => sendEvent('Login', 'login link click')}
            >
              <LeftAllowLink>
                {i18n.t('passwordResetDone.linkLogin')}
              </LeftAllowLink>
            </NavLink>
          </div>
        </main>
      </>
    );
  }

  const descriptions = i18n.t('customerNoReminder.description').split('\n');
  return (
    <>
      <Header />
      <main className='reminder'>
        <DocumentTitle pageNameInTitle='ログイン画面 お客様番号を忘れた方' />
        <div className='contents-inner'>
          <h2>{i18n.t('customerNoReminder.title')}</h2>
          {descriptions.map((description: string) => (
            <p key={description}>{description}</p>
          ))}

          <form>
            <EmailOrSms
              name='mailphone'
              onChange={e => setMailAddressOrPhoneNumber(e.target.value)}
              value={mailAddressOrPhoneNumber}
            />

            <div className='submit'>
              {seconds > 0 ? <p className='error'>{waitSeconds}</p> : <></>}
              <WhiteOutButton onClick={submit} active={active}>
                {i18n.t('button.send')}
              </WhiteOutButton>
            </div>
          </form>

          <NavLink
            to='/password-reminder'
            onClick={() =>
              sendEvent(
                'CustomerNoReminder',
                'customer no reminder login link click'
              )
            }
          >
            <RightAllowLink>
              {i18n.t('customerNoReminder.linkPasswordReminder')}
            </RightAllowLink>
          </NavLink>
          <NavLink
            to='/login'
            onClick={() => sendEvent('Login', 'login link click')}
          >
            <LeftAllowLink>
              {i18n.t('passwordResetDone.linkLogin')}
            </LeftAllowLink>
          </NavLink>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  ...state.requestCustomerNoResult,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  resetReminderResults: () => {
    dispatch(resetPasswordReset());
  },
  remindCustomerNo: (mailAddress, phoneNumber) =>
    dispatch(requestCustomerNoReminder(mailAddress, phoneNumber)),
  sendEvent: (category, action) =>
    dispatch(sendEventAnalytics(category, action)),
  callShowAlert: config => dispatch(showAlert(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerNoReminder);
