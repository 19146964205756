export const SET_PINCODE_FRAGMENT = 'SET_PINCODE_FRAGMENT';
export const SET_PINCODE_FRAGMENT_SUCCESS = 'SET_PINCODE_FRAGMENT_SUCCESS';

const setPinCodeFragment = () => ({
  type: SET_PINCODE_FRAGMENT as typeof SET_PINCODE_FRAGMENT,
  payload: {
    updated: new Date().getTime(),
  },
});

interface SetPincodeFragmentSuccess {
  type: typeof SET_PINCODE_FRAGMENT_SUCCESS;
}

export type SetPincodeFragmentAction =
  | ReturnType<typeof setPinCodeFragment>
  | SetPincodeFragmentSuccess;

export default setPinCodeFragment;
