export const AGREE_WITH_THE_TERMS = 'AGREE_WITH_THE_TERMS';
export const POST_AGREE_WITH_THE_TERMS = 'POST_AGREE_WITH_THE_TERMS';
export const postAgreeWithTheTerms = (customerNo: string) => ({
  type: POST_AGREE_WITH_THE_TERMS,
  payload: {customerNo},
});
export const POST_AGREE_WITH_THE_TERMS_SUCCESS =
  'POST_AGREE_WITH_THE_TERMS_SUCCESS';
export const POST_AGREE_WITH_THE_TERMS_FAILURE =
  'POST_AGREE_WITH_THE_TERMS_FAILURE';
export const CANCEL_AGREE_WITH_THE_TERMS = 'CANCEL_AGREE_WITH_THE_TERMS';
export const cancelAgreeWithTheTerms = () => ({
  type: CANCEL_AGREE_WITH_THE_TERMS,
});

export interface PostAgreeWithTheTermsSuccessType {
  type: typeof POST_AGREE_WITH_THE_TERMS_SUCCESS;
  payload: {
    authToken: string;
    isAppPasswordSet: boolean;
  };
}

export type AgreeWithTheTermsType =
  | {type: typeof AGREE_WITH_THE_TERMS}
  | {type: typeof POST_AGREE_WITH_THE_TERMS_FAILURE}
  | PostAgreeWithTheTermsSuccessType
  | {type: typeof CANCEL_AGREE_WITH_THE_TERMS};
