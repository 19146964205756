import {AxiosError, AxiosResponse} from 'axios';

export default class HttpRequestError implements AxiosError {
  public name: string;
  public message: string;
  public code?: string;
  public request?: any;
  public response?: AxiosResponse;
  public isAxiosError: boolean;
  private readonly json: object;

  constructor(e: AxiosError) {
    this.name = e.name;
    this.message = e.message;
    this.code = e.code;
    this.request = e.request;
    this.response = e.response;
    this.isAxiosError = e.isAxiosError;
    this.json = e.toJSON();
  }

  public toJSON(): object {
    return this.json;
  }
}
