import {post} from './http';

const postRequestCustomerNoReminderToken = (
  mailAddress: string,
  phoneNumber: string,
  instanceID: string
) => {
  const oemCode = process.env.REACT_APP_OEM_CODE;
  const params = {mailAddress, phoneNumber, oemCode};
  return post('/customer_no/reminder', params, instanceID);
};

export default postRequestCustomerNoReminderToken;
