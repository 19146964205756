import {get} from './http';

const getNotifications = (
  cursor: string | null,
  authToken: string,
  distributorType?: number
) => {
  const params: {cursor?: string; distributor_type?: number; limit: number} =
    cursor !== null ? {cursor, limit: 10} : {limit: 10};

  if (distributorType) {
    params.distributor_type = distributorType;
  }

  return get('/notifications', params, authToken);
};

export default getNotifications;
