import {post} from './http';

const postConfirmationCode = (
  confirmationCodeToken: string,
  instanceID: string
) => {
  const params = {
    confirmationCodeToken,
  };
  return post('/confirmation_code', params, null, instanceID);
};

export default postConfirmationCode;
