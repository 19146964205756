import {i18n} from '../../locale/i18n';
import './Password.scss';
import './Inputs.scss';
import {ChangeEvent, useState} from 'react';

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  name?: string;
  title?: string;
  subTitle?: string;
  viewPasswordVisibleButton?: boolean;
}

const Component = ({
  onChange,
  name,
  title,
  subTitle,
  viewPasswordVisibleButton,
  value,
}: Props) => {
  const [viewPassword, setViewPassword] = useState(false);
  return (
    <div className='password inputs'>
      {subTitle ? (
        <div>
          <p>{title || i18n.t('common.password')}</p>
          <p>{subTitle}</p>
        </div>
      ) : (
        <p>{title || i18n.t('common.password')}</p>
      )}
      <div>
        <input
          name={name || 'password'}
          value={value || ''}
          type='text'
          maxLength={255}
          onChange={onChange}
          className={viewPassword ? '' : 'mask'}
          autoComplete='off'
        />
        {viewPasswordVisibleButton ? (
          <img
            alt='hide-password'
            onClick={() => setViewPassword(!viewPassword)}
            src='/img/login-screen/hide-password.png'
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Component;
