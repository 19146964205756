import {get} from './http';

const getFinancialInstitutionBranches = (
  financialInstitutionCode: string,
  text: string,
  authToken: string,
  employeeID: number | null
) => {
  return get(
    `/financial_institutions/${financialInstitutionCode}/branches`,
    {text, limit: 10},
    authToken,
    employeeID
  );
};

export default getFinancialInstitutionBranches;
