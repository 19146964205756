import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import SubmitButton from './parts/SubmitButton';
import MiniSubmitButton from './parts/MiniSubmitButton';
import DocumentTitle from './parts/DocumentTitle';
import ConfirmationCodeDialog from '../components/parts/ConfirmationCode';
import {GlobalState} from '../reducers/index';
import {AuthTokenState} from '../reducers/authToken';
import {ConfirmationCodeState} from '../reducers/confirmationCode';
import showAlert from '../actions/showAlert';
import {AlertConfig} from './parts/Alert';
import './ConfirmationCode.scss';
import verifyConfirmationCode, {
  verifyConfirmationCodeExpireCancel,
  verifyConfirmationCodeErrorCancel,
  Meta,
} from '../actions/verifyConfirmationCode';
import recreateConfirmationCode, {
  recreateConfirmationCodeCancel,
} from '../actions/recreateConfirmationCode';
import {useTimer} from 'react-timer-hook';
import localStorage from '../util/localStorage';
import moment from 'moment';

interface DispatchProps {
  callVefiryConfirmationCode: typeof verifyConfirmationCode;
  callConfirmationCodeRecreate: typeof recreateConfirmationCode;
  callShowAlert: typeof showAlert;
  callConfirmationCodeRecreateExpireCancel: typeof recreateConfirmationCodeCancel;
  callVerifyConfirmationCodeExpireCancel: typeof verifyConfirmationCodeExpireCancel;
  callVerifyConfirmationCodeErrorCancel: typeof verifyConfirmationCodeErrorCancel;
}

type States = ConfirmationCodeState & AuthTokenState;
type Props = States & DispatchProps;

const ConfirmationCode = ({
  loggedInFromConfirmationCode,
  callVefiryConfirmationCode,
  callConfirmationCodeRecreate,
  recreatablesAtFromConfirmationCode,
  confirmationCodeSendMailAddress,
  confirmationCodeSendPhoneNumber,
  callShowAlert,
  expiredFromConfirmationCodeRecreate,
  callConfirmationCodeRecreateExpireCancel,
  callVerifyConfirmationCodeExpireCancel,
  callVerifyConfirmationCodeErrorCancel,
  clearConfirmationCode,
}: Props) => {
  const navigate = useNavigate();
  const [validConfirmationCode, setValidConfirmationCode] = useState(false);

  let inputConfirmationCode: string;

  let viewConfirmationCodeSendMailAddress =
    confirmationCodeSendMailAddress ||
    localStorage.confirmationCodeSendMailAddress;
  if (viewConfirmationCodeSendMailAddress) {
    const splited = viewConfirmationCodeSendMailAddress.split(/@/, 2);
    splited[0] = splited[0].replace(/(?<=.{3})./g, '*');
    viewConfirmationCodeSendMailAddress = splited.join('@');
  }

  let viewConfirmationCodeSendPhoneNumber =
    confirmationCodeSendPhoneNumber ||
    localStorage.confirmationCodeSendPhoneNumber;
  if (confirmationCodeSendPhoneNumber) {
    const splited = confirmationCodeSendPhoneNumber.split('-');
    splited[1] = splited[1].replace(/\w/g, '*');
    viewConfirmationCodeSendPhoneNumber = splited.join('-');
  }

  const annotations = `${
    viewConfirmationCodeSendMailAddress || viewConfirmationCodeSendPhoneNumber
  }\nに確認コードをお送りしました。\n${
    confirmationCodeSendMailAddress ? 'メール' : 'SMS'
  }に記載された6桁の確認コードを\n入力してください。`.split('\n');

  const {seconds, minutes, restart} = useTimer({
    expiryTimestamp: moment().toDate(),
  });

  const active = seconds === 0 && minutes === 0;
  const waitSeconds = seconds > 0 ? `(${seconds}秒)` : '';

  if (clearConfirmationCode) {
    callVerifyConfirmationCodeErrorCancel();
  }
  const recreatablesAt =
    recreatablesAtFromConfirmationCode || localStorage.recreatablesAt;
  useEffect(() => {
    if (recreatablesAt) {
      restart(moment(recreatablesAt).toDate());
    } else if (recreatablesAtFromConfirmationCode) {
      restart(moment(recreatablesAtFromConfirmationCode).toDate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (expiredFromConfirmationCodeRecreate) {
      callConfirmationCodeRecreateExpireCancel();
      callVerifyConfirmationCodeExpireCancel();
      navigate('/login');
    }

    if (loggedInFromConfirmationCode) {
      navigate('/');
    }
  }, [
    loggedInFromConfirmationCode,
    expiredFromConfirmationCodeRecreate,
    recreatablesAt,
    recreatablesAtFromConfirmationCode,
    callConfirmationCodeRecreateExpireCancel,
    callVerifyConfirmationCodeExpireCancel,
    navigate,
    restart,
  ]);

  return (
    <>
      <main id='confirmation-code'>
        <DocumentTitle pageNameInTitle='本人確認' />
        <div>
          <h2>本人確認のお願い</h2>
          {annotations.map((annotation: string) => (
            <p key={annotation}>{annotation}</p>
          ))}
          <form>
            <ConfirmationCodeDialog
              clearConfirmationCode={clearConfirmationCode || false}
              setConfirmationCode={setConfirmationCode => {
                inputConfirmationCode = setConfirmationCode;
              }}
              setValid={setValidConfirmationCode}
            />

            <SubmitButton
              active={validConfirmationCode}
              onClick={() => {
                const pathname = '/';

                callVefiryConfirmationCode(inputConfirmationCode, {
                  routeOnSuccess: {pathname},
                });
              }}
            >
              本人確認する
            </SubmitButton>

            <MiniSubmitButton
              active={active}
              onClick={() => {
                callConfirmationCodeRecreate();
              }}
            >
              {`メールを再送信する ${waitSeconds}`}
            </MiniSubmitButton>
          </form>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: GlobalState): States => ({
  ...state.confirmationCode,
  ...state.authToken,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  callVefiryConfirmationCode: (confirmationCode: string, meta: Meta) =>
    dispatch(verifyConfirmationCode(confirmationCode, meta)),
  callConfirmationCodeRecreate: () => dispatch(recreateConfirmationCode()),
  callConfirmationCodeRecreateExpireCancel: () =>
    dispatch(recreateConfirmationCodeCancel()),
  callVerifyConfirmationCodeExpireCancel: () =>
    dispatch(verifyConfirmationCodeExpireCancel()),
  callVerifyConfirmationCodeErrorCancel: () =>
    dispatch(verifyConfirmationCodeErrorCancel()),
  callShowAlert: (config: AlertConfig) => dispatch(showAlert(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationCode);
