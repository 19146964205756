import {i18n} from '../../../locale/i18n';
import commaNumber from 'comma-number';
import moment from 'moment';
import {
  IApplicationSummary,
  ApplicationSummaryState,
} from '../../../reducers/applicationSummary';
import {GlobalState} from '../../../reducers/index';
import {connect} from 'react-redux';
import CompanySelector from '../../../components/parts/CompanySelector';
import {Dispatch} from 'redux';
import './UpperLimitAmount.scss';
import fetchApplicationSummary from '../../../actions/fetchApplicationSummary';
import fetchBalanceHistories from '../../../actions/fetchBalanceHistories';
import {FranchiseStoresState} from '../../../reducers/franchiseStores';
import {NavLink} from 'react-router-dom';

import {getOemName} from '../../../util/oem';

interface DispatchProps {
  reload: (employeeId: number) => void;
}

type StatesFromReducer = IApplicationSummary &
  FranchiseStoresState &
  ApplicationSummaryState;
type Props = StatesFromReducer & DispatchProps;

const Header = ({
  latestDeadlineDate,
  upperLimitAmount,
  selected,
  reload,
  loading,
}: Props) => {
  const oemName = getOemName();

  return (
    <header className='navbar'>
      <section className='forms'>
        <NavLink to='/'>
          <img
            alt='logo'
            className='logo'
            src={`/img/home/${oemName}/logo.svg`}
          />
        </NavLink>

        <div className='limit'>
          <div className='limit-date'>
            <p>{i18n.t('application.requestLimit')}</p>
            {!loading &&
            latestDeadlineDate &&
            moment(latestDeadlineDate.replace(/Z$/, '')).unix() > 0 ? (
              <p>{moment(latestDeadlineDate).format('M月D日')}まで</p>
            ) : (
              <p />
            )}
          </div>

          <div className='upper-limit'>
            <span className='amount'>
              {loading ? '-- , ---' : commaNumber(upperLimitAmount)}
            </span>
            <span className='yen'>{i18n.t('common.yen')}</span>
          </div>
        </div>

        <div className='reload'>
          <p>{i18n.t('common.update')}</p>
          <img
            alt='reload'
            onClick={() => {
              if (selected) {
                reload(selected.employeeID);
              }
            }}
            src={
              loading
                ? '/img/side-bar/loading.gif'
                : `/img/home/${oemName}/reload.svg`
            }
          />
        </div>
      </section>
      <section className='right-menu'>
        <div>
          <p>{i18n.t('notification.company')}</p>
          <CompanySelector />
        </div>
      </section>
    </header>
  );
};

const mapStateToProps = (state: GlobalState): StatesFromReducer => ({
  ...state.franchiseStores,
  ...state.applicationSummary.applicationSummary,
  ...state.applicationSummary,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  reload: employeeID => {
    dispatch(fetchApplicationSummary(employeeID));
    dispatch(fetchBalanceHistories(null, employeeID));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
