import {useState, useEffect, createRef, RefObject, ChangeEvent} from 'react';
import {connect} from 'react-redux';
import {GlobalState} from '../../reducers/index';
import {PinCodeState} from '../../reducers/pinCode';
import {PinCodeFragmentState} from '../../reducers/pinCodeFragment';
import convertToHarfWidth from '../../util/convertToHarfWidth';
import './Pincodes.scss';
import {detect} from 'detect-browser';

interface Props {
  setPincodes: (pincode: string) => void;
  clearPincodes?: (clearPincode: () => void) => void;
  setValid: (valid: boolean) => void;
  setPinCodeFragment?: () => void;
}

const Pincodes = ({
  setPincodes,
  clearPincodes,
  setValid,
  setPinCodeFragment,
  hasError,
  updated,
}: Props & PinCodeState & PinCodeFragmentState) => {
  const valueAndSetters: any[] = [
    [...useState(''), ...useState(false), ...useState(false), createRef()],
    [...useState(''), ...useState(false), ...useState(false), createRef()],
    [...useState(''), ...useState(false), ...useState(false), createRef()],
    [...useState(''), ...useState(false), ...useState(false), createRef()],
  ];

  const valid =
    valueAndSetters[0][0] &&
    valueAndSetters[1][0] &&
    valueAndSetters[2][0] &&
    valueAndSetters[3][0];

  useEffect(() => {
    setValid(!!valid);
  }, [valid, setValid]);

  useEffect(() => {
    if (clearPincodes) {
      clearPincodes(() => {
        valueAndSetters[0][1]('');
        valueAndSetters[1][1]('');
        valueAndSetters[2][1]('');
        valueAndSetters[3][1]('');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearPincodes]);

  const inputPincode =
    valueAndSetters[3][0].toString() +
    valueAndSetters[2][0].toString() +
    valueAndSetters[1][0].toString() +
    valueAndSetters[0][0].toString();

  useEffect(() => {
    setPincodes(inputPincode);
  }, [inputPincode, setPincodes]);

  let previousRef: RefObject<HTMLInputElement> | null;
  const nextRefs = {};
  const inputs = valueAndSetters
    .map(([value, setter, _, setFocused, clicked, setClicked, ref], i) => {
      const element = ((
        closurePreviousRef,
        closureI,
        closureClicked,
        closureSetClicked
      ) => {
        const browser = detect();
        const isChrome = browser && browser.name === 'chrome';
        return (
          <div key={i}>
            {!closureClicked && value ? (
              <div onClick={() => closureSetClicked(true)}>
                <p>●</p>
              </div>
            ) : (
              <p />
            )}
            <input
              style={{
                left: isChrome ? '-28.5px' : '-35.5px',
              }}
              key={`input-number-${closureI}`}
              inputMode='numeric'
              type='text'
              maxLength={1}
              ref={ref}
              value={value}
              onInput={(e: ChangeEvent<HTMLInputElement>) => {
                if (new Date().getTime() - updated < 100) {
                  return;
                }

                const inputValue = convertToHarfWidth(e.target.value);
                if (!inputValue.match(/^$/) && !inputValue.match(/^\d+$/)) {
                  setter('');
                  return;
                }
                closureSetClicked(false);
                if (!inputValue) {
                  setter('');
                  if (
                    nextRefs[`input-number-${closureI}`] &&
                    nextRefs[`input-number-${closureI}`].current
                  ) {
                    nextRefs[`input-number-${closureI}`].current.focus();
                  }
                  return;
                }
                let inputNumber = parseInt(inputValue, 10);
                if (inputNumber > 10) {
                  inputNumber = parseInt(
                    inputNumber.toString().substr(-1, 1),
                    10
                  );
                }

                // 全角から半角に変換する際に、IMEが閉じて空文字が入力される。
                // 苦渋だが、入力が完了した瞬間に空文字での登録を不可能にする
                if (setPinCodeFragment) {
                  setPinCodeFragment();
                }
                setter(inputNumber + '');
                if (closurePreviousRef == null) return;
                if (closurePreviousRef.current == null) return;
                const current = closurePreviousRef.current;
                current.focus();
              }}
              onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                closureSetClicked(true);
                setFocused(true);
              }}
              onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                closureSetClicked(false);
                setFocused(false);
              }}
            />
          </div>
        );
      })(previousRef, i, clicked, setClicked);
      nextRefs[`input-number-${i - 1}`] = ref;
      previousRef = ref;
      return element;
    })
    .reverse();

  return (
    <div id='pincodes' className={hasError ? 'error ' : ''}>
      {inputs}
    </div>
  );
};

const mapStateToProps = (
  state: GlobalState
): PinCodeState & PinCodeFragmentState => ({
  ...state.pinCode,
  ...state.pinCodeFragment,
});

export default connect(mapStateToProps)(Pincodes);
