import {i18n} from '../../locale/i18n';
import SubmitButton from '../../components/parts/SubmitButton';
import CancelButton from '../../components/parts/CancelButton';
import Modal from 'react-modal';
import './TermsOfUse.scss';

import Terms from '../settings/Terms';
interface Props {
  isOpened: boolean;
  onAgree: () => void;
  onCancel: () => void;
}

const TermsOfUse = ({isOpened, onAgree, onCancel}: Props) => {
  return (
    <div className='terms-of-use-modal'>
      <Modal
        ariaHideApp={false}
        isOpen={isOpened}
        onRequestClose={() => {
          onCancel();
        }}
        contentLabel='Settings'
        overlayClassName='overlay'
      >
        <Terms noDetails={true} />
        <div className='terms-of-use-modal__button-field'>
          <CancelButton
            onClick={() => {
              onCancel();
            }}
          >
            {i18n.t('settings.disagreeWithTheTerms')}
          </CancelButton>
          <SubmitButton
            onClick={() => {
              onAgree();
            }}
            active={true}
          >
            {i18n.t('settings.agreeWithTheTerms')}
          </SubmitButton>
        </div>
      </Modal>
    </div>
  );
};

export default TermsOfUse;
