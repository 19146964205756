import ReportError from './reportError';
export const FETCH_FRANCHISE_STORES = 'FETCH_FRANCHISE_STORES';
export const FETCH_FRANCHISE_STORES_SUCCESS = 'FETCH_FRANCHISE_STORES_SUCCESS';
export const FETCH_FRANCHISE_STORES_ERROR = 'FETCH_FRANCHISE_STORES_ERROR';

export interface FranchiseStore {
  companyCode: string;
  franchiseStoreName: string;
  totalAmount: number;
  employeeID: number;
  allowEmployeeEditAccount: boolean;
}

const fetchFranchiseStores = () => ({
  type: FETCH_FRANCHISE_STORES as typeof FETCH_FRANCHISE_STORES,
});

interface FetchFranchiseStoresSuccess {
  type: typeof FETCH_FRANCHISE_STORES_SUCCESS;
  payload: {
    franchiseStores: FranchiseStore[];
  };
}

export type FetchFranchiseStoresAction =
  | ReturnType<typeof fetchFranchiseStores>
  | FetchFranchiseStoresSuccess
  | ReportError<typeof FETCH_FRANCHISE_STORES_ERROR>;

export default fetchFranchiseStores;
