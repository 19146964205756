import Header from '../../components/parts/Headers/OnlyLogo';
import './UsageSevenAtm.scss';
import './../settings/PrivacyPolicyAndTerms.scss';
import {i18n} from '../../locale/i18n';
import DocumentTitle from '../parts/DocumentTitle';

const Component = () => {
  const liElements = [
    {
      h4: 'セブン銀行ATM トップ画面',
      p: i18n.t('howToCash.selectTop'),
    },
    {
      h4: '提携先コード入力',
      p: '『提携先コード』を入力し、確認ボタンを押します。',
    },
    {
      h4: 'お客様番号入力',
      p: '『お客様番号』を入力し、確認ボタンを押します。',
    },
    {
      h4: '確認番号入力',
      p: '『確認番号』を入力し、確認ボタンを押します。',
    },
    {
      h4: '取引内容確認',
      p: '取引内容と金額をご確認の上、確認ボタンを押します。',
    },
    {
      h4: '紙幣の受け取り',
      p: 'ATMから紙幣をお受け取りください。',
    },
    {
      h4: '明細票の受け取り',
      p: i18n.t('howToCash.takeReceipt'),
    },
  ];
  return (
    <>
      <DocumentTitle pageNameInTitle='セブンATMでの受取方法' />
      <div id='usage-seven-atm' className='privacy-policy-and-terms'>
        <Header />
        <section>
          <main>
            <link
              href='https://fonts.googleapis.com/css?family=Manrope'
              rel='stylesheet'
            />
            <h2>
              <div>
                <img alt='atm' src='/img/side-bar/seven-atm/atm.png' />
                <p>セブン銀行ATMでの受取方法</p>
              </div>
            </h2>

            <h3>
              前払い申請時に「セブン銀行ATMで受取り」を選択した場合の受け取り方法です。
            </h3>
            <ul>
              {liElements.map(({h4, p}, i) => (
                <li key={i}>
                  <div>
                    <h4>
                      {i + 1}.{h4}
                    </h4>
                    <p>{p}</p>
                  </div>
                  <div>
                    <img
                      alt={`atm-desciption-${i + 1}`}
                      src={`/img/side-bar/seven-atm/atm-description-${
                        i + 1
                      }.png`}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </main>
        </section>
        <footer>© Metaps Payment Inc.</footer>
      </div>
    </>
  );
};

export default Component;
