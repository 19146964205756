import {post} from './http';

const postRequestPasswordResetToken = (
  customerNo: string,
  instanceID: string
) => {
  const oemCode = process.env.REACT_APP_OEM_CODE;
  const params = {customerNo, oemCode};
  return post('/password/token/customer_no', params, instanceID);
};

export default postRequestPasswordResetToken;
