import './OnlyLogo.scss';
import {NavLink} from 'react-router-dom';
import {getOemName} from '../../../util/oem';

const Component = () => {
  const oemName = getOemName();
  return (
    <header className='only-logo'>
      <div>
        <NavLink to='/'>
          <img alt='logo' src={`/img/parts/headers/${oemName}/logo.svg`} />
        </NavLink>
      </div>
    </header>
  );
};

export default Component;
