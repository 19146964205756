import {put, takeEvery, call, select} from 'redux-saga/effects';
import {AUTHENTICATION_FAILURE_COMPLETE} from '../actions/authFailure';
import getApplicationSummary from '../api/getApplicationSummary';
import fetchApplicationSummary, {
  ApplicationSummary,
  FETCH_APPLICATION_SUMMARY,
  FETCH_APPLICATION_SUMMARY_SUCCESS,
  FETCH_APPLICATION_SUMMARY_ERROR,
} from '../actions/fetchApplicationSummary';
import changePaymentMethod, {
  setPaymentMethod,
  setAmount,
  changePaymentMethodSuccess,
  CHANGE_PAYMENT_METHOD,
  CHANGE_PAYMENT_METHOD_ERROR,
} from '../actions/changePaymentMethod';
import applyPrepayment, {
  APPLY_PREPAYMENT,
  APPLY_PREPAYMENT_SUCCESS,
  APPLY_PREPAYMENT_ERROR,
} from '../actions/applyPrepayment';
import postPrepaymentApplication from '../api/postPrepaymentApplication';
import reportError from './reportError';
import {CLOSE_APPLICATION_RESULT} from '../actions/closeApplicationResult';
import {FETCH_FRANCHISE_STORES} from '../actions/fetchFranchiseStores';
import localStorage from '../util/localStorage';
import {SET_ANALYTICS} from '../actions/setAnalytics';
import {IApplicationFee} from '../reducers/applicationSummary';

function* handleFetchApplicationSummary(
  action: ReturnType<typeof fetchApplicationSummary>
) {
  try {
    const authToken: string = yield localStorage.authToken;
    const resp: {data: ApplicationSummary[]} = yield call(
      getApplicationSummary,
      authToken,
      action.payload.employeeID
    );

    yield put({
      type: FETCH_APPLICATION_SUMMARY_SUCCESS,
      payload: {applicationSummary: resp.data},
    });

    const paymentMethodIndex: number = yield select(
      state => state.applicationSummary.applicationSummary.paymentMethodIndex
    );

    yield put({
      type: CHANGE_PAYMENT_METHOD,
      payload: {paymentMethodIndex},
    });
  } catch (e: any) {
    if (e.response && e.response.status === 403) {
      yield put({
        type: AUTHENTICATION_FAILURE_COMPLETE,
      });
      return;
    }

    yield reportError(e, FETCH_APPLICATION_SUMMARY_ERROR);
  }
}

function* handleChangePaymentMethod(
  action: ReturnType<typeof changePaymentMethod>
) {
  try {
    const paymentMethodIndex = action.payload.paymentMethodIndex;

    yield put(setPaymentMethod({paymentMethodIndex}));
    yield put(setAmount());
    yield put(changePaymentMethodSuccess());
  } catch (e: any) {
    yield reportError(e, CHANGE_PAYMENT_METHOD_ERROR);
  }
}

function* handleApplyPrepayment(action: ReturnType<typeof applyPrepayment>) {
  try {
    const authToken: string = yield localStorage.authToken;
    const paymentMethod = action.payload.paymentMethodIndex + 1;

    const applicationFees: IApplicationFee[] = yield select(state => {
      try {
        return state.applicationSummary.applicationSummary.applicationFees;
      } catch (e) {
        return null;
      }
    });

    let applicationFeeVersion: string | null = null;
    if (applicationFees) {
      const applicationFee = applicationFees.find(
        (element: IApplicationFee) => element.paymentMethod === paymentMethod
      );
      if (!applicationFee) {
        throw new Error(
          `paymentMethod: ${paymentMethod} の applicationFee がありません。`
        );
      }
      applicationFeeVersion = applicationFee.version;
    }

    const resp: {data: {applicationNumber: number}} = yield call(
      postPrepaymentApplication,
      paymentMethod,
      action.payload.amount,
      applicationFeeVersion,
      authToken,
      action.payload.employeeID
    );

    yield put({
      type: SET_ANALYTICS,
      payload: {
        application_number: resp.data.applicationNumber,
        amount: action.payload.amount,
        paymentMethod,
      },
    });

    yield put({
      type: APPLY_PREPAYMENT_SUCCESS,
      payload: {
        applicationResult: resp.data,
        paymentMethodIndex: action.payload.paymentMethodIndex,
      },
    });
  } catch (e: any) {
    if (e.response && e.response.status === 403) {
      yield put({
        type: AUTHENTICATION_FAILURE_COMPLETE,
      });
      return;
    }

    yield reportError(e, APPLY_PREPAYMENT_ERROR);
  }
}

function* handleCloseApplicationResult() {
  yield put({
    type: FETCH_FRANCHISE_STORES,
  });
}

function* applicationSummarySaga() {
  yield takeEvery(FETCH_APPLICATION_SUMMARY, handleFetchApplicationSummary);
  yield takeEvery(CHANGE_PAYMENT_METHOD, handleChangePaymentMethod);
  yield takeEvery(APPLY_PREPAYMENT, handleApplyPrepayment);
  yield takeEvery(CLOSE_APPLICATION_RESULT, handleCloseApplicationResult);
}

export default applicationSummarySaga;
