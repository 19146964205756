import './List.scss';
import {i18n} from '../../locale/i18n';

import moment from 'moment';
import {useState, useRef} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {GlobalState} from '../../reducers/index';
import SideBar, {selectedPages} from '../../components/parts/SideBar';
import {NotificationsState} from '../../reducers/notifications';
import {NotificationItem} from '../../actions/fetchNotifications';
import Header from '../../components/parts/Headers/UpperLimitAmount';
import fetchCriaNotifications from '../../actions/fetchCriaNotifications';
import DocumentTitle from '../parts/DocumentTitle';
import NotificationDetail from './Detail';
import RestrictedPage from '../auth/RestrictedPage';

type StatesFromReducer = {
  companyNotifications: NotificationsState;
  criaNotifications: NotificationsState;
};

interface DispatchProps {
  loadNextCriaNotification: typeof fetchCriaNotifications;
}

enum tabs {
  // notifications,
  informations,
}

const liHeight = 90;

export const calcPublishInterval = (
  notificationItem: NotificationItem,
  currentUnixtime: number
) => {
  if (!notificationItem) {
    return '';
  }
  const unixtime = moment(notificationItem.publishedAt).unix();
  let diff = currentUnixtime - unixtime;
  if (diff > 3600 * 24 * 365) {
    let n = 0;
    while (diff > 3600 * 24 * 365) {
      n++;
      diff -= 3600 * 24 * 365;
    }
    // n年前
    return `${n}年前`;
  }
  if (diff >= 3600 * 24 * 7) {
    let n = 0;
    while (diff >= 3600 * 24 * 7) {
      n++;
      diff -= 3600 * 24 * 7;
    }
    return `${n}週間前`;
  }
  if (diff >= 3600 * 24) {
    let n = 0;
    while (diff >= 3600 * 24) {
      n++;
      diff -= 3600 * 24;
    }

    return `${n}日前`;
  }

  if (diff >= 3600) {
    let n = 0;
    while (diff >= 3600) {
      n++;
      diff -= 3600;
    }

    return `${n}時間前`;
  }

  return 'たった今';
};

export const NotificationList = ({
  companyNotifications,
  criaNotifications,
  loadNextCriaNotification,
}: DispatchProps & StatesFromReducer) => {
  const [currentTab, setCurrentTab] = useState(tabs.informations);

  const [detailNotification, setDetailNotification] =
    useState<NotificationItem | null>(null);

  const {notificationItems, cursor, loading} =
    currentTab === tabs.informations ? criaNotifications : companyNotifications;

  const currentUnixtime = moment().unix();
  const ref = useRef(null);
  return (
    <RestrictedPage>
      <DocumentTitle pageNameInTitle={i18n.t('notification.notice')} />
      <Header />
      <main id='notification-list'>
        <link
          href='https://fonts.googleapis.com/css?family=Manrope'
          rel='stylesheet'
        />
        <section>
          <section>
            <ul className='tabs'>
              <li
                onClick={() => {
                  setCurrentTab(tabs.informations);
                }}
                className={currentTab === tabs.informations ? 'selected' : ''}
              >
                <p>{i18n.t('alert.notice')}</p>
                <div />
              </li>
            </ul>
            <section className='notifications'>
              <ul
                ref={ref}
                className='notifications'
                onLoad={e => {
                  if (!ref || !ref.current) {
                    return;
                  }
                  const innerHeight = (notificationItems.length - 1) * liHeight;
                  const visibleHeight = (
                    ref.current as HTMLElement
                  ).getBoundingClientRect().height;
                  const currentScrollTop = (ref.current as HTMLElement)
                    .scrollTop;
                  if (
                    !loading &&
                    cursor &&
                    visibleHeight + currentScrollTop > innerHeight
                  ) {
                    loadNextCriaNotification(cursor);
                  }
                }}
                onScroll={e => {
                  const innerHeight = (notificationItems.length - 1) * liHeight;
                  const visibleHeight = (
                    e.target as HTMLElement
                  ).getBoundingClientRect().height;
                  const currentScrollTop = (e.target as HTMLElement).scrollTop;
                  if (
                    !loading &&
                    cursor &&
                    visibleHeight + currentScrollTop > innerHeight
                  ) {
                    loadNextCriaNotification(cursor);
                  }
                }}
              >
                {notificationItems.length === 0 ? (
                  <div className='no-contents'>
                    {i18n.t('notification.emptyCria')}
                  </div>
                ) : (
                  <></>
                )}
                {notificationItems.map((notificationItem, i) => {
                  return (
                    <li
                      onClick={() => {
                        setDetailNotification(notificationItem);
                      }}
                      key={notificationItem.publishedAt + i}
                    >
                      <img
                        alt='yen-mark'
                        src='/img/notification/yen-mark.svg'
                      />
                      <div>
                        <h3>{notificationItem.title}</h3>
                      </div>
                      <p className='date'>
                        {calcPublishInterval(notificationItem, currentUnixtime)}
                      </p>
                    </li>
                  );
                })}
                {loading ? (
                  <li className='loading'>
                    <img alt='loading' src='/img/side-bar/loading.gif' />
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </section>
          </section>
          <SideBar selectedPage={selectedPages.notification} />
        </section>
        <footer>© Metaps Payment Inc.</footer>
      </main>
      <NotificationDetail
        notification={detailNotification}
        closeModal={() => setDetailNotification(null)}
      />
    </RestrictedPage>
  );
};

const mapStateToProps = (state: GlobalState): StatesFromReducer => ({
  companyNotifications: state.companyNotifications,
  criaNotifications: state.criaNotifications,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadNextCriaNotification: cursor => dispatch(fetchCriaNotifications(cursor)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
