import {post} from './http';

const postAuthToken = (
  customerNo: string,
  password: string,
  instanceID: string
) => {
  const oemCode = process.env.REACT_APP_OEM_CODE;
  const params = {customerNo, oemCode, password};
  return post('/auth_token/customer_no', params, null, instanceID);
};

export default postAuthToken;
