const f = (inputString: string) => {
  const replaceList = [
    ['あ', 'ア', 'ｱ'],
    ['い', 'イ', 'ｲ'],
    ['う', 'ウ', 'ｳ'],
    ['え', 'エ', 'ｴ'],
    ['お', 'オ', 'ｵ'],
    ['か', 'カ', 'ｶ'],
    ['き', 'キ', 'ｷ'],
    ['く', 'ク', 'ｸ'],
    ['け', 'ケ', 'ｹ'],
    ['こ', 'コ', 'ｺ'],
    ['さ', 'サ', 'ｻ'],
    ['し', 'シ', 'ｼ'],
    ['す', 'ス', 'ｽ'],
    ['せ', 'セ', 'ｾ'],
    ['そ', 'ソ', 'ｿ'],
    ['た', 'タ', 'ﾀ'],
    ['ち', 'チ', 'ﾁ'],
    ['つ', 'ツ', 'ﾂ'],
    ['て', 'テ', 'ﾃ'],
    ['と', 'ト', 'ﾄ'],
    ['な', 'ナ', 'ﾅ'],
    ['に', 'ニ', 'ﾆ'],
    ['ぬ', 'ヌ', 'ﾇ'],
    ['ね', 'ネ', 'ﾈ'],
    ['の', 'ノ', 'ﾉ'],
    ['は', 'ハ', 'ﾊ'],
    ['ひ', 'ヒ', 'ﾋ'],
    ['ふ', 'フ', 'ﾌ'],
    ['へ', 'ヘ', 'ﾍ'],
    ['ほ', 'ホ', 'ﾎ'],
    ['ま', 'マ', 'ﾏ'],
    ['み', 'ミ', 'ﾐ'],
    ['む', 'ム', 'ﾑ'],
    ['め', 'メ', 'ﾒ'],
    ['も', 'モ', 'ﾓ'],
    ['や', 'ヤ', 'ﾔ'],
    ['ゆ', 'ユ', 'ﾕ'],
    ['よ', 'ヨ', 'ﾖ'],
    ['ら', 'ラ', 'ﾗ'],
    ['り', 'リ', 'ﾘ'],
    ['る', 'ル', 'ﾙ'],
    ['れ', 'レ', 'ﾚ'],
    ['ろ', 'ロ', 'ﾛ'],
    ['わ', 'ワ', 'ﾜ'],
    ['を', 'ヲ', 'ｦ'],
    ['ん', 'ン', 'ﾝ'],
    ['が', 'ガ', 'ｶﾞ'],
    ['ぎ', 'ギ', 'ｷﾞ'],
    ['ぐ', 'グ', 'ｸﾞ'],
    ['げ', 'ゲ', 'ｹﾞ'],
    ['ご', 'ゴ', 'ｺﾞ'],
    ['ざ', 'ザ', 'ｻﾞ'],
    ['じ', 'ジ', 'ｼﾞ'],
    ['ず', 'ズ', 'ｽﾞ'],
    ['ぜ', 'ゼ', 'ｾﾞ'],
    ['ぞ', 'ゾ', 'ｿﾞ'],
    ['だ', 'ダ', 'ﾀﾞ'],
    ['ぢ', 'ヂ', 'ﾁﾞ'],
    ['づ', 'ヅ', 'ﾂﾞ'],
    ['で', 'デ', 'ﾃﾞ'],
    ['ど', 'ド', 'ﾄﾞ'],
    ['ば', 'バ', 'ﾊﾞ'],
    ['び', 'ビ', 'ﾋﾞ'],
    ['ぶ', 'ブ', 'ﾌﾞ'],
    ['べ', 'ベ', 'ﾍﾞ'],
    ['ぼ', 'ボ', 'ﾎﾞ'],
    ['ぱ', 'パ', 'ﾊﾟ'],
    ['ぴ', 'ピ', 'ﾋﾟ'],
    ['ぷ', 'プ', 'ﾌﾟ'],
    ['ぺ', 'ペ', 'ﾍﾟ'],
    ['ぽ', 'ポ', 'ﾎﾟ'],
    ['ゔ', 'ヴ', 'ｳﾞ'],
    ['ぁ', 'ァ', 'ｱ'],
    ['ぃ', 'ィ', 'ｲ'],
    ['ぅ', 'ゥ', 'ｳ'],
    ['ぇ', 'ェ', 'ｴ'],
    ['ぉ', 'ォ', 'ｵ'],
    ['っ', 'ッ', 'ﾂ'],
    ['ゃ', 'ャ', 'ﾔ'],
    ['ゅ', 'ュ', 'ﾕ'],
    ['ょ', 'ョ', 'ﾖ'],
  ];
  replaceList.forEach(([from1, from2, to]) => {
    while (inputString.match(from1)) {
      inputString = inputString.replace(from1, to);
    }
    while (inputString.match(from2)) {
      inputString = inputString.replace(from2, to);
    }
  });

  // 各種ハイフン系の変換
  ['－', '‐', '−', '—', 'ー'].forEach(from => {
    while (inputString.match(from)) {
      inputString = inputString.replace(from, '-');
    }
  });

  inputString = inputString.replace(/[\uFF01-\uFF5D]/g, ch => {
    return String.fromCharCode(ch.charCodeAt(0) - (0xff01 - 0x0021));
  });

  return inputString;
};
export default f;
