import ReportError from './reportError';
import {NotificationItem} from './fetchNotifications';

export const FETCH_CRIA_NOTIFICATIONS = 'FETCH_CRIA_NOTIFICATIONS';
export const FETCH_CRIA_NOTIFICATIONS_SUCCESS =
  'FETCH_CRIA_NOTIFICATIONS_SUCCESS';
export const FETCH_CRIA_NOTIFICATIONS_ERROR = 'FETCH_CRIA_NOTIFICATIONS_ERROR';

const fetchCriaNotifications = (cursor: string | null) => ({
  type: FETCH_CRIA_NOTIFICATIONS as typeof FETCH_CRIA_NOTIFICATIONS,
  payload: {
    cursor,
  },
});

interface FetchCriaNotificationsSuccess {
  type: typeof FETCH_CRIA_NOTIFICATIONS_SUCCESS;
  payload: {
    notificationItems: NotificationItem[];
    cursor: string | null;
  };
}

export type FetchCriaNotificationsAction =
  | ReturnType<typeof fetchCriaNotifications>
  | FetchCriaNotificationsSuccess
  | ReportError<typeof FETCH_CRIA_NOTIFICATIONS_ERROR>;

export default fetchCriaNotifications;
