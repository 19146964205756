import ReportError from './reportError';

export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_ERROR = 'FETCH_USER_INFO_ERROR';

const fetchUserInfo = (employeeID: number | null) => ({
  type: FETCH_USER_INFO as typeof FETCH_USER_INFO,
  payload: {employeeID},
});

export interface UserInfo {
  accountName: string;
  accountNumber: string;
  accountType: number;
  applicationDisabled: boolean;
  appUserKey: number;
  companyCode: string;
  customerNo: string;
  employeeCode: string;
  financialInstitutionName: string;
  financialInstitutionCode: string;
  branchName: string;
  branchCode: string;
  franchiseStoreName: string;
  givenName: string;
  mailAddress: string;
  phoneNumber: string;
  surname: string;
}

export interface FetchUserInfoSuccess {
  type: typeof FETCH_USER_INFO_SUCCESS;
  payload: {
    user: UserInfo;
  };
}

export type FetchUserInfoAction =
  | ReturnType<typeof fetchUserInfo>
  | FetchUserInfoSuccess
  | ReportError<typeof FETCH_USER_INFO_ERROR>;

export default fetchUserInfo;
