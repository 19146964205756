export const CLOSE_ALERT = 'CLOSE_ALERT';

const closeAlert = () => ({
  type: CLOSE_ALERT as typeof CLOSE_ALERT,
  payload: {},
});

export type CloseAlertAction = ReturnType<typeof closeAlert>;

export default closeAlert;
