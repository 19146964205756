import {i18n} from '../../locale/i18n';
import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import './Home.scss';
import {GlobalState} from '../../reducers/index';
import {IApplicationSummary} from '../../reducers/applicationSummary';
import {FranchiseStoresState} from '../../reducers/franchiseStores';
import {UserInfoState} from '../../reducers/userInfo';
import commaNumber from 'comma-number';
import Confirm from './Confirm';
import Pincode from './Pincode';
import ResultBank from './Result/Bank';
import ResultSeven from './Result/Seven';
import applyPrepayment from '../../actions/applyPrepayment';
import fetchBalanceHistories from '../../actions/fetchBalanceHistories';
import SideBar, {selectedPages} from '../../components/parts/SideBar';
import changePaymentMethod from '../../actions/changePaymentMethod';
import Header from '../../components/parts/Headers/UpperLimitAmount';
import DocumentTitle from '../parts/DocumentTitle';
import {sprintf} from 'sprintf-js';
import RestrictedPage from '../auth/RestrictedPage';
import {detect} from 'detect-browser';
import {getOemName} from '../../util/oem';

declare module 'comma-number';

interface DispatchProps {
  apply: typeof applyPrepayment;
}

type StatesFromReducer = IApplicationSummary &
  FranchiseStoresState &
  UserInfoState;
type Props = StatesFromReducer & DispatchProps;

export const Home = ({
  endDate,
  upperLimitAmount,
  selected,
  apply,
  maxTransferAmount,
  user,
  applicationFees,
  bankAccount,
  paymentMethods,
}: Props) => {
  const paymentMethodsHash = {};
  (paymentMethods || []).forEach(
    selectablePaymentMethod =>
      (paymentMethodsHash[selectablePaymentMethod] = true)
  );

  const allowSevenBankTransfer = paymentMethodsHash['2'];
  const allowBankTransfer = paymentMethodsHash['1'];

  const [paymentMethod, setPaymentMethod] = useState(0);

  const applicationFee =
    applicationFees.length > 1
      ? applicationFees[paymentMethod]
      : applicationFees[0];

  const totalFee = applicationFee ? applicationFee.totalFee : 0;

  const inputMaxValue = Math.floor(maxTransferAmount / 1000);
  let enableInputUpperLimitAmount = parseInt(
    (upperLimitAmount - totalFee) / 1000 + '',
    10
  );
  if (enableInputUpperLimitAmount >= inputMaxValue) {
    enableInputUpperLimitAmount = inputMaxValue;
  }

  useEffect(() => {
    if (allowSevenBankTransfer && !allowBankTransfer) {
      setPaymentMethod(1);
      return;
    }
    if (!allowSevenBankTransfer && allowBankTransfer) {
      setPaymentMethod(0);
      return;
    }
    let paymentMethodIndex: number | null = null;
    applicationFees.forEach((row, i) => {
      if (row.paymentMethod === 1 && allowBankTransfer) {
        paymentMethodIndex = i;
      }
      if (
        !paymentMethodIndex &&
        row.paymentMethod === 2 &&
        allowSevenBankTransfer
      ) {
        paymentMethodIndex = i;
      }
    });
    if (paymentMethodIndex) {
      setPaymentMethod(paymentMethodIndex);
    }
  }, [allowBankTransfer, allowSevenBankTransfer, applicationFees]);

  const [amount, setAmount] = useState(enableInputUpperLimitAmount);
  const [confirmOpened, setConfirmOpened] = useState(false);
  const [pincodeOpened, setPincodeOpened] = useState(false);
  const editable = upperLimitAmount > 1000 + totalFee;

  const inputStyle: {marginTop: string} = {marginTop: '12px'};
  const browser = detect();
  switch (browser && browser.name) {
    case 'firefox':
      inputStyle.marginTop = '13px';
      break;
    default:
      break;
  }

  useEffect(() => {
    setAmount(enableInputUpperLimitAmount);
  }, [upperLimitAmount, enableInputUpperLimitAmount]);

  if (!applicationFee) {
    return <RestrictedPage />;
  }
  const splitedReceiveAtSeven = i18n
    .t('application.receiveAtSeven')
    .split('\n');

  const oemName = getOemName();

  return (
    <RestrictedPage>
      <DocumentTitle
        pageNameInTitle={i18n.t('application.requestAdvancePayment')}
      />
      <Header />
      <main id='home'>
        <link
          href='https://fonts.googleapis.com/css?family=Manrope'
          rel='stylesheet'
        />
        <section>
          <section>
            <section className='forms'>
              <div className='form-number-and-submit'>
                <h2>{i18n.t('history.requestAmount')}</h2>
                {editable ? (
                  <div>
                    <div className='change-amount'>
                      <button
                        className='upper'
                        onClick={() => {
                          if (amount + 1 <= enableInputUpperLimitAmount) {
                            setAmount(amount + 1);
                          }
                        }}
                      >
                        <img alt='upper' src='/img/home/upper.svg' />
                      </button>
                      <button
                        className='lower'
                        onClick={() => {
                          if (amount > 1) {
                            setAmount(amount - 1);
                          }
                        }}
                      >
                        <img alt='lower' src='/img/home/lower.svg' />
                      </button>
                    </div>
                    <div className='change-amount mini'>
                      <button
                        className='upper'
                        onClick={() => {
                          if (amount + 1 <= enableInputUpperLimitAmount) {
                            setAmount(amount + 1);
                          }
                        }}
                      >
                        <img alt='upper-mini' src='/img/home/upper-mini.svg' />
                      </button>
                      <button
                        className='lower'
                        onClick={() => {
                          if (amount > 1) {
                            setAmount(amount - 1);
                          }
                        }}
                      >
                        <img alt='lower-mini' src='/img/home/lower-mini.svg' />
                      </button>
                    </div>

                    <div>
                      <div className='input'>
                        <img
                          alt='under-bar'
                          className='under-bar'
                          src='/img/home/under-bar.svg'
                        />
                        <input
                          type='text'
                          style={inputStyle}
                          onChange={e => {
                            let inputAmount = parseInt(
                              e.target.value.replace(/,/g, ''),
                              10
                            );
                            if (inputAmount >= inputMaxValue) {
                              inputAmount = inputMaxValue;
                            }
                            if (inputAmount > enableInputUpperLimitAmount) {
                              inputAmount = enableInputUpperLimitAmount;
                            }
                            if (!inputAmount.toString().match(/^\d+$/)) {
                              inputAmount = 0;
                            }
                            setAmount(inputAmount);
                          }}
                          value={commaNumber(amount)}
                        />
                      </div>
                      <div className='fee'>
                        ,
                        {sprintf(
                          '%03d',
                          totalFee
                            .toString()
                            .substring(
                              totalFee.toString().length - 3,
                              totalFee.toString().length
                            )
                        )}
                      </div>
                      <div className='yen'>{i18n.t('common.yen')}</div>
                    </div>
                  </div>
                ) : (
                  <div className='not-editable'>
                    <div>
                      <div className='input'>
                        <input disabled={true} type='text' value={0} />
                      </div>
                      <div className='yen'>{i18n.t('common.yen')}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className='includes-fee'>
                {i18n.t('application.withCharge')}
              </div>

              <div className='payment-methods'>
                <h2>{i18n.t('history.method')}</h2>
                {allowBankTransfer && allowSevenBankTransfer ? (
                  <>
                    <img
                      alt='checked'
                      className={paymentMethod === 1 ? 'seven-selected' : ''}
                      src={
                        oemName === 'cria'
                          ? `/img/home/${oemName}/checked.png`
                          : `/img/home/${oemName}/checked.svg`
                      }
                    />
                    <div>
                      <div
                        className={
                          paymentMethod === 1
                            ? 'payment-method-card-background-selected seven-selected'
                            : 'payment-method-card-background-selected'
                        }
                      />
                      <div
                        onClick={() => {
                          setPaymentMethod(0);
                        }}
                        className={
                          paymentMethod === 0
                            ? 'left payment-method-card selected'
                            : 'left payment-method-card'
                        }
                      >
                        <img
                          alt='bank'
                          src={
                            oemName === 'cria'
                              ? `/img/home/${oemName}/bank.png`
                              : `/img/home/${oemName}/bank.png`
                          }
                        />
                        <h3>{i18n.t('application.transferToBankAccount')}</h3>
                      </div>
                      <div
                        onClick={() => {
                          setPaymentMethod(1);
                        }}
                        className={
                          paymentMethod === 1
                            ? 'right payment-method-card selected'
                            : 'right payment-method-card'
                        }
                      >
                        <div className='images'>
                          <img alt='seven' src='/img/home/seven.png' />
                          <img alt='seven-atm' src='/img/home/seven-atm.png' />
                        </div>
                        <h3>{i18n.t('application.receiveAtSeven')}</h3>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {!allowBankTransfer && allowSevenBankTransfer ? (
                  <div>
                    <div className='payment-method-card payment-method-card-single seven'>
                      <div className='images'>
                        <img alt='seven' src='/img/home/seven.png' />
                        <img alt='seven-atm' src='/img/home/seven-atm.png' />
                      </div>
                      <div>
                        {splitedReceiveAtSeven.map((t: string) => (
                          <h3 key={t}>{t}</h3>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {allowBankTransfer && !allowSevenBankTransfer ? (
                  <div>
                    <div className='payment-method-card payment-method-card-single selected'>
                      <img
                        alt='bank'
                        src={
                          oemName === 'cria'
                            ? `/img/home/${oemName}/bank.png`
                            : `/img/home/${oemName}/bank.png`
                        }
                      />
                      <h3>{i18n.t('application.transferToBankAccount')}</h3>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className='fee-and-amount'>
                <div className='fee'>
                  <div>{i18n.t('common.charge')}</div>
                  <div>
                    <div>{editable ? -1 * totalFee : '--'}</div>
                    <div>{i18n.t('common.yen')}</div>
                  </div>
                </div>
                <hr />
                <div className='amount'>
                  <div>{i18n.t('common.withdrawalAmount')}</div>
                  <div>
                    <div>
                      {editable
                        ? commaNumber(
                            (amount - Math.floor(totalFee / 1000)) * 1000
                          )
                        : '--'}
                    </div>
                    <div>{i18n.t('common.yen')}</div>
                  </div>
                </div>
              </div>

              <button
                className={
                  editable && amount > 0 ? 'submit' : 'submit submit-ng'
                }
                disabled={!(allowSevenBankTransfer || allowBankTransfer)}
                onClick={() => {
                  if (editable && amount > 0) {
                    setConfirmOpened(true);
                  }
                }}
              >
                <img
                  alt='submit'
                  src={
                    editable &&
                    amount > 0 &&
                    (allowSevenBankTransfer || allowBankTransfer)
                      ? '/img/home/submit.png'
                      : '/img/home/submit-ng.png'
                  }
                />
                <p>{i18n.t('button.request')}</p>
              </button>
            </section>
          </section>
          <SideBar selectedPage={selectedPages.prepayment} />
        </section>
        <footer>© Metaps Payment Inc.</footer>

        <Confirm
          paymentMethod={paymentMethod}
          amount={(amount - Math.floor(totalFee / 1000)) * 1000 + totalFee}
          confirmOpened={confirmOpened}
          setConfirmOpened={setConfirmOpened}
          submit={() => {
            setPincodeOpened(true);
          }}
        />

        <Pincode
          pincodeOpened={pincodeOpened}
          setPincodeOpened={setPincodeOpened}
          submit={() => {
            const employeeID = selected ? selected.employeeID : null;
            apply(
              paymentMethod,
              (amount - Math.floor(totalFee / 1000)) * 1000 + totalFee,
              employeeID
            );
          }}
        />

        <ResultBank />
        <ResultSeven />
      </main>
    </RestrictedPage>
  );
};

const mapStateToProps = (state: GlobalState): StatesFromReducer => ({
  ...state.applicationSummary.applicationSummary,
  ...state.franchiseStores,
  ...state.userInfo,
  ...state.authToken,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  apply: (paymentMethodIndex, amount, employeeID) => {
    dispatch(changePaymentMethod(paymentMethodIndex));
    dispatch(fetchBalanceHistories(null, employeeID));
    return dispatch(applyPrepayment(paymentMethodIndex, amount, employeeID));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
