import ReportError from './reportError';

export const FETCH_AUTH_TOKEN = 'FETCH_AUTH_TOKEN';
export const FETCH_AUTH_TOKEN_SUCCESS = 'FETCH_AUTH_TOKEN_SUCCESS';
export const FETCH_AUTH_TOKEN_FAILURE = 'FETCH_AUTH_TOKEN_FAILURE';
export const FETCH_AUTH_TOKEN_ERROR = 'FETCH_AUTH_TOKEN_ERROR';
export const FETCH_CONFRIRATMION_CODE_TOKEN_SUCCESS =
  'FETCH_CONFRIRATMION_CODE_TOKEN_SUCCESS';

export interface Meta {
  routeOnSuccess: {pathname?: string};
  // routeOnSuccess: LocationDescriptorObject;
}

const fetchAuthToken = (customerNo: string, password: string, meta: Meta) => ({
  type: FETCH_AUTH_TOKEN as typeof FETCH_AUTH_TOKEN,
  payload: {
    customerNo,
    password,
  },
  meta,
});

export interface FetchAuthTokenSuccess {
  type: typeof FETCH_AUTH_TOKEN_SUCCESS;
  payload: {
    authToken: string;
    isAppPasswordSet: boolean;
  };
  meta: Meta;
}

interface FetchAuthTokenFailure {
  type: typeof FETCH_AUTH_TOKEN_FAILURE;
  payload: {
    message: string;
  };
}

export interface FetchConfirmationCodeTokenSuccess {
  type: typeof FETCH_CONFRIRATMION_CODE_TOKEN_SUCCESS;
  payload: {
    recreatablesAt: string;
    mailAddress: string;
    phoneNumber: string;
  };
  meta: Meta;
}

export type FetchAuthTokenAction =
  | ReturnType<typeof fetchAuthToken>
  | FetchAuthTokenSuccess
  | FetchAuthTokenFailure
  | FetchConfirmationCodeTokenSuccess
  | ReportError<typeof FETCH_AUTH_TOKEN_ERROR>;

export default fetchAuthToken;
