export const customerNoWithSpace = (x: string) => {
  if (!x) {
    return '';
  }
  switch (true) {
    case x.length > 4 && x.indexOf(' ') === -1:
      return x.slice(0, 4) + ' ' + x.slice(4);
    case x.length === 5:
      return x.slice(0, 4);
    default:
      return x;
  }
};
