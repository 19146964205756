export const SEND_EVENT_ANALYTICS = 'SEND_EVENT_ANALYTICS';

const sendEventAnalytics = (category: string, action: string) => ({
  type: SEND_EVENT_ANALYTICS as typeof SEND_EVENT_ANALYTICS,
  payload: {
    category,
    action,
  },
});

export type SendEventAnalyticsAction = ReturnType<typeof sendEventAnalytics>;

export default sendEventAnalytics;
