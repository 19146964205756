import ReportError from './reportError';
import {NotificationItem} from './fetchNotifications';

export const FETCH_COMPANY_NOTIFICATIONS = 'FETCH_COMPANY_NOTIFICATIONS';
export const FETCH_COMPANY_NOTIFICATIONS_SUCCESS =
  'FETCH_COMPANY_NOTIFICATIONS_SUCCESS';
export const FETCH_COMPANY_NOTIFICATIONS_ERROR =
  'FETCH_COMPANY_NOTIFICATIONS_ERROR';

const fetchCompanyNotifications = (cursor: string | null) => ({
  type: FETCH_COMPANY_NOTIFICATIONS as typeof FETCH_COMPANY_NOTIFICATIONS,
  payload: {
    cursor,
  },
});

interface FetchCompanyNotificationsSuccess {
  type: typeof FETCH_COMPANY_NOTIFICATIONS_SUCCESS;
  payload: {
    notificationItems: NotificationItem[];
    cursor: string | null;
  };
}

export type FetchCompanyNotificationsAction =
  | ReturnType<typeof fetchCompanyNotifications>
  | FetchCompanyNotificationsSuccess
  | ReportError<typeof FETCH_COMPANY_NOTIFICATIONS_ERROR>;

export default fetchCompanyNotifications;
