export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE';
export const AUTHENTICATION_FAILURE_COMPLETE =
  'AUTHENTICATION_FAILURE_COMPLETE';

interface AuthenticationFailure {
  type: typeof AUTHENTICATION_FAILURE;
}

interface AuthenticationFailureComplete {
  type: typeof AUTHENTICATION_FAILURE_COMPLETE;
}

export type AuthenticationFailureAction =
  | AuthenticationFailure
  | AuthenticationFailureComplete;
