import {i18n} from '../../locale/i18n';
import './Tutorial.scss';
import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {useNavigate} from 'react-router-dom';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';
import WhiteOutButton from '../parts/WhiteOutButton';
import SubmitButton from '../parts/SubmitButton';
import {FranchiseStoresState} from '../../reducers/franchiseStores';
import fetchApplicationSummary from '../../actions/fetchApplicationSummary';
import {GlobalState} from '../../reducers/index';
import {getOemName} from '../../util/oem';

import localStorage from '../../util/localStorage';
import RestrictedPage from '../auth/RestrictedPage';

const Tutorial = ({
  selected,
  loadApplicationSummary,
}: DispatchProps & StatesFromReducer) => {
  const [finished, setFinished] = useState(false);
  const [index, setIndex] = useState(0);

  const navigate = useNavigate();

  const oemName = getOemName();

  useEffect(() => {
    if (localStorage.showTutorial || finished) {
      if (selected) {
        loadApplicationSummary(selected.employeeID);
      }
      navigate('/');
    }
  }, [navigate, finished, selected, loadApplicationSummary]);

  const descriptions = i18n.t('initial2.description').split('\n');
  return (
    <RestrictedPage>
      <main id='tutorial'>
        <div className={index === 2 ? 'carousel last-page' : 'carousel'}>
          <Carousel
            autoPlay={false}
            infiniteLoop={false}
            showThumbs={false}
            showStatus={false}
            onChange={setIndex}
            selectedItem={index}
            useKeyboardArrows={true}
          >
            <img alt='一ページ目' src={`/img/tutorial/${oemName}/first.png`} />
            <img alt='二ページ目' src={`/img/tutorial/${oemName}/second.png`} />
            <div>
              <h2>{i18n.t('initial2.title')}</h2>
              {descriptions.map((description: string) => (
                <p key={description}>{description}</p>
              ))}
            </div>
          </Carousel>
        </div>

        {index === 2 ? (
          <SubmitButton
            onClick={() => {
              localStorage.showTutorial = true;
              setFinished(true);
            }}
            active={true}
          >
            {i18n.t('initial2.start')}
          </SubmitButton>
        ) : (
          <WhiteOutButton
            onClick={() => {
              setIndex(index + 1);
            }}
          >
            {i18n.t('button.next')}
          </WhiteOutButton>
        )}
      </main>
    </RestrictedPage>
  );
};

type StatesFromReducer = FranchiseStoresState;
interface DispatchProps {
  loadApplicationSummary: typeof fetchApplicationSummary;
}
const mapStateToProps = (state: GlobalState): StatesFromReducer => ({
  ...state.franchiseStores,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadApplicationSummary: (employeeID: number) =>
    dispatch(fetchApplicationSummary(employeeID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tutorial);
