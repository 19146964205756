import {i18n} from '../../locale/i18n';
import './EmailOrSms.scss';
import './Inputs.scss';
import {ChangeEvent} from 'react';

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  name?: string;
  title?: string;
}

const Component = ({onChange, value, name, title}: Props) => {
  return (
    <div className='email-or-sms inputs'>
      <p>{title || i18n.t('customerNoReminder.contact')}</p>
      <input
        name={name || 'number'}
        type='text'
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default Component;
