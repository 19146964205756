import {put, call, takeEvery} from 'redux-saga/effects';
import {AUTHENTICATION_FAILURE_COMPLETE} from '../actions/authFailure';
import fetchFinancialInstitutions, {
  FinancialInstitution,
  FETCH_FINANCIAL_INSTITUTION,
  FETCH_FINANCIAL_INSTITUTION_SUCCESS,
  FETCH_FINANCIAL_INSTITUTION_ERROR,
} from '../actions/fetchFinancialInstitutions';
import getFinancialInstitutions from '../api/getFinancialInstitutions';
import localStorage from '../util/localStorage';
import reportError from './reportError';
// import {
// 	FETCH_FINANCIAL_INSTITUTION_BRANCHES,
// } from "../actions/fetchFinancialInstitutionBranches";

function* handleFetchFinancialInstitution(
  action: ReturnType<typeof fetchFinancialInstitutions>
) {
  try {
    const authToken: string = yield localStorage.authToken;
    const resp: {data: {items: FinancialInstitution[]}} = yield call(
      getFinancialInstitutions,
      action.payload.financialName,
      authToken,
      action.payload.employeeID
    );
    yield put({
      type: FETCH_FINANCIAL_INSTITUTION_SUCCESS,
      payload: {financialInstitutions: resp.data.items},
    });
  } catch (e: any) {
    if (e.response && e.response.status === 403) {
      yield put({
        type: AUTHENTICATION_FAILURE_COMPLETE,
      });
      return;
    }

    yield reportError(e, FETCH_FINANCIAL_INSTITUTION_ERROR);
  }
}

function* financialInstitutionSaga() {
  yield takeEvery(FETCH_FINANCIAL_INSTITUTION, handleFetchFinancialInstitution);
}

export default financialInstitutionSaga;
