import {put, call, takeEvery, select, take} from 'redux-saga/effects';
import {AUTHENTICATION_FAILURE_COMPLETE} from '../actions/authFailure';
import fetchFranchiseStores, {
  FranchiseStore,
  FETCH_FRANCHISE_STORES,
  FETCH_FRANCHISE_STORES_SUCCESS,
  FETCH_FRANCHISE_STORES_ERROR,
} from '../actions/fetchFranchiseStores';
import getFranchiseStores from '../api/getFranchiseStores';
import localStorage from '../util/localStorage';
import reportError from './reportError';
import {FETCH_APPLICATION_SUMMARY} from '../actions/fetchApplicationSummary';
import selectFranchiseStore, {
  SELECT_FRANCHISE_STORE,
} from '../actions/selectFranchiseStore';
import {
  FETCH_USER_INFO,
  FETCH_USER_INFO_SUCCESS,
} from '../actions/fetchUserInfo';
import {FETCH_ACCOUNTING_HISTORIES} from '../actions/fetchBalanceHistories';
import {FETCH_NOTIFICATIONS} from '../actions/fetchNotifications';
import {FETCH_CRIA_NOTIFICATIONS} from '../actions/fetchCriaNotifications';
import {FETCH_COMPANY_NOTIFICATIONS} from '../actions/fetchCompanyNotifications';

function* handleFetchFranchiseStores(
  action: ReturnType<typeof fetchFranchiseStores>
) {
  try {
    const authToken: string = yield localStorage.authToken;
    const resp: {data: {items: FranchiseStore[]}} = yield call(
      getFranchiseStores,
      authToken
    );
    yield put({
      type: FETCH_FRANCHISE_STORES_SUCCESS,
      payload: {franchiseStores: resp.data.items},
    });

    const selected: FranchiseStore | null = yield select(
      state => state.franchiseStores.selected
    );
    const franchiseStore = selected !== null ? selected : resp.data.items[0];

    yield put({
      type: SELECT_FRANCHISE_STORE,
      payload: franchiseStore,
    });
  } catch (e: any) {
    if (e.response && e.response.status === 403) {
      yield put({
        type: AUTHENTICATION_FAILURE_COMPLETE,
      });
      return;
    }

    yield reportError(e, FETCH_FRANCHISE_STORES_ERROR);

    const franchiseStore = {employeeID: null};

    yield put({
      type: SELECT_FRANCHISE_STORE,
      payload: franchiseStore,
    });
  }
}

function* handleSelectFranchiseStores(
  action: ReturnType<typeof selectFranchiseStore>
) {
  yield put({
    type: FETCH_USER_INFO,
    payload: {employeeID: action.payload.employeeID},
  });

  yield take(FETCH_USER_INFO_SUCCESS);

  yield put({
    type: FETCH_APPLICATION_SUMMARY,
    payload: {employeeID: action.payload.employeeID},
  });

  yield put({
    type: FETCH_ACCOUNTING_HISTORIES,
    payload: {employeeID: action.payload.employeeID},
  });

  yield put({
    type: FETCH_NOTIFICATIONS,
    payload: {employeeID: action.payload},
  });

  yield put({
    type: FETCH_CRIA_NOTIFICATIONS,
    payload: {employeeID: action.payload},
  });

  yield put({
    type: FETCH_COMPANY_NOTIFICATIONS,
    payload: {employeeID: action.payload},
  });
}

function* franchiseStoresSaga() {
  yield takeEvery(FETCH_FRANCHISE_STORES, handleFetchFranchiseStores);
  yield takeEvery(SELECT_FRANCHISE_STORE, handleSelectFranchiseStores);
}

export default franchiseStoresSaga;
