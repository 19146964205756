import {takeEvery, put} from 'redux-saga/effects';
import confirmPin, {
  CONFIRM_PIN,
  CONFIRM_PIN_SUCCESS,
  CONFIRM_PIN_FAILURE,
} from '../actions/confirmPin';
import enterPin, {
  ENTER_PIN,
  ENTER_PIN_SUCCESS,
  ENTER_PIN_FAILURE,
} from '../actions/enterPin';
import localStorage from '../util/localStorage';
import {encode} from '../util/password';

const APP_PASSWORD_ENCODE_PREFIX = 'maebarai-p:';

function* handleConfirmPin(action: ReturnType<typeof confirmPin>) {
  const {pin, retypedPin, subtitle} = action.payload;

  if (pin === retypedPin || !retypedPin) {
    yield (localStorage.appPassword = encodeAppPassword(pin));

    yield put({
      type: CONFIRM_PIN_SUCCESS,
    });
  } else {
    yield put({
      type: CONFIRM_PIN_FAILURE,
      payload: {
        subtitle,
      },
    });
  }
}

function* handleEnterPin(action: ReturnType<typeof enterPin>) {
  const {pin} = action.payload;
  const storedPin = localStorage.appPassword;

  if (encodeAppPassword(pin) === storedPin) {
    yield put({
      type: ENTER_PIN_SUCCESS,
    });
  } else {
    yield put({
      type: ENTER_PIN_FAILURE,
    });
  }
}

const encodeAppPassword = (pin: string) => {
  return encode(
    pin,
    APP_PASSWORD_ENCODE_PREFIX,
    process.env.REACT_APP_SECRET_KEY || ''
  );
};

function* pinCodeSaga() {
  yield takeEvery(CONFIRM_PIN, handleConfirmPin);
  yield takeEvery(ENTER_PIN, handleEnterPin);
}

export default pinCodeSaga;
