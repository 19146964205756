import {
  useState,
  createRef,
  RefObject,
  KeyboardEvent,
  ChangeEvent,
} from 'react';
import convertToHarfWidth from '../../util/convertToHarfWidth';
import './ConfirmationCode.scss';
import {detect} from 'detect-browser';

interface Props {
  clearConfirmationCode: boolean;
  setConfirmationCode: (confirmationCode: string) => void;
  setValid: (valid: boolean) => void;
  setPinCodeFragment?: () => void;
}

const ConfirmationCode = ({
  setConfirmationCode,
  clearConfirmationCode,
  setValid,
  setPinCodeFragment,
}: Props) => {
  const valueAndSetters: any[] = [
    [...useState(''), ...useState(false), ...useState(false), createRef()],
    [...useState(''), ...useState(false), ...useState(false), createRef()],
    [...useState(''), ...useState(false), ...useState(false), createRef()],
    [...useState(''), ...useState(false), ...useState(false), createRef()],
    [...useState(''), ...useState(false), ...useState(false), createRef()],
    [...useState(''), ...useState(false), ...useState(false), createRef()],
  ];

  const valid =
    valueAndSetters[0][0] &&
    valueAndSetters[1][0] &&
    valueAndSetters[2][0] &&
    valueAndSetters[3][0] &&
    valueAndSetters[4][0] &&
    valueAndSetters[5][0];
  setValid(!!valid);

  const inputConfirmationCode =
    valueAndSetters[5][0].toString() +
    valueAndSetters[4][0].toString() +
    valueAndSetters[3][0].toString() +
    valueAndSetters[2][0].toString() +
    valueAndSetters[1][0].toString() +
    valueAndSetters[0][0].toString();

  if (clearConfirmationCode && inputConfirmationCode) {
    setConfirmationCode('');
    valueAndSetters[5][1]('');
    valueAndSetters[4][1]('');
    valueAndSetters[3][1]('');
    valueAndSetters[2][1]('');
    valueAndSetters[1][1]('');
    valueAndSetters[0][1]('');
  } else {
    setConfirmationCode(inputConfirmationCode);
  }

  let previousRef: RefObject<HTMLInputElement> | null;
  const nextRefs = {};
  let pasted: string | null = null;
  const inputs = valueAndSetters
    .map(([value, setter, _, setFocused, clicked, setClicked, ref], i) => {
      const element = ((
        closurePreviousRef,
        closureI,
        closureClicked,
        closureSetClicked
      ) => {
        const browser = detect();
        const isChrome = browser && browser.name === 'chrome';
        return (
          <div key={i}>
            {!closureClicked && value ? (
              <div onClick={() => closureSetClicked(true)}>
                <p>●</p>
              </div>
            ) : (
              <p />
            )}
            <input
              style={{
                left: isChrome ? '-28.5px' : '-35.5px',
              }}
              key={`input-number-${closureI}`}
              inputMode='numeric'
              type='text'
              maxLength={1}
              ref={ref}
              value={value}
              onPaste={e => {
                const inputedValue = e.clipboardData.getData('Text');

                if (inputedValue.match(/^\d{6}$/)) {
                  pasted = inputedValue;
                }
              }}
              onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
                const inputElement = e.target as HTMLInputElement;
                const inputValue = convertToHarfWidth(inputElement.value);

                if (
                  !inputValue &&
                  (e.key === 'Backspace' || e.code === 'Backspace') &&
                  nextRefs[`input-number-${closureI}`] &&
                  nextRefs[`input-number-${closureI}`].current
                ) {
                  nextRefs[`input-number-${closureI}`].current.focus();
                }

                if (inputValue && e.key.match(/^\d$/)) {
                  const inputNumber = e.key;
                  inputElement.value = inputNumber;
                  setter(inputNumber + '');
                  if (closurePreviousRef == null) return;
                  if (closurePreviousRef.current == null) return;
                  const current = closurePreviousRef.current;
                  current.focus();
                }
              }}
              onInput={(e: ChangeEvent<HTMLInputElement>) => {
                if (pasted) {
                  const inputed = pasted.split('');
                  valueAndSetters[5][1](inputed[0]);
                  valueAndSetters[4][1](inputed[1]);
                  valueAndSetters[3][1](inputed[2]);
                  valueAndSetters[2][1](inputed[3]);
                  valueAndSetters[1][1](inputed[4]);
                  valueAndSetters[0][1](inputed[5]);

                  pasted = null;
                  return;
                }
                const inputValue = convertToHarfWidth(e.target.value);
                if (!inputValue.match(/^$/) && !inputValue.match(/^\d+$/)) {
                  setter('');
                  return;
                }
                closureSetClicked(false);
                if (!inputValue) {
                  setter('');
                  if (
                    nextRefs[`input-number-${closureI}`] &&
                    nextRefs[`input-number-${closureI}`].current
                  ) {
                    nextRefs[`input-number-${closureI}`].current.focus();
                  }
                  return;
                }
                let inputNumber = parseInt(inputValue, 10);
                if (inputNumber > 10) {
                  inputNumber = parseInt(
                    inputNumber.toString().substr(-1, 1),
                    10
                  );
                }

                // 全角から半角に変換する際に、IMEが閉じて空文字が入力される。
                // 苦渋だが、入力が完了した瞬間に空文字での登録を不可能にする
                if (setPinCodeFragment) {
                  setPinCodeFragment();
                }
                setter(inputNumber + '');
                if (closurePreviousRef == null) return;
                if (closurePreviousRef.current == null) return;
                const current = closurePreviousRef.current;
                current.focus();
              }}
              onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                closureSetClicked(true);
                setFocused(true);
              }}
              onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                closureSetClicked(false);
                setFocused(false);
              }}
            />
          </div>
        );
      })(previousRef, i, clicked, setClicked);
      nextRefs[`input-number-${i - 1}`] = ref;
      previousRef = ref;
      return element;
    })
    .reverse();

  return (
    <div id='confirmation-codes'>
      <p />
      {inputs}
      <p />
    </div>
  );
};

export default ConfirmationCode;
