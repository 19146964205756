import {FranchiseStore} from './fetchFranchiseStores';

export const SELECT_FRANCHISE_STORE = 'SELECT_FRANCHISE_STORE';

const selectFranchiseStore = (franchiseStore: FranchiseStore) => ({
  type: SELECT_FRANCHISE_STORE as typeof SELECT_FRANCHISE_STORE,
  payload: franchiseStore,
});

export type SelectFranchiseStoreAction = ReturnType<
  typeof selectFranchiseStore
>;

export default selectFranchiseStore;
