import {useState, useEffect} from 'react';
import {useNavigate, NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {GlobalState} from '../../reducers/index';
import {AuthTokenState} from '../../reducers/authToken';
import fetchAuthToken from '../../actions/fetchAuthToken';
import showAlert from '../../actions/showAlert';
import DocumentTitle from '../parts/DocumentTitle';
import Header from '../parts/Headers/InitialSetting';
import {i18n} from '../../locale/i18n';
import resetPasswordReset from '../../actions/resetPasswordReset';
import resetCustomerNoReminder from '../../actions/resetCustomerNoReminder';
import removeAuthToken from '../../actions/removeAuthToken';
import {
  postAgreeWithTheTerms,
  cancelAgreeWithTheTerms,
} from '../../actions/agreeWithTheTerms';

import {
  checkInputted,
  checkLength,
  checkNumbers,
  checkMaxLength,
} from '../../util/validation';
import sendEventAnalytics from '../../actions/sendEventAnalytics';
import './LoginScreen.scss';
import TermsOfUse from './TermsOfUse';

import SubmitButton from '../parts/SubmitButton';
import Password from '../parts/Password';
import CustomerNumber from '../parts/CustomerNumber';

interface StateProps {
  showConfirmationCode?: boolean;
  // Headerはreduxと接続しないと使えないが、jestはストアを生成せず、テスト時に困るのでnoHeaderを渡して呼び出す
  noHeader?: boolean;
}

interface DispatchProps {
  login: typeof fetchAuthToken;
  callShowAlert: typeof showAlert;
  sendEvent: typeof sendEventAnalytics;
  resetShowConfirmationCode: typeof removeAuthToken;
  agreeWithTheTerms: typeof postAgreeWithTheTerms;
  disgreeWithTheTerms: typeof cancelAgreeWithTheTerms;
  resetReminderResults: () => void;
}

type Props = StateProps & DispatchProps & AuthTokenState;

export const LoginScreen = ({
  returnPathname,
  noHeader,
  loggedIn,
  sendEvent,
  callShowAlert,
  login,
  resetReminderResults,
  showConfirmationCode,
  resetShowConfirmationCode,
  agreeWithTheTerms,
  disgreeWithTheTerms,
  showTermOfUse,
}: Props) => {
  const navigate = useNavigate();
  const [customerNo, setCustomerNo] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [message, setMessage] = useState<string | null>(null);

  resetReminderResults();

  const validate = () => {
    sendEvent('Login', 'login button click');

    let validateMessage = null;

    if (checkInputted(customerNo)) {
      validateMessage = i18n.t('alert.customerNumberEmpty');
    } else if (checkLength(customerNo, 8)) {
      validateMessage = i18n.t('alert.customerNumberNoMatchLength');
    } else if (checkNumbers(customerNo)) {
      validateMessage = i18n.t('alert.customerNumberNotInt');
    } else if (checkInputted(password)) {
      validateMessage = i18n.t('alert.passwordEmpty');
    } else if (checkMaxLength(password, 255)) {
      validateMessage = i18n.t('alert.passwordTooLong');
    }

    setMessage(validateMessage);

    if (validateMessage !== null) {
      sendEvent('Login', 'login validation error');

      callShowAlert({
        title: i18n.t('alert.inputError'),
        message: validateMessage,
      });
      return;
    }

    const pathname = returnPathname || '/';

    login(customerNo, password, {
      routeOnSuccess: {pathname},
    });
  };

  const valid = !(
    checkInputted(customerNo) ||
    checkLength(customerNo, 8) ||
    checkNumbers(customerNo) ||
    checkInputted(password) ||
    checkMaxLength(password, 255)
  );

  useEffect(() => {
    if (loggedIn) {
      navigate('/');
    }
    if (showConfirmationCode) {
      resetShowConfirmationCode();
      navigate('/confirmation-code');
    }
  }, [loggedIn, showConfirmationCode, resetShowConfirmationCode, navigate]);

  return (
    <>
      {noHeader ? <></> : <Header />}
      <main id='login-screen'>
        <DocumentTitle pageNameInTitle='Login' />
        <div className='contents-inner'>
          <div>
            <form>
              <ul className='number-and-password'>
                <li>
                  <CustomerNumber
                    onChange={e => setCustomerNo(e.target.value)}
                    value={customerNo}
                  />
                </li>
                <li>
                  <Password
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    viewPasswordVisibleButton={true}
                  />
                </li>
              </ul>
              <p className='error'>{message}</p>
              <SubmitButton active={valid} onClick={validate}>
                {i18n.t('button.login')}
              </SubmitButton>
            </form>
            <ul className='info'>
              <NavLink
                to='/password-reminder'
                onClick={() => sendEvent('Login', 'login link click')}
              >
                <li>
                  <h3>{i18n.t('login.linkPasswordReset')}</h3>
                  <div>
                    <img alt='reset' src='/img/login-screen/reset.png' />
                    <p>{i18n.t('passwordReset.title')}</p>
                  </div>
                </li>
              </NavLink>

              <NavLink
                to='/customer-no-reminder'
                onClick={() => sendEvent('Login', 'login link click')}
              >
                <li>
                  <h3>{i18n.t('login.linkCustomerNumberReminder')}</h3>
                  <div>
                    <img
                      alt='forget-number'
                      src='/img/login-screen/forget-number.png'
                    />
                    <p>{i18n.t('customerNoReminder.title')}</p>
                  </div>
                </li>
              </NavLink>
            </ul>
          </div>
        </div>

        <TermsOfUse
          isOpened={showTermOfUse}
          onAgree={() => agreeWithTheTerms(customerNo)}
          onCancel={() => {
            disgreeWithTheTerms();
          }}
        />
      </main>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  ...state.authToken,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  resetReminderResults: () => {
    dispatch(resetPasswordReset());
    dispatch(resetCustomerNoReminder());
  },
  login: (customerNo, password, meta) =>
    dispatch(fetchAuthToken(customerNo, password, meta)),
  resetShowConfirmationCode: () => dispatch(removeAuthToken()),
  callShowAlert: config => dispatch(showAlert(config)),
  sendEvent: (category, action) =>
    dispatch(sendEventAnalytics(category, action)),
  agreeWithTheTerms: customerNo => dispatch(postAgreeWithTheTerms(customerNo)),
  disgreeWithTheTerms: () => dispatch(cancelAgreeWithTheTerms()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
