import ReportError from './reportError';
import {BalanceHistory} from '../reducers/balanceHistories';
export const FETCH_ACCOUNTING_HISTORIES = 'FETCH_ACCOUNTING_HISTORIES';
export const FETCH_ACCOUNTING_HISTORIES_SUCCESS =
  'FETCH_ACCOUNTING_HISTORIES_SUCCESS';
export const FETCH_ACCOUNTING_HISTORIES_ERROR =
  'FETCH_ACCOUNTING_HISTORIES_ERROR';

export interface BalanceHistoriesItem {
  month: string;
  startDate: string;
  endDate: string;
  totalAccountingAmount: number;
  data: BalanceHistory[];
}

const fetchBalanceHistories = (
  cursor: string | null,
  employeeID: number | null
) => ({
  type: FETCH_ACCOUNTING_HISTORIES as typeof FETCH_ACCOUNTING_HISTORIES,
  payload: {cursor, employeeID},
});

interface FetchBalanceHistoriesSuccess {
  type: typeof FETCH_ACCOUNTING_HISTORIES_SUCCESS;
  payload: {
    balanceHistories: BalanceHistoriesItem[];
    cursor: string | null;
    nextCursor: string | null;
  };
}

export type FetchBalanceHistoriesAction =
  | ReturnType<typeof fetchBalanceHistories>
  | FetchBalanceHistoriesSuccess
  | ReportError<typeof FETCH_ACCOUNTING_HISTORIES_ERROR>;

export default fetchBalanceHistories;
