import ReportError from './reportError';
import {AlertConfig} from '../components/parts/Alert';

export const PUT_USER = 'PUT_USER';
export const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS';
export const PUT_USER_ERROR = 'PUT_USER_ERROR';

const putUser = (
  employeeID: number | null,
  financialInstitutionCode: string,
  branchCode: string,
  accountType: string,
  accountNumber: string,
  accountName: string,
  alertConfig?: AlertConfig
) => ({
  type: PUT_USER as typeof PUT_USER,
  payload: {
    employeeID,
    financialInstitutionCode,
    branchCode,
    accountType,
    accountNumber,
    accountName,
  },
  alertConfig,
});

interface PutUserSuccess {
  type: typeof PUT_USER_SUCCESS;
  payload: {};
}

interface PutUser {
  type: typeof PUT_USER;
  payload: {};
}
export type PutUserAction =
  | ReturnType<typeof putUser>
  | PutUser
  | PutUserSuccess
  | ReportError<typeof PUT_USER_ERROR>;

export default putUser;
