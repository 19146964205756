import * as React from 'react';
import initPin from '../../../actions/initPin';
import choosePin from '../../../actions/choosePin';
import enterPin from '../../../actions/enterPin';

export interface PinCodeProps {
  finishProcess?: any;
}

export interface StateProps {
  subtitle: React.ReactElement<any>;
  status: PinStatus;
  pin: string;
  hasError: boolean;
  completed: boolean;
}

export interface DispatchProps {
  init: typeof initPin;
  input: typeof choosePin | typeof enterPin;
  helpAppPassForgetter?: () => void;
}

export enum PinStatus {
  input = 'input',
  confirm = 'confirm',
}
