import {doDelete} from './http';

const deleteAuthToken = (
  refreshToken: string,
  instanceID: string,
  authToken: string
) => {
  return doDelete('/auth_token', {refreshToken}, authToken, instanceID);
};

export default deleteAuthToken;
