import {i18n} from '../../locale/i18n';
import './CustomerNumber.scss';
import './Inputs.scss';
import {ChangeEvent} from 'react';

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  name?: string;
  title?: string;
}

const Component = ({onChange, value, name, title}: Props) => {
  return (
    <div className='customer-number inputs'>
      <p>{title || i18n.t('common.customerNumber')}</p>
      <input
        inputMode='numeric'
        name={name || 'number'}
        type='text'
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default Component;
