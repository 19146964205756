import {
  RequestCustomerNoReminderAction,
  REQUEST_CUSTOMER_NO_REMINDER,
  REQUEST_CUSTOMER_NO_REMINDER_SUCCESS,
  REQUEST_CUSTOMER_NO_REMINDER_ERROR,
} from '../actions/requestCustomerNoReminder';

import {
  ResetCustomerNoReminderAction,
  RESET_CUSTOMER_NO_REMINDER,
} from '../actions/resetCustomerNoReminder';
import localStorage from '../util/localStorage';

type RequestCustomerAction =
  | RequestCustomerNoReminderAction
  | ResetCustomerNoReminderAction;

export interface RequestCustomerNoResultState {
  requestCustomerNoDate: null | Date;
  requestCustomerNoCompleted: boolean;
  requestCustomerNoSucceeded: boolean;
  requestCustomerNoErrorMessage: string | null;
}

const requestCustomerNoDate = localStorage.requestCustomerNoDate;

const initialState: RequestCustomerNoResultState = {
  requestCustomerNoDate,
  requestCustomerNoCompleted: false,
  requestCustomerNoSucceeded: false,
  requestCustomerNoErrorMessage: null,
};

const requestCustomerNoReducer = (
  state = initialState,
  action: RequestCustomerAction
) => {
  switch (action.type) {
    case RESET_CUSTOMER_NO_REMINDER:
      return Object.assign({}, state, {
        requestCustomerNoCompleted: false,
        requestCustomerNoSucceeded: false,
        requestCustomerNoErrorMessage: null,
      });
    case REQUEST_CUSTOMER_NO_REMINDER:
      return Object.assign({}, state, {});
    case REQUEST_CUSTOMER_NO_REMINDER_SUCCESS:
      const newRequestCustomerNoDate = new Date();
      localStorage.requestCustomerNoDate = newRequestCustomerNoDate;
      return Object.assign({}, state, {
        requestCustomerNoDate: newRequestCustomerNoDate,
        requestCustomerNoCompleted: true,
        requestCustomerNoSucceeded: true,
      });
    case REQUEST_CUSTOMER_NO_REMINDER_ERROR:
      return Object.assign({}, state, {
        requestCustomerNoCompleted: true,
        requestCustomerNoSucceeded: false,
        requestCustomerNoErrorMessage: action.payload.message,
      });
    default:
      return state;
  }
};

export default requestCustomerNoReducer;
