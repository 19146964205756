import {
  FetchUserInfoAction,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO_ERROR,
} from '../actions/fetchUserInfo';
import {PutUserAction, PUT_USER, PUT_USER_ERROR} from '../actions/putUser';

type UserInfoAction = FetchUserInfoAction | PutUserAction;

export interface UserInfoState {
  user: {
    accountName?: string;
    accountNumber?: string;
    accountType?: string;
    branchName?: string;
    branchCode?: string;
    customerNo?: string;
    employeeCode?: string;
    financialInstitutionName?: string;
    financialInstitutionCode?: string;
    franchiseStoreName?: string;
    givenName?: string;
    mailAddress?: string;
    phoneNumber?: string;
    surname?: string;
    applicationDisabled?: boolean;
  };
}
const initialState: UserInfoState = {
  user: {},
};

const userInfoReducer = (state = initialState, action: UserInfoAction) => {
  switch (action.type) {
    case FETCH_USER_INFO_SUCCESS:
      return Object.assign({}, state, {
        user: action.payload.user,
      });
    case FETCH_USER_INFO_ERROR:
      return Object.assign({}, state, {});
    case PUT_USER:
      return Object.assign({}, state, {});
    case PUT_USER_ERROR:
      return Object.assign({}, state, {});
    default:
      return state;
  }
};

export default userInfoReducer;
