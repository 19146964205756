import {i18n} from '../../../locale/i18n';
import './Common.scss';
import './Seven.scss';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import closeApplicationResult from '../../../actions/closeApplicationResult';
import {
  ApplicationSummaryState,
  IApplicationSummary,
  IApplicationResult,
} from '../../../reducers/applicationSummary';
import ReactModal from 'react-modal';
import WhiteOutButton from '../../../components/parts/WhiteOutButton';
import {GlobalState} from '../../../reducers/index';
import {customerNoWithSpace} from '../../../util/number';
import {getOemName} from '../../../util/oem';

interface DispatchProps {
  close: typeof closeApplicationResult;
}

type StatesFromReducer = ApplicationSummaryState &
  IApplicationSummary &
  IApplicationResult;
type Props = StatesFromReducer & DispatchProps;

const Result = ({
  close,
  showsApplicationResult,
  paymentMethodIndex,
  partnerCode,
  confirmNo,
  customerNo,
}: Props) => {
  const oemName = getOemName();

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={paymentMethodIndex === 1 && showsApplicationResult}
      onRequestClose={() => {
        close();
      }}
      contentLabel='Settings'
      overlayClassName='overlay'
      className='home-result-seven'
    >
      <section>
        <h2>
          <img alt='checked' src={`/img/home/result/${oemName}/checked.svg`} />
          <p>{i18n.t('result.annotation')}</p>
        </h2>
        <p className='thanks'>{i18n.t('result.thanks')}</p>
        <hr />
        <dl>
          <dt>{i18n.t('common.partnerCode')}</dt>
          <dd>{partnerCode}</dd>
        </dl>
        <dl>
          <dt>{i18n.t('common.customerNumber')}</dt>
          <dd>{customerNoWithSpace(customerNo)}</dd>
        </dl>
        <dl>
          <dt>{i18n.t('common.confirmationNumber')}</dt>
          <dd>{confirmNo}</dd>
        </dl>
        <hr />
        {oemName === 'cria' ? (
          <></>
        ) : (
          <img
            alt='viewHistory'
            src={`/img/home/result/${oemName}/history.svg`}
          />
        )}
        <p className='confirmation'>{i18n.t('result.confirmation')}</p>
        <a rel='noopener noreferrer' href='/usage-seven-atm' target='_blank'>
          <img alt='seven' src={`/img/home/result/${oemName}/seven.svg`} />
        </a>

        <WhiteOutButton
          onClick={() => {
            close();
          }}
        >
          {'✕ ' + i18n.t('button.close')}
        </WhiteOutButton>
      </section>
    </ReactModal>
  );
};

const mapStateToProps = (state: GlobalState): StatesFromReducer => ({
  ...state.applicationSummary.applicationSummary,
  ...state.applicationSummary.applicationResult,
  ...state.applicationSummary,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  close: () => dispatch(closeApplicationResult()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Result);
