import {i18n} from '../../../locale/i18n';
import LanguageSelector from '../../parts/LanguageSelector';
import {NavLink} from 'react-router-dom';
import './InitialSetting.scss';
import {getOemName} from '../../../util/oem';

const LoginHeader = () => {
  const oemName = getOemName();
  return (
    <header id='initial-setting'>
      <h1 className='text-center'>
        <NavLink to='/'>
          <img
            src={`img/${oemName}/logo.svg`}
            alt={i18n.t('notification.cria')}
            width={oemName === 'cria' ? '87' : '180'}
            height='auto'
          />
        </NavLink>
      </h1>
      <div id='menu-buttons'>
        <LanguageSelector />
      </div>
    </header>
  );
};

export default LoginHeader;
