import {
  FETCH_FRANCHISE_STORES,
  FETCH_FRANCHISE_STORES_SUCCESS,
  FETCH_FRANCHISE_STORES_ERROR,
  FetchFranchiseStoresAction,
  FranchiseStore,
} from '../actions/fetchFranchiseStores';
import {
  SELECT_FRANCHISE_STORE,
  SelectFranchiseStoreAction,
} from '../actions/selectFranchiseStore';

export interface FranchiseStoresState {
  franchiseStores: FranchiseStore[];
  selected: FranchiseStore | null;
}

const initialState: FranchiseStoresState = {
  franchiseStores: [],
  selected: null,
};

type FranchiseStoresAction =
  | FetchFranchiseStoresAction
  | SelectFranchiseStoreAction;

const franchiseStoresReducer = (
  state = initialState,
  action: FranchiseStoresAction
) => {
  switch (action.type) {
    case FETCH_FRANCHISE_STORES:
      return Object.assign({}, state, {});
    case FETCH_FRANCHISE_STORES_SUCCESS: {
      const currentCompanyCode = localStorage.currentCompanyCode;
      const franchiseStores = action.payload.franchiseStores;
      let selected = franchiseStores[0];
      if (currentCompanyCode) {
        franchiseStores.forEach(franchiseStore => {
          if (franchiseStore.companyCode === currentCompanyCode) {
            selected = franchiseStore;
          }
        });
      }
      return Object.assign({}, state, {
        franchiseStores,
        selected,
      });
    }
    case FETCH_FRANCHISE_STORES_ERROR:
      return Object.assign({}, state, {});
    case SELECT_FRANCHISE_STORE: {
      const selected = action.payload;
      localStorage.currentCompanyCode = selected.companyCode;
      return Object.assign({}, state, {
        selected,
      });
    }
    default:
      return state;
  }
};

export default franchiseStoresReducer;
