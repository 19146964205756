import {put, takeEvery} from 'redux-saga/effects';
import setLanguage, {
  SET_LANGUAGE,
  SET_LANGUAGE_SUCCESS,
} from '../actions/setLanguage';

function* handleSetLanguage(action: ReturnType<typeof setLanguage>) {
  yield put({
    type: SET_LANGUAGE_SUCCESS,
    payload: {language: action.payload.language},
  });
}

function* languageSaga() {
  yield takeEvery(SET_LANGUAGE, handleSetLanguage);
}

export default languageSaga;
