import {MouseEvent} from 'react';

export interface Props {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | null;
  active: boolean;
  children: string;
}

const Component = ({onClick, children, active}: Props) => {
  return active && onClick ? (
    <button className={active ? 'active' : ''} type='button' onClick={onClick}>
      {children}
    </button>
  ) : (
    <button className={active ? 'active' : ''} type='button'>
      {children}
    </button>
  );
};
export default Component;
