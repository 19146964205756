import ReportError from './reportError';

export const APPLY_PREPAYMENT = 'APPLY_PREPAYMENT';
export const APPLY_PREPAYMENT_SUCCESS = 'APPLY_PREPAYMENT_SUCCESS';
export const APPLY_PREPAYMENT_ERROR = 'APPLY_PREPAYMENT_ERROR';

const applyPrepayment = (
  paymentMethodIndex: number,
  amount: number,
  employeeID: number | null
) => {
  return {
    type: APPLY_PREPAYMENT as typeof APPLY_PREPAYMENT,
    payload: {paymentMethodIndex, amount, employeeID},
  };
};

interface ApplyPrepaymentSuccess {
  type: typeof APPLY_PREPAYMENT_SUCCESS;
  payload: {
    applicationResult: {
      transferAmount: number;
      transferDate: string;
      applicationNumber: string;
    };
  };
}

export type ApplyPrepaymentAction =
  | ReturnType<typeof applyPrepayment>
  | ApplyPrepaymentSuccess
  | ReportError<typeof APPLY_PREPAYMENT_ERROR>;

export default applyPrepayment;
