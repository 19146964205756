import {i18n} from '../../locale/i18n';
import {useState} from 'react';
import {connect} from 'react-redux';
import './CompanySelector.scss';
import {Dispatch} from 'redux';
import ReactModal from 'react-modal';
import {FranchiseStoresState} from '../../reducers/franchiseStores';
import {GlobalState} from '../../reducers/index';
import selectFranchiseStore from '../../actions/selectFranchiseStore';
import {FranchiseStore} from '../../actions/fetchFranchiseStores';

interface SelectedIndex {
  selectedIndex: number;
}

type StateFromReducer = FranchiseStoresState & SelectedIndex;
type Props = StateFromReducer & DispatchProps;
const CompanySelector = ({
  franchiseStores,
  selectedStore,
  selected,
  selectedIndex,
}: Props) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <ReactModal
        isOpen={opened}
        onRequestClose={() => {
          setOpened(false);
        }}
        ariaHideApp={false}
        contentLabel='Settings'
        overlayClassName='overlay'
        className='company-pulldowns'
      >
        <ul>
          <li
            onClick={() => {
              setOpened(false);
            }}
          >
            <h2>{i18n.t('application.switchCompany')}</h2>
          </li>
          {franchiseStores.map((company, i) => (
            <li
              className={i === selectedIndex ? 'selected' : ''}
              onClick={() => {
                selectedStore(franchiseStores[i]);
                setOpened(false);
              }}
              key={i}
            >
              {company.franchiseStoreName}
            </li>
          ))}
        </ul>
      </ReactModal>
      <button id='company-selector' onClick={() => setOpened(true)}>
        {franchiseStores.length > 0
          ? franchiseStores[selectedIndex].franchiseStoreName
          : ''}
      </button>
    </>
  );
};

interface DispatchProps {
  selectedStore: typeof selectFranchiseStore;
}

const mapStateToProps = (state: GlobalState): StateFromReducer => {
  const {franchiseStores, selected} = state.franchiseStores;

  let selectedIndex = 0;
  if (selected != null) {
    franchiseStores.forEach((franchiseStore, i) => {
      if (franchiseStore.companyCode === selected.companyCode) {
        selectedIndex = i;
      }
    });
  }
  return {
    franchiseStores,
    selected,
    selectedIndex,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  selectedStore: (franchiseStore: FranchiseStore) =>
    dispatch(selectFranchiseStore(franchiseStore)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelector);
