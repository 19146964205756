import {call, put, takeEvery} from 'redux-saga/effects';
import postTermsOfUse from '../api/postTermsOfUse';
import reportError from './reportError';

import {
  postAgreeWithTheTerms,
  POST_AGREE_WITH_THE_TERMS,
  POST_AGREE_WITH_THE_TERMS_SUCCESS,
  POST_AGREE_WITH_THE_TERMS_FAILURE,
} from '../actions/agreeWithTheTerms';
import {FETCH_FRANCHISE_STORES} from '../actions/fetchFranchiseStores';

function* handleAgreeWithTheTerms(
  action: ReturnType<typeof postAgreeWithTheTerms>
) {
  const instanceID: string = yield localStorage.instanceID;
  try {
    const resp: {status: number} = yield call(
      postTermsOfUse,
      action.payload.customerNo,
      instanceID
    );

    if (resp.status === 200) {
      const authToken: string = yield localStorage.authToken;
      const isAppPasswordSet = !!localStorage.appPassword;

      yield put({
        type: FETCH_FRANCHISE_STORES,
      });

      yield put({
        type: POST_AGREE_WITH_THE_TERMS_SUCCESS,
        payload: {authToken, isAppPasswordSet},
      });
    }
  } catch (e: any) {
    yield reportError(e, POST_AGREE_WITH_THE_TERMS_FAILURE);
    return;
  }
}

function* agreeWithTheTermsSaga() {
  yield takeEvery(POST_AGREE_WITH_THE_TERMS, handleAgreeWithTheTerms);
}

export default agreeWithTheTermsSaga;
