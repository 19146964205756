import './Card.scss';
import {
  BalanceHistory,
  AttendanceDetail as AttendanceDetailType,
} from '../../reducers/balanceHistories';
import moment from 'moment';
import commaNumber from 'comma-number';
import BankDetail from './BankDetail';
import SevenDetail from './SevenDetail';
import {useState} from 'react';
import {detect} from 'detect-browser';

import {getOemName} from '../../util/oem';

enum PlusOrMinus {
  plus = 'plus',
  minus = 'minus',
}
const Card = ({
  balanceHistory,
  nextOpened,
  opened,
  toggle,
}: {
  balanceHistory: BalanceHistory;
  nextOpened: boolean;
  opened: boolean;
  toggle: () => any;
}) => {
  const {
    historyStatus,
    transferStatus,
    applicationNumber,
    historyDate,
    balance,
    changedAmount,
    attendanceDetail,
  } = balanceHistory;

  const oemName = getOemName();

  const statuses = {
    '1-1': {
      title: '申請(銀行)',
      icon: `/img/accounting-history/icons/${oemName}/application.svg`,
      alt: 'application',
      isBank: true,
      plusOrMinus: PlusOrMinus.minus,
    },
    '1-2': {
      title: '振込エラー',
      icon: '/img/accounting-history/icons/bank-error.svg',
      alt: 'bank-error',
      isBank: true,
      plusOrMinus: PlusOrMinus.plus,
    },
    '1-3': {
      title: '申請(銀行)',
      icon: '/img/accounting-history/icons/bank-error.svg',
      alt: 'application',
      isBank: true,
      plusOrMinus: PlusOrMinus.minus,
    },
    '2-1': {
      title: '申請(セブン)',
      icon: `/img/accounting-history/icons/${oemName}/application.svg`,
      alt: 'application',
      isSeven: true,
      plusOrMinus: PlusOrMinus.minus,
    },
    '2-2': {
      title: '受取期限切れ',
      icon: `/img/accounting-history/icons/${oemName}/receive-expire.svg`,
      alt: 'receive-expire',
      isSeven: true,
      plusOrMinus: PlusOrMinus.plus,
    },
    '2-3': {
      title: '申請(セブン)',
      icon: `/img/accounting-history/icons/${oemName}/application.svg`,
      alt: 'application',
      isSeven: true,
      plusOrMinus: PlusOrMinus.minus,
    },
    '3-1': {
      title: '勤怠反映',
      icon: '/img/accounting-history/icons/attendance-reflect.svg',
      alt: 'attendance-reflect',
      isAttendance: true,
      plusOrMinus: PlusOrMinus.plus,
    },
    '3-2': {
      title: '勤怠修正',
      // これはあとで修正
      icon: '/img/accounting-history/icons/attendance-expire.svg',
      alt: 'attendance-update',
      isAttendance: true,
      plusOrMinus: PlusOrMinus.plus,
    },
    '3-3': {
      title: '勤怠修正',
      // これはあとで修正
      icon: '/img/accounting-history/icons/attendance-expire.svg',
      alt: 'attendance-update',
      isAttendance: true,
      plusOrMinus: PlusOrMinus.minus,
    },
    '3-4': {
      title: '勤怠削除',
      // これはあとで修正
      icon: '/img/accounting-history/icons/attendance-expire.svg',
      alt: 'attendance-delete',
      isAttendance: true,
      hideMessage: true,
      plusOrMinus: PlusOrMinus.minus,
    },
    '3-5': {
      title: '勤怠期限切れ',
      icon: '/img/accounting-history/icons/attendance-expire.svg',
      alt: 'attendance-expire',
      isAttendance: true,
      hideDate: true,
      plusOrMinus: PlusOrMinus.minus,
      cautionMessage: '※ 期限切れの申請金額は給与日に振り込まれます',
    },
  };

  const transferStatuses = {
    '1-1': {
      message: '送金待ち',
    },
    '1-2': {
      message: '振込エラー',
    },
    '1-3': {
      message: '送金完了',
    },
    '2-1': {
      message: '受取待ち',
      viewReceiveMethod: true,
    },
    '2-2': {
      message: '期限切れ',
      viewReceiveMethod: false,
    },
    '2-3': {
      message: '受取完了',
      viewReceiveMethod: false,
    },
  };

  const {message, viewReceiveMethod = false} = transferStatus
    ? transferStatuses[transferStatus]
    : {message: ''};
  const status = statuses[historyStatus];
  const {
    title,
    icon,
    alt,
    isBank,
    isSeven,
    isAttendance,
    plusOrMinus,
    hideMessage = false,
    hideDate = false,
    cautionMessage = null,
  } = status;
  const [mouseIn, setMouseIn] = useState(false);

  const {expirationDate, workDate} = attendanceDetail as AttendanceDetailType;

  // spだとleaveが反応しない。Enterは反応するのでhoverが残ってしまう。
  // なのでSPではmouseイベントを使わずtouchを使う
  const browser = detect();
  const isSp = browser && (browser.os === 'iOS' || browser.os === 'Android OS');

  const toggleFunction = () => {
    if (isBank || isSeven) {
      toggle();
    }
  };

  return (
    <li
      className={`accounting-history-card ${mouseIn ? 'hover' : ''} ${
        nextOpened ? 'next-opened' : ''
      }`}
      onMouseEnter={() => {
        if (!isSp) {
          setMouseIn(true);
        }
      }}
      onMouseLeave={() => {
        if (!isSp) {
          setMouseIn(false);
        }
      }}
      onTouchStart={() => {
        if (isSp) {
          setMouseIn(true);
        }
      }}
      onTouchEnd={() => {
        if (isSp) {
          setMouseIn(false);
        }
      }}
    >
      <div className='receive-date' onClick={toggleFunction}>
        <div className='alt-icon' />
        <p>{moment(historyDate).format('Y年M月D日')}</p>
        <div className='alt-amount' />
        <div className='alt-no-expand' />
      </div>
      <div className='upper-area' onClick={toggleFunction}>
        <div className='icon'>
          <img alt={alt} src={icon} />
        </div>
        <div className='description'>
          <h2>{title}</h2>
          {isBank || isSeven ? (
            <p className='account-number'>申請番号{applicationNumber}</p>
          ) : null}
          {isAttendance && !hideDate ? (
            <p className='account-number'>
              {moment(workDate).format('Y年M月D日')}分
            </p>
          ) : null}
        </div>
        <div className='amount'>
          <div className='changedAmount'>
            <img
              className={plusOrMinus}
              alt={plusOrMinus}
              src={`/img/accounting-history/buttons/${plusOrMinus}.svg`}
            />
            <p className='kingaku'>{commaNumber(changedAmount)}</p>
            <p className='yen'>円</p>
          </div>
          <p className='balance'>申請可能残高 {commaNumber(balance)}円</p>

          {message ? (
            <div className='status-card'>
              <div>{message}</div>
            </div>
          ) : (
            <></>
          )}
          {isAttendance && !hideMessage && !message && expirationDate ? (
            <div className='expiration-date'>
              <div>{moment(expirationDate).format('Y年M月D日')}期限</div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {isBank || isSeven ? (
          <div className='expand'>
            <img
              alt={opened ? 'open' : 'close'}
              src={`/img/accounting-history/buttons/${
                !opened ? 'open' : 'close'
              }.svg`}
            />
          </div>
        ) : (
          <div className='no-expand' />
        )}
      </div>
      {cautionMessage ? (
        <div className='caution'>
          <div className='alt-icon' />
          <div className='caution-message'>{cautionMessage}</div>
          <div />
          <div />
        </div>
      ) : (
        <></>
      )}
      {isBank ? (
        <div className={`expand-area ${opened ? ' opened-bank' : ''}`}>
          <BankDetail close={toggle} balanceHistory={balanceHistory} />
        </div>
      ) : (
        <></>
      )}

      {isSeven ? (
        <div className={`expand-area ${opened ? ' opened-seven' : ''}`}>
          <SevenDetail
            viewReceiveMethod={viewReceiveMethod}
            close={toggle}
            balanceHistory={balanceHistory}
          />
        </div>
      ) : (
        <></>
      )}
    </li>
  );
};

export default Card;
