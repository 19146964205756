import {
  FetchCompanyNotificationsAction,
  FETCH_COMPANY_NOTIFICATIONS,
  FETCH_COMPANY_NOTIFICATIONS_SUCCESS,
  FETCH_COMPANY_NOTIFICATIONS_ERROR,
} from '../actions/fetchCompanyNotifications';

import {NotificationsState} from './notifications';

const initialState: NotificationsState = {
  notificationItems: [],
  cursor: null,
  loading: false,
};

type NotificationsAction = FetchCompanyNotificationsAction;

const notificationsReducer = (
  state = initialState,
  action: NotificationsAction
) => {
  switch (action.type) {
    case FETCH_COMPANY_NOTIFICATIONS:
      return Object.assign({}, state, {loading: true});
    case FETCH_COMPANY_NOTIFICATIONS_SUCCESS:
      return Object.assign({}, state, {
        notificationItems: [
          ...state.notificationItems,
          ...action.payload.notificationItems,
        ],
        cursor: action.payload.cursor,
        loading: false,
      });
    case FETCH_COMPANY_NOTIFICATIONS_ERROR:
      return Object.assign({}, state, {});
    default:
      return state;
  }
};

export default notificationsReducer;
