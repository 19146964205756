import {i18n} from '../../locale/i18n';
import './PrivacyPolicy.scss';
import './PrivacyPolicyAndTerms.scss';
import Header from '../../components/parts/Headers/OnlyLogo';
import DocumentTitle from '../parts/DocumentTitle';
import {languages} from '../../actions/setLanguage';

const Component = () => {
  const privacyPolicyBody = renderPrivacyPolicyBody();
  const match = window.location.href.match(/language_code=(\w+)/);
  let foreignLanguagePrivacyPolicyBody = <></>;
  let translaterCompany = <></>;
  if (match) {
    languages.forEach(language => {
      if (language.code === match[1] && language.code !== 'ja') {
        i18n.locale = match[1];
        foreignLanguagePrivacyPolicyBody = renderPrivacyPolicyBody();
        translaterCompany = (
          <div className='translater-company'>
            <p>【翻訳会社情報】</p>
            <p>会社名 ： ワールドアイ株式会社</p>
            <p>代表者名 ： 木戸　秀典</p>
            <p>企業HP　：{'https://www.world-i-english.net/english/'}</p>
          </div>
        );
      }
    });
  }
  const noDetailsMatch = window.location.href.match(/no_details=1/);
  return (
    <>
      <DocumentTitle pageNameInTitle={i18n.t('settings.privacyPolicy')} />
      <div
        id='privacy-policy'
        className={
          noDetailsMatch
            ? 'privacy-policy-and-terms no-details'
            : 'privacy-policy-and-terms'
        }
      >
        {noDetailsMatch ? <></> : <Header />}
        <main>
          <link
            href='https://fonts.googleapis.com/css?family=Manrope'
            rel='stylesheet'
          />
          <section>
            {foreignLanguagePrivacyPolicyBody}
            {translaterCompany}
            {privacyPolicyBody}
          </section>
        </main>
        {noDetailsMatch ? <></> : <footer>© Metaps Payment Inc.</footer>}
      </div>
    </>
  );
};

const renderPrivacyPolicyBody = () => {
  const privacyPolicies: any = i18n.t('settings.privacyPolicies');
  return (
    <section>
      <div>
        <h2>{i18n.t('settings.privacyPolicy')}</h2>
      </div>

      {(
        privacyPolicies as {
          title: string;
          content: string;
          list: {
            index: number;
            content: string;
            innerList?: {index: number; content: string}[];
          }[];
          bracketList: {index: number; content: string}[];
        }[]
      ).map(({title, content, list, bracketList}) => (
        <section key={title}>
          <h3>{title}</h3>
          {content.split('\n').map(sentence => (
            <p key={sentence}>{sentence}</p>
          ))}
          <ul className='number'>
            {list.map(row => (
              <li key={row.index}>
                <div>
                  <p>{row.index}.</p>
                  <p>{row.content}</p>
                </div>
                <ul className='bracket'>
                  {(row.innerList || []).map(innerRow => (
                    <li key={innerRow.index}>
                      <div>
                        <p>{innerRow.index}）</p>
                        <p>{innerRow.content}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <ul className='bracket'>
            {bracketList.map(row => (
              <li key={row.index}>
                <div>
                  <p>{row.index}）</p>
                  <p>{row.content}</p>
                </div>
              </li>
            ))}
          </ul>

          <br />
        </section>
      ))}
    </section>
  );
};

export default Component;
