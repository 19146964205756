export const ENTER_PIN = 'ENTER_PIN';
export const ENTER_PIN_SUCCESS = 'ENTER_PIN_SUCCESS';
export const ENTER_PIN_FAILURE = 'ENTER_PIN_FAILURE';

const enterPin = (pin: string) => ({
  type: ENTER_PIN as typeof ENTER_PIN,
  payload: {
    pin,
  },
});

interface EnterPinSuccess {
  type: typeof ENTER_PIN_SUCCESS;
}

interface EnterPinFailure {
  type: typeof ENTER_PIN_FAILURE;
}

export type EnterPinAction =
  | ReturnType<typeof enterPin>
  | EnterPinSuccess
  | EnterPinFailure;

export default enterPin;
