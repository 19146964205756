import './Detail.scss';
import ReactModal from 'react-modal';
import {NotificationItem} from '../../actions/fetchNotifications';
// import WhiteOutButton from '../../components/parts/WhiteOutButton';
import moment from 'moment-strftime';

interface Props {
  notification: NotificationItem | null;
  closeModal: () => void;
}

const NotificationDetail = ({notification, closeModal}: Props) => {
  if (!notification) {
    return <></>;
  }
  const bodies = notification.body.split(/<br>/);
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={true}
      onRequestClose={closeModal}
      contentLabel='Settings'
      overlayClassName='overlay'
      className='notification-detail'
    >
      <section onClick={closeModal}>
        <h2>{notification.title}</h2>
        <p className='date'>
          {moment(notification.publishedAt).strftime('%Y年%m月%d日 %H時%m分')}
        </p>
        {bodies.map(t => (
          <p key={t}>{t}</p>
        ))}
      </section>
    </ReactModal>
  );
};

export default NotificationDetail;
