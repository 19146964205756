export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_LANGUAGE_SUCCESS = 'SET_LANGUAGE_SUCCESS';

export interface Language {
  code: string;
  name: string;
}
export const languages: Language[] = [
  {
    code: 'ja',
    name: '日本語',
  },
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'vi',
    name: 'Tiếng Việt',
  },
];

const setLanguage = (language: Language) => ({
  type: SET_LANGUAGE as typeof SET_LANGUAGE,
  payload: {
    language,
  },
});

interface SetLanguageSuccess {
  type: typeof SET_LANGUAGE_SUCCESS;
  payload: {language: Language};
}

interface SetLanguage {
  type: typeof SET_LANGUAGE;
  payload: {};
}

export type SetLanguageAction =
  | ReturnType<typeof setLanguage>
  | SetLanguageSuccess
  | SetLanguage;

export default setLanguage;
