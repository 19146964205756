import './WhiteOutButton.scss';
import {MouseEvent} from 'react';
import Button from './Button';

const Component = ({
  active,
  onClick,
  children,
}: {
  active?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | null;
  children: string;
}) => {
  if (active === null || active === undefined) {
    active = true;
  }
  return onClick ? (
    <div className={active ? 'whiteout-button' : 'whiteout-button disabled'}>
      <Button onClick={onClick} active={active}>
        {children}
      </Button>
    </div>
  ) : (
    <div className={active ? 'whiteout-button' : 'whiteout-button disabled'}>
      <Button active={active}>{children}</Button>
    </div>
  );
};

export default Component;
