import {AlertConfig} from '../components/parts/Alert';

export const SHOW_ALERT = 'SHOW_ALERT';

const showAlert = (config: AlertConfig) => ({
  type: SHOW_ALERT as typeof SHOW_ALERT,
  payload: {config},
});

export type ShowAlertAction = ReturnType<typeof showAlert>;

export default showAlert;
