import {
  VERIFY_CONFRIRATMION_CODE,
  VERIFY_CONFRIRATMION_CODE_SUCCESS,
  VERIFY_CONFRIRATMION_CODE_ERROR,
  VERIFY_CONFRIRATMION_CODE_EXPIRED,
  VERIFY_CONFRIRATMION_CODE_EXPIRED_CANCEL,
  VERIFY_CONFRIRATMION_CODE_ERROR_CANCEL,
  VerifyConfirmationCodeAction,
} from '../actions/verifyConfirmationCode';

import {
  RECREATE_CONFRIRATMION_CODE,
  RECREATE_CONFRIRATMION_CODE_SUCCESS,
  RECREATE_CONFRIRATMION_CODE_EXPIRED,
  RECREATE_CONFRIRATMION_CODE_EXPIRED_CANCEL,
  ConfirmationCodeRecreateAction,
} from '../actions/recreateConfirmationCode';

import {
  RemoveAuthTokenAction,
  REMOVE_AUTH_TOKEN_COMPLETED,
} from '../actions/removeAuthToken';

export interface ConfirmationCodeState {
  loggedInFromConfirmationCode: boolean;
  showPasscodeDialogFromConfirmationCode: boolean;
  recreatablesAtFromConfirmationCode?: string;
  expiredFromConfirmationCodeRecreate?: boolean;
  clearConfirmationCode?: boolean;
}

const initialState: ConfirmationCodeState = {
  loggedInFromConfirmationCode: false,
  showPasscodeDialogFromConfirmationCode: false,
  clearConfirmationCode: false,
};

const authTokenReducer = (
  state = initialState,
  action:
    | VerifyConfirmationCodeAction
    | ConfirmationCodeRecreateAction
    | RemoveAuthTokenAction
) => {
  switch (action.type) {
    case VERIFY_CONFRIRATMION_CODE:
      return Object.assign({}, state, {});
    case VERIFY_CONFRIRATMION_CODE_SUCCESS:
      return Object.assign({}, state, {
        showPasscodeDialogFromConfirmationCode:
          !action.payload.isAppPasswordSet, // アプリパスワードが設定されていない場合は設定させる
        loggedInFromConfirmationCode: true,
        expiredFromConfirmationCodeRecreate: false,
      });
    case VERIFY_CONFRIRATMION_CODE_ERROR:
      return Object.assign({}, state, {
        clearConfirmationCode: true,
      });
    case VERIFY_CONFRIRATMION_CODE_ERROR_CANCEL:
      return Object.assign({}, state, {
        clearConfirmationCode: false,
      });
    case VERIFY_CONFRIRATMION_CODE_EXPIRED:
      return Object.assign({}, state, {
        expiredFromConfirmationCodeRecreate: true,
      });
    case VERIFY_CONFRIRATMION_CODE_EXPIRED_CANCEL:
      return Object.assign({}, state, {
        expiredFromConfirmationCodeRecreate: false,
      });
    case RECREATE_CONFRIRATMION_CODE:
      return Object.assign({}, state, {});
    case RECREATE_CONFRIRATMION_CODE_SUCCESS:
      return Object.assign({}, state, {
        expiredFromConfirmationCodeRecreate: false,
        recreatablesAtFromConfirmationCode: action.payload.recreatablesAt,
      });
    case RECREATE_CONFRIRATMION_CODE_EXPIRED:
      return Object.assign({}, state, {
        expiredFromConfirmationCodeRecreate: true,
      });
    case RECREATE_CONFRIRATMION_CODE_EXPIRED_CANCEL:
      return Object.assign({}, state, {
        expiredFromConfirmationCodeRecreate: false,
      });

    case REMOVE_AUTH_TOKEN_COMPLETED:
      return Object.assign({}, state, {
        loggedInFromConfirmationCode: false,
      });
    default:
      return state;
  }
};

export default authTokenReducer;
