import ReportError from './reportError';

export const VERIFY_CONFRIRATMION_CODE = 'VERIFY_CONFRIRATMION_CODE';
export const VERIFY_CONFRIRATMION_CODE_SUCCESS =
  'VERIFY_CONFRIRATMION_CODE_SUCCESS';
export const VERIFY_CONFRIRATMION_CODE_FAILURE =
  'VERIFY_CONFRIRATMION_CODE_FAILURE';
export const VERIFY_CONFRIRATMION_CODE_ERROR =
  'VERIFY_CONFRIRATMION_CODE_ERROR';
export const VERIFY_CONFRIRATMION_CODE_ERROR_CANCEL =
  'VERIFY_CONFRIRATMION_CODE_ERROR_CANCEL';
export const VERIFY_CONFRIRATMION_CODE_EXPIRED =
  'VERIFY_CONFRIRATMION_CODE_EXPIRED';
export const VERIFY_CONFRIRATMION_CODE_EXPIRED_CANCEL =
  'VERIFY_CONFRIRATMION_CODE_EXPIRED_CANCEL';

export interface Meta {
  routeOnSuccess: {pathname?: string};
}

const verifyConfirmationCode = (confirmationCode: string, meta: Meta) => ({
  type: VERIFY_CONFRIRATMION_CODE as typeof VERIFY_CONFRIRATMION_CODE,
  payload: {
    confirmationCode,
  },
  meta,
});

export const verifyConfirmationCodeExpireCancel = () => ({
  type: VERIFY_CONFRIRATMION_CODE_EXPIRED_CANCEL as typeof VERIFY_CONFRIRATMION_CODE_EXPIRED_CANCEL,
  payload: {},
});

export const verifyConfirmationCodeErrorCancel = () => ({
  type: VERIFY_CONFRIRATMION_CODE_ERROR_CANCEL as typeof VERIFY_CONFRIRATMION_CODE_ERROR_CANCEL,
  payload: {},
});

export interface VerifyConfirmationCodeSuccess {
  type: typeof VERIFY_CONFRIRATMION_CODE_SUCCESS;
  payload: {
    authToken: string;
    isAppPasswordSet: boolean;
  };
  meta: Meta;
}

interface VerifyConfirmationCodeExpired {
  type: typeof VERIFY_CONFRIRATMION_CODE_EXPIRED;
  payload: {};
}

interface VerifyConfirmationCodeExpiredCancel {
  type: typeof VERIFY_CONFRIRATMION_CODE_EXPIRED_CANCEL;
  payload: {};
}

interface VerifyConfirmationCodeFailure {
  type: typeof VERIFY_CONFRIRATMION_CODE_FAILURE;
  payload: {};
}

interface VerifyConfirmationCodeErrorCancel {
  type: typeof VERIFY_CONFRIRATMION_CODE_ERROR_CANCEL;
  payload: {};
}

export type VerifyConfirmationCodeAction =
  | ReturnType<typeof verifyConfirmationCode>
  | VerifyConfirmationCodeSuccess
  | VerifyConfirmationCodeFailure
  | VerifyConfirmationCodeExpired
  | VerifyConfirmationCodeExpiredCancel
  | VerifyConfirmationCodeErrorCancel
  | ReportError<typeof VERIFY_CONFRIRATMION_CODE_ERROR>;

export default verifyConfirmationCode;
