import {takeEvery, call} from 'redux-saga/effects';
import * as ReactGA from 'react-ga';
import {INCREASE_AMOUNT} from '../actions/increaseAmount';
import {DECREASE_AMOUNT} from '../actions/decreaseAmount';
import {CHANGE_PAYMENT_METHOD} from '../actions/changePaymentMethod';
import {OPEN_PASSCODE_DIALOG} from '../actions/openPasscodeDialog';
import {CLOSE_APPLICATION_RESULT} from '../actions/closeApplicationResult';
import {
  FETCH_USER_INFO_SUCCESS,
  FetchUserInfoSuccess,
} from '../actions/fetchUserInfo';
import {PASS_APP_PASSWORD} from '../actions/passAppPassword';
import {OPEN_HISTORY_DETAIL} from '../actions/openHistoryDetail';
import {CLOSE_HISTORY_DETAIL} from '../actions/closeHistoryDetail';
import {REQUEST_CUSTOMER_NO_REMINDER_SUCCESS} from '../actions/requestCustomerNoReminder';
import {REQUEST_PASSWORD_RESET_SUCCESS} from '../actions/requestPasswordReset';
import {
  FETCH_AUTH_TOKEN_FAILURE,
  FETCH_AUTH_TOKEN_SUCCESS,
} from '../actions/fetchAuthToken';
import {AUTHENTICATION_FAILURE_COMPLETE} from '../actions/authFailure';
import {
  SendEventAnalyticsAction,
  SEND_EVENT_ANALYTICS,
} from '../actions/sendEventAnalytics';
import {SetAnalyticsAction, SET_ANALYTICS} from '../actions/setAnalytics';
import {APPLY_PREPAYMENT_SUCCESS} from '../actions/applyPrepayment';
import {SELECT_FRANCHISE_STORE} from '../actions/selectFranchiseStore';

function* sendEventAnalytics(action: SendEventAnalyticsAction) {
  yield call(() => {
    ReactGA.event({
      category: action.payload.category,
      action: action.payload.action,
    });
  });
}

function* setAnalytics(action: SetAnalyticsAction) {
  yield call(() => {
    ReactGA.set(action.payload);
  });
}

// login
function* requestCustomerNoReminderAnalytics() {
  yield call(() => {
    ReactGA.event({
      category: 'Login',
      action: 'request customer no reminder',
    });
  });
}

function* requestPasswordResetAnalytics() {
  yield call(() => {
    ReactGA.event({
      category: 'Login',
      action: 'request password reset',
    });
  });
}

function* loginAnalytics() {
  yield call(() => {
    ReactGA.event({
      category: 'Login',
      action: 'login success',
    });
  });
}

function* loginFailureAnalytics() {
  yield call(() => {
    ReactGA.event({
      category: 'Login',
      action: 'login failure',
    });
  });
}

function* authFailureAnalytics() {
  yield call(() => {
    ReactGA.event({
      category: 'Login',
      action: 'auth failure',
    });
  });
}

// franchise store
function* selectFranchiseStoreAnalytics() {
  yield call(() => {
    ReactGA.event({
      category: 'FranchiseStore',
      action: 'franchise store select',
    });
  });
}

// history
function* openHistoryDetailAnalytics() {
  yield call(() => {
    ReactGA.event({
      category: 'History',
      action: 'history list item click',
    });
  });
}

function* closeHistoryDetailAnalytics() {
  yield call(() => {
    ReactGA.event({
      category: 'History',
      action: 'history detail close button click',
    });
  });
}

// home
function* increaseAmountAnalytics() {
  yield call(() => {
    ReactGA.event({
      category: 'Home',
      action: 'home increase button click',
    });
  });
}

function* decreaseAmountAnalytics() {
  yield call(() => {
    ReactGA.event({
      category: 'Home',
      action: 'home decrease button click',
    });
  });
}

function* changePaymentMethodAnalytics() {
  yield call(() => {
    ReactGA.event({
      category: 'Home',
      action: 'payment method tab click',
    });
  });
}

function* decideConfirmDialogAnalytics() {
  yield call(() => {
    ReactGA.event({
      category: 'Home',
      action: 'home decide button click',
    });
  });
}

function* applyPrepaymentAnalytics() {
  yield call(() => {
    ReactGA.event({
      category: 'Home',
      action: 'prepayment apply',
    });
  });
}

function* closeApplicationResultAnalytics() {
  yield call(() => {
    ReactGA.event({
      category: 'Home',
      action: 'home back button click',
    });
  });
}

// user info
function* setUserProperty(action: FetchUserInfoSuccess) {
  yield call(() => {
    const userInfo = action.payload.user;
    ReactGA.set({
      user_id: userInfo.appUserKey.toString(),
      company_code: action.payload.user.companyCode,
      franchise_store_name: action.payload.user.franchiseStoreName,
    });
  });
}

// app password
function* passAppPasswordAnalytics() {
  yield call(() => {
    ReactGA.event({
      category: 'AppPassword',
      action: 'app password success',
    });
  });
}

function* analyticsSaga() {
  if (process.env.REACT_APP_ANALYTICS_ENABLED !== 'true') {
    return;
  }

  yield takeEvery(SEND_EVENT_ANALYTICS, sendEventAnalytics);
  yield takeEvery(SET_ANALYTICS, setAnalytics);

  yield takeEvery(
    REQUEST_CUSTOMER_NO_REMINDER_SUCCESS,
    requestCustomerNoReminderAnalytics
  );
  yield takeEvery(
    REQUEST_PASSWORD_RESET_SUCCESS,
    requestPasswordResetAnalytics
  );
  yield takeEvery(FETCH_AUTH_TOKEN_SUCCESS, loginAnalytics);
  yield takeEvery(FETCH_AUTH_TOKEN_FAILURE, loginFailureAnalytics);
  yield takeEvery(AUTHENTICATION_FAILURE_COMPLETE, authFailureAnalytics);

  yield takeEvery(SELECT_FRANCHISE_STORE, selectFranchiseStoreAnalytics);

  yield takeEvery(OPEN_HISTORY_DETAIL, openHistoryDetailAnalytics);
  yield takeEvery(CLOSE_HISTORY_DETAIL, closeHistoryDetailAnalytics);

  yield takeEvery(INCREASE_AMOUNT, increaseAmountAnalytics);
  yield takeEvery(DECREASE_AMOUNT, decreaseAmountAnalytics);
  yield takeEvery(CHANGE_PAYMENT_METHOD, changePaymentMethodAnalytics);
  yield takeEvery(OPEN_PASSCODE_DIALOG, decideConfirmDialogAnalytics);
  yield takeEvery(APPLY_PREPAYMENT_SUCCESS, applyPrepaymentAnalytics);
  yield takeEvery(CLOSE_APPLICATION_RESULT, closeApplicationResultAnalytics);

  yield takeEvery(FETCH_USER_INFO_SUCCESS, setUserProperty);

  yield takeEvery(PASS_APP_PASSWORD, passAppPasswordAnalytics);
}

export default analyticsSaga;
