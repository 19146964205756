import {AlertConfig} from '../components/parts/Alert';
import ReportError from './reportError';

export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_ERROR = 'REQUEST_PASSWORD_RESET_ERROR';

interface Meta {
  alertConfig: AlertConfig;
}

const requestPasswordReset = (customerNo: string, meta?: Meta) => ({
  type: REQUEST_PASSWORD_RESET as typeof REQUEST_PASSWORD_RESET,
  payload: {
    customerNo,
  },
  meta,
});

export interface RequestPasswordResetSuccess {
  type: typeof REQUEST_PASSWORD_RESET_SUCCESS;
  meta: Meta;
}

export type RequestPasswordResetAction =
  | ReturnType<typeof requestPasswordReset>
  | RequestPasswordResetSuccess
  | ReportError<typeof REQUEST_PASSWORD_RESET_ERROR>;

export default requestPasswordReset;
