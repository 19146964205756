import './MiniSubmitButton.scss';
import Button, {Props} from './Button';

const Component = ({onClick, children, active}: Props) => {
  return (
    <div className='mini-submit-button'>
      <Button onClick={onClick} active={active}>
        {children}
      </Button>
    </div>
  );
};
export default Component;
