const dictionary = {
  common: {
    customerNumber: 'Customer Number',
    customerNumberAnd8: 'Customer Number(8 digit number)',
    password: 'Password',
    version: 'Version',
    update: 'Update',
    userInfo: 'User Info',
    withdrawalAmount: 'Withdrawal amount',
    charge: 'Service charges',
    systemUsageFee: 'System usage fee',
    transferFee: 'Transfer fee',
    bank: 'Bank',
    branch: 'Branch',
    accountType: 'Account Type',
    savingsAccount: 'Savings Account',
    checkingAccount: 'Checking Account',
    accountNumber: 'Account number',
    expectedTransferDate: 'Expected transfer date',
    partnerCode: 'Partner code',
    confirmationNumber: 'Confirmation number',
    requestNumber: 'Request Number',
    deadline: 'Deadline for withdrawal',
    yen: 'yen',
    language: 'language',
  },
  button: {
    login: 'Log in',
    send: 'Send',
    close: 'Close',
    delete: 'Delete',
    cancel: 'Cancel',
    request: 'Request',
    confirm: 'Confirm',
    back: 'Back',
    change: 'Change',
    next: 'Next',
  },
  login: {
    linkCustomerNumberReminder: 'Forgot your Customer Number?',
    linkCustomerNumberReminderTitle: 'Re-notification of customer number',
    linkPasswordReset: 'Forgot your password?',
    linkPasswordResetTitle: 'Password Reset',
  },
  customerNoReminder: {
    title: 'Re-notification of customer number',
    description:
      'Customer number information will be sent to the registered email address or SMS',
    contact: 'Email address or phone number',
    linkPasswordReminder: 'Click here if you forgot your password',
    linkLogin: 'Back to Login',
    failureTitle: 'information could not be sent',
    failureDescription:
      'The input contents may be incorrect or may not be registered. Please check the details and enter again. For confirmation of registration information, please contact your affiliated company. ',
  },
  customerNoReminderDone: {
    title: 'Sent',
    description:
      'instruction of customer number was sent. If you do not receive an email or message (SMS) within a few minutes, please check your registration information with your affiliated company.',
    resend: 'Resend',
    linkLogin: 'Return to login screen ',
  },
  passwordReset: {
    title: 'Password reset',
    description:
      'Please enter your customer number. A password reset notice will be sent to your registered email address or SMS.',
    linkLogin: 'Return to login screen',
    linkCustomerNumberReminder: 'Click here if you forgot your password',
    failureTitle: 'Customer number has not been registered ',
    failureDescription:
      'Please check and enter the number again. If your customer number has not been issued, please contact your affiliated company. ',
  },
  passwordResetDone: {
    title: 'Sent',
    description:
      'instruction of customer number was sent. If you do not receive an email or message (SMS) within a few minutes, please check your registration information with your affiliated company.',
    resend: 'Resend',
    linkLogin: 'Return to login screen',
  },
  appPassword: {
    setUp: 'Set up app password',
    annotation: `To ensure higher security,please choose a 4-digit password for this app.${'\n'}*You will need this password to log in and make requests.${'\n'}*Biometric authentication is given priority if enabled.`,
    enter: 'Enter app password',
    set: 'Set password',
    bioAuth: 'You can use bio auth',
    input: 'Input app password',
    inputPasscode: 'Input your previous password',
    forgotPasscode: 'Click here if you forgot your app password ',
    error: 'The password is incorrect',
    errorInputPasscode: 'Enter the set password ',
    reset: 'Reset your app password and log out ',
    resetNextLogin: 'Remember to reset for the next login',
    goBack: 'Return to the input screen',
    lock: 'Password is being locked temporarily due to authentication failure  ',
    later: 'Return to the input screen after 30 seconds ',
    notMatch: 'Passwords do not match',
    incorrect: 'Incorrect password',
    lockAnnotation:
      'Unable to verify. Account has been locked. Please try again in 30 seconds.',
    resetConfirm: 'Reset app password?',
    resetSuccess:
      'App password reset successfully. Please log in again and create a new password.',
  },
  fingerprint: {
    verify: 'Verify using device',
    userIdentification: 'User identification',
    touch: 'Touch the fingerprint sensor',
    notMatch: 'Does not match',
    enterAppPassword: 'Enter app password',
  },
  application: {
    requestLimit: 'Request limit',
    requestAnnotation: 'Amount registration',
    selectAnnotation: 'Method of receiving payment',
    transferToBankAccount: 'Transfer to bank account',
    receiveAtSeven: 'Receive at 7-Eleven Bank ATM',
    receiveAtSeven2: 'Receive in cash at 7-Eleven Bank ATM',
    bankAccountAnnotation:
      'The amount will be transferred to the following account.',
    sevenReceiveAnnotation:
      'Enter this number along with the confirmation number that will be displayed after your request is made into a Seven Bank ATM to receive your money.',
    transferAnnotation:
      'Requests made after 8 a.m. will be processed the next business day.',
    sevenAnnotation:
      'Confirmation numbers for 7-Eleven withdrawal requests can be viewed in History and Details.',
    requestAdvancePayment: 'Request',
    withCharge: 'Fees included',
    recipient: 'Recipient',
    switchCompany: 'Switch affiliated company',
    updateButtonDialog:
      'Your affiliated company will set and update the maximum registration amount. If the amount is not updated even if you press the update button, please contact your company ',
  },
  confirm: {
    annotation: 'Make a request for advance payment as below',
    sevenAnnotation: 'Receive cash from 7-Eleven ATM',
  },
  result: {
    annotation: 'Advance payment registration was accepted',
    thanks: 'Thank you for using our services',
    confirmation:
      ' Required information for this transaction can be checked at any time from the history',
    withdrawalMethod: 'Withdrawal method',
  },
  history: {
    history: 'History',
    totalAmount: 'Total Amount',
    requestNo: 'Request No.',
    amountDeducted: 'Amount deducted from pay',
    details: 'Details',
    status: 'Status',
    waiting: 'Waiting for transfer',
    reversing: 'Transfer error',
    transferComplete: 'Transfer complete',
    awating: 'Awaiting withdrawal',
    expired: 'Expired',
    withdrawalComplete: 'Withdrawal complete',
    date: 'Date of request',
    method: 'Withdrawal method',
    bank: 'Bank name',
    branch: 'Branch name',
    transferDate: 'Date of transfer',
    transfer: 'Bank transfer',
    seven: '7-Eleven Bank ATM',
    requestAmount: 'Request Amount',
    receivedYet: 'Unpaid payment has been registered',
    includeFee: 'fees included',
  },
  notification: {
    notice: 'Notice',
    detail: 'Detail',
    company: 'Company',
    cria: 'CRIA',
    emptyCompany:
      'The maximum registration amount after being updated will be displayed here ',
    emptyCria: 'Notifications from CRIA will be displayed here ',
  },
  settings: {
    settings: 'Settings',
    account: 'Account',
    logout: 'Log Out',
    infomation: 'Information',
    withdrawalMethod: 'How to Withdraw Cash from 7-Eleven',
    termOfUse: 'Terms of Use',
    needAgreeWithTheTerms: 'Please agree to the Terms of Use',
    agreeWithTheTermsFailure: 'Failed to agree.',
    agreeWithTheTerms: 'Agree',
    disagreeWithTheTerms: 'Disagree',
    termOfUseFirstSentence:
      'This "[Immediate Payroll Payment "CRIA"] Terms of Use (For Users)" (hereinafter referred to as the "Terms") sets forth the conditions and compliance matters regarding the use of "Immediate Payroll Payment "CRIA"" (hereinafter referred to as the "Service") operated and provided by Metaps Payment Inc. (hereinafter referred to as either “Company”, “We”, “Us” or “Our”).The User (as defined in Article 3) shall use the Service in advance upon consenting to the Terms, and the contents of the Terms shall form part of the agreement between the User and the Company regarding the Service. ',
    termOfUses: [
      {
        title: 'Article 1 (Application)',
        content:
          'The Terms apply to all types of usage of the Service conducted by the user. The Company may, without departing from the spirit of the Terms, set forth separately the detailed rules, etc. regarding the use of the Service, and such detailed rules, etc. shall constitute a part of the Contract (as defined in Article 4 paragraph 3) after the establishment of the Contract.',
        list: [],
        bracketList: [],
      },
      {
        title: 'Article 2 (The Service)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' This Service, as part of the administrative work we have been commissioned separately from affiliated companies (defined in Article 3), provides a system for payout before the normal payment date with regard to wages, etc. (lump sum payment such as bonuses, and retirement benefits are not included. Hereinafter referred to as "wages, etc.") for users to receive payments from affiliated companies, within the limits set by affiliated companies, according to the user\'s wishes.',
          },
          {
            index: 2,
            content:
              ' The amount of money that the user can receive payment from this Service is within the range separately specified by the affiliated company (hereinafter referred to as "application limit") from the monetary amount of wages, etc. that has already been generated as receivable as a result of the user working at the affiliated company. Wages, etc. that have not been settled as wage obligations for users of affiliated companies due to reasons such as being prior to employment are not eligible for this Service.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Article 3 (Definition)',
        content:
          'The following terminology used in the Terms shall have the meanings specified in the respective items.',
        list: [],
        bracketList: [
          {
            index: 1,
            content:
              ' "Affiliated company" refers to a corporation that has introduced this Service by entering into an agreement (hereinafter referred to as "Affiliated Company Contract") with the Company regarding the use of this Service.',
          },
          {
            index: 2,
            content:
              ' “User" refers to an individual who works in an affiliated company and has completed the user registration in accordance with Article 4 based on the approval concerning the use of this Service by the affiliated company.',
          },
          {
            index: 3,
            content:
              ' "Designated application" refers to a computer application for the user to use this Service, which We will provide to the user.',
          },
        ],
      },
      {
        title: 'Article 4 (User Registration)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' In order to use the Service, users must first complete the user registration. The user registration shall be performed according to the notice to the user from the Company based on the designation of the affiliated company, by downloading the designated application and conducted from the designated application.',
          },
          {
            index: 2,
            content:
              ' Those who will register as a user shall apply for a user registration upon agreeing to the Terms.',
          },
          {
            index: 3,
            content:
              ' When the registration for the preceding two paragraphs is completed, a contract for the use of the Service under the Terms (hereinafter referred to as "Contract") shall be concluded between the person who conducted user registration and the Company.',
          },
          {
            index: 4,
            content:
              ' The financial institution account for receiving payments based on this Service will be the account that the affiliated company notified the Company as an account registered by the user as a payroll account for the affiliated company. Upon changing this account, the user shall perform the necessary procedures with the affiliated company.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Article 5 (How to Use)',
        content: '',
        list: [
          {
            index: 1,
            content:
              " A designated application is required to use this Service. The user shall download the designated application from the designated site or a website that we separately approve, and install it on their own device such as smartphones. Note that the provision and maintenance of smartphones and other devices necessary to receive the provision of this Service, communication lines and other communication environments shall be conducted at the expense and responsibility of the user, and users shall take security measures such as prevention of virus infection and prevention of unauthorized access and information leakage at their own expense and responsibility, according to the usage environment of the Service. In addition, when installing software etc. on the user's computer or other device by downloading the application or other methods when the user starts using the Service or while using the Service, sufficient care shall be taken not to cause the disappearance or modification of the information held by the user or the failure or damage of the equipment, as we will not be responsible for any damages incurred to the user.",
          },
          {
            index: 2,
            content:
              ' When using the Service, the user will start the specified application, logs in by inputting authentication information such as ID and password in the designated application, and enter the monetary amount to be applied for using the Service (hereinafter referred to as "applied payment amount") and other necessary matters shall be input on the predetermined form in the designated application, and apply for payout. The applied payment amount shall be the sum of the amount the user applies to the Company for advanced payment as a wage and the usage fee of the Service as defined in the following Article.',
          },
          {
            index: 3,
            content:
              ' The Company will confirm the contents entered in the preceding paragraph, confirm that it is a proper application, and will do payout as an advanced payment as a business agent commissioned by the affiliated company. Note that We will confirm that the ID and password entered when logging in to the specified application by the user match the ID and password of the user registered with the Company. If we confirm that the information match, we will treat the application described in the previous paragraph as made from the user.',
          },
          {
            index: 4,
            content:
              ' The applied payment amount must be within the application limit at that time, and the minimum unit will be 1,000 yen. In addition, the Company may impose certain restrictions on the number of applications for usage per fixed period, the monetary amount able to be applied, etc.',
          },
          {
            index: 5,
            content:
              ' At the same time the payment is made as a prepayment of wages, the amount used is notified to the affiliated companies, and the affiliated companies treat the amount paid out by the company (a portion equivalent to the amount of the wages excluding the usage fees in the following article from the applied payment amount) as prepaid wages as if the wages etc. were paid. The user will agree to the affiliated company that the amount will be deducted on the prescribed payment date. Notwithstanding the preceding paragraphs, if the following items apply, the Company may refuse the user from using the Service.                    ',
            innerList: [
              {
                index: 1,
                content:
                  ' When the financial institution account notified by the affiliated company to the Company as a payee account is subject to foreclosure or temporary foreclosure, or closed',
              },
              {
                index: 2,
                content:
                  ' When the affiliated company has suspended payment, or when it is determined by the Company that the economic condition of other affiliated companies has significantly deteriorated',
              },
              {
                index: 3,
                content: " When the user's wage claim is seized",
              },
              {
                index: 4,
                content:
                  ' When it cannot be handled due to a power failure or accident',
              },
              {
                index: 5,
                content:
                  ' When the affiliated company requests the Company to stop serving the user',
              },
              {
                index: 6,
                content:
                  ' When we can refuse the use of this Service to the user based on the affiliated company contract',
              },
              {
                index: 7,
                content:
                  ' When we judge that there are other unavoidable circumstances and that the handling is inappropriate or impossible',
              },
            ],
          },
          {
            index: 6,
            content:
              ' If it takes time for confirmation based on paragraph 3 or if usage refusal based on the preceding paragraph is made, the use of this Service by the user may be delayed or the user may not be able to use this Service. Even if damage occurs in those circumstances, we shall not be liable.',
          },
          {
            index: 7,
            content:
              ' If an overpayment occurs in the amount paid out due to reasons that cannot be returned to the responsibility of the affiliated company, the user shall refund the Company for the amount corresponding to the overpayment.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Article 6 (Usage Fee)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' The user shall separately pay a usage fee for the use of this Service, which is separately determined by Us and presented in advance through designated applications, etc.',
          },
          {
            index: 2,
            content:
              ' The Company shall be able to receive the usage fee specified in the preceding paragraph and the consumption tax equivalent amount related to it regarding the payout to the user based on the preceding Article, paragraph 3.',
          },
          {
            index: 3,
            content:
              ' The usage fee based on the preceding paragraph and the amount paid out by the Company as an advanced payment will be displayed on the designated application each time the application for payout is made. The user will confirm the amount and conduct an explicit agreement to it prior to proceeding to apply for the payout. "Explicit agreement" in this section shall be made possible by the user clicking a button (link) such as "Agree" or "Accept" to the content displayed on the dedicated application.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Article 7 (Receiving Payments from Affiliated Companies, etc.)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' The Company shall be able to receive payment from the affiliated companies for the amount paid out to the user as advance payment of the wage under Article 5 paragraph 3 and the total amount of fees paid by the user for the use of the Service based on the preceding Article. The user shall consent for the affiliated company to deduct this total amount upon being paid.',
          },
          {
            index: 2,
            content:
              ' By conducting payout as an advanced payment of wages through temporary payment from the Company based on Article 5 (3), the Company will substitute the claims for wages the user who is the subject of the said payment owns to the affiliated company for the amount equivalent to the amount paid out (a part equivalent to the monetary amount of wages excluding the usage fee under paragraph 1 of the preceding Article from the amount of money applied by the user). In this case, to the extent that the Company can request the affiliated company to reimburse and pay for the amount of payment made as advance payment of wages based on the preceding paragraph, the Company shall be able to exercise all rights held by the user as effectiveness and collateral of the said claim for wages alone, and the user will agree and consent to the exercise of such claims by the Company.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Article 8 (Duty of Users, Prohibited Acts, etc.)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' The user will state and guarantee that all information submitted to the Company and its affiliated companies in registering for use of the Service is accurate and that there are no errors in important points.',
          },
          {
            index: 2,
            content:
              ' The user shall use the Service with the due care of a prudent manager.',
          },
          {
            index: 3,
            content:
              ' The user shall manage the authentication information such as ID and password (hereinafter referred to as "account information") necessary for using this Service as a secret and handle it so as not to leak elsewhere. We will assume the responsibility of damages caused due to poor management of account information, usage error, use by a third party, etc. is the responsibility of the user, and we will not take responsibility.',
          },
          {
            index: 4,
            content:
              ' If the user finds that the account information has been used illegally by a third party, or if the user finds that there is a risk, the user will immediately notify Us and follow Our instructions.',
          },
          {
            index: 5,
            content:
              ' The User shall not perform the following acts in relation to the use of the Service.                    ',
            innerList: [
              {
                index: 1,
                content:
                  " Have a third party use this Service using the user's name",
              },
              {
                index: 2,
                content:
                  ' Use the designated sites and other websites related to the Service, or applications provided by the Company for the use of the Service, etc. for any purpose other than the use of this Service, or attempt unauthorized access',
              },
              {
                index: 3,
                content:
                  " Using the Service in any manner that violates the law or public order and morals, an act related to a criminal act, an act that interferes with the operation of the Company's service, or any aspect contrary to the spirit of the Service",
              },
              {
                index: 4,
                content: ' Other acts that the Company deems inappropriate',
              },
            ],
          },
          {
            index: 6,
            content:
              " In the case where a dispute arises between a user and an affiliated company or a third party regarding the user's use of the Service, or the debt-involvement relationship or labor-management relationship pertaining to the Service, the user shall solve this in their own responsibility and costs, and we shall defend our company against such disputes.",
          },
        ],
        bracketList: [],
      },
      {
        title: 'Article 9 (Suspending of this Service)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' The Company shall be able to temporarily suspend the provision of the Service after notifying the user in advance, for reasons such as inspection and maintenance of the system and facilities (hereinafter referred to as "facilities, etc.") for operating the Service. In addition, in the following cases, provision of the Service may be suspended without the need for prior notice.                    ',
            innerList: [
              {
                index: 1,
                content:
                  ' Cases where an unexpected failure or the like occurs in facilities etc. and it is necessary to prevent the occurrence or expansion of damage',
              },
              {
                index: 2,
                content:
                  ' Cases where it is judged that the facilities, etc. are under attack from a third party and it is necessary to maintain things such as security of information',
              },
              {
                index: 3,
                content:
                  ' Cases where the Service cannot be operated due to fire, blackout, natural disaster, or other force majeure (meaning an event exceeding the reasonable control of the concerned parties. The same shall apply hereinafter)',
              },
              {
                index: 4,
                content:
                  ' Other cases where the implementation of maintenance etc. is urgently needed for normal operation of the Service, maintenance of security of information, etc.',
              },
            ],
          },
          {
            index: 2,
            content:
              ' If the Company determines that the operation of the Service has become difficult due to changes in laws or its interpretation guidelines, etc. after the establishment of the Contract, the Company shall be able to change the content of the Service or stop the operation of the Service.',
          },
          {
            index: 3,
            content:
              ' The Company shall not be liable for any damages whatsoever concerning the partial change or suspension of the Service under the preceding two paragraphs.',
          },
        ],
        bracketList: [],
      },
      {
        title: "Article 10 (Company's Responsibility)",
        content: '',
        list: [
          {
            index: 1,
            content:
              ' Other than the contents set forth in these Terms, the Company shall operate the Service with the due care of a prudent manager.',
          },
          {
            index: 2,
            content:
              ' We do not guarantee that the Service will achieve a specific purpose for the user.',
          },
          {
            index: 3,
            content:
              ' The applications, etc. provided by the Company in relation to the Service are provided on an "as is" basis in principle, and the Company does not guarantee any functions, etc. of the application or the absence of bugs, etc.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Article 11 (Information Management)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' The Company will use the personal information (including information on the payout status) of the user acquired along with the operation of the Service only within the scope of the purpose of use specified on the designated site. When the individual consent of the user is obtained separately, the information shall be available for the purpose of use for which the consent was obtained.',
          },
          {
            index: 2,
            content:
              " In addition to the preceding paragraph, the handling of personal information shall be in accordance with the laws and regulations and the Company's personal information protection policy.",
          },
          {
            index: 3,
            content:
              " Even in cases where we have stored information about the user's use of the Service for a certain period of operation, we have no obligation to store such information, and we shall be able to delete such information at any time. In addition, we will not take any responsibility for the damages caused to the user based on the deletion of such information.",
          },
        ],
        bracketList: [],
      },
      {
        title: 'Article 12 (Communication and Notification)',
        content:
          'Users and the Company shall communicate with each other and notify each other in accordance with the Contract in a manner separately designated by the Company. Communications and notices not in accordance with the said method will in principle be invalid unless the recipient accepts it. In addition, the Company shall be able to use the method of posting on the designated application as a method of contacting users.',
        list: [],
        bracketList: [],
      },
      {
        title: 'Article 13 (Intellectual Property Rights, etc.)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' With regard to the systems, websites, applications (including designated applications), etc., provided by our company regarding the operation of this Service, all intellectual property rights including patent rights, utility model rights, design rights, copyrights, trademarks, etc., (including the right to register applications for these) belong to Us or Our affiliated companies.',
          },
          {
            index: 2,
            content:
              ' Users shall not decompile, reverse engineer, disassemble, etc. the applications (including designated applications) provided by our company regarding the use of the Service. In addition, the application, etc. cannot be used for any purpose other than using the Service.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Article 14 (Confidentiality)',
        content: '',
        list: [
          {
            index: 1,
            content:
              " The user and the Company will manage confidential information (hereinafter referred to as “Confidential Information”) of the other party's technology, sales, and other information obtained through the performance of this Contract as confidential, and not only during the term of this Contract but also after its termination, without prior consent of the other party in writing, will not disclose or leak the information to any third party excluding Company officers, employees, or an expert such as lawyers or an accountant concerned with the performance of this Contract, and in addition, the information shall not be used for purposes other than fulfillment of this Contract.",
          },
          {
            index: 2,
            content:
              ' Notwithstanding the provisions of paragraph 1, the information corresponding to any of the following items shall not be subject to confidentiality obligations.                    ',
            innerList: [
              {
                index: 1,
                content:
                  ' The information was already publicly known before the concerned party obtained the information',
              },
              {
                index: 2,
                content:
                  ' The information became publicly known after the concerned party obtained the information regardless of their responsibility',
              },
              {
                index: 3,
                content:
                  ' The information was self-legitimately owned before the concerned party obtained the information',
              },
              {
                index: 4,
                content:
                  ' The information was obtained legally without confidentiality from a third party with legitimate authority',
              },
              {
                index: 5,
                content:
                  ' The information was independently developed by the concerned party without reference to Confidential Information',
              },
            ],
          },
        ],
        bracketList: [],
      },
      {
        title: 'Article 15 (Revision of the Terms, etc.)',
        content:
          'The Company shall be able to revise the Terms and to suspend the Service by notifying to the user one month before the date of implementation of the change by writing (including e-mail), designated application, posting on our prescribed website, and other methods by which the user can reasonably know the contents of the change. In addition, with regard to the revision of these Terms, if the user uses the Service after the revision of these Terms, the user is deemed to have agreed to the modification of these Terms.',
        list: [],
        bracketList: [],
      },
      {
        title: 'Article 16 (Compliance with Laws and Regulations)',
        content:
          'Users and the Company shall comply with the relevant laws, cabinet orders, ministerial orders, ordinances, rules, and orders, etc. in relation to the performance of this Contract.',
        list: [],
        bracketList: [],
      },
      {
        title: 'Article 17 (Transfer of Rights, etc.)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' The User may not transfer their position in this Contract to any third party without the prior written consent of the Company.',
          },
          {
            index: 2,
            content:
              ' Users shall not transfer or reimburse claims against the Company under this Contract to a third party, or use them for pledged security without the prior written consent of the Company.',
          },
          {
            index: 3,
            content:
              ' In the event where we transfer the business (it does not matter whether business transfer, company split or any other aspect) related to this Service to a third party, with regard to the assignment, the status on this Contract, the rights and obligations under this agreement, the personal information, and other customer information of the user may be transferred to the assignee of the assignment, and the user is deemed to have agreed in advance to such transfers under this section.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Article 18 (Elimination of Antisocial Forces)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' The user guarantees that they do not currently fall under any of the antisocial forces listed in the following items, and also promises to not fall under these in the future.                    ',
            innerList: [
              {
                index: 1,
                content: ' An organized crime group',
              },
              {
                index: 2,
                content: ' A member of an organized crime group',
              },
              {
                index: 3,
                content: ' A quasi-member of an organized crime group',
              },
              {
                index: 4,
                content:
                  ' Those who have not been five years since they no longer became an organized crime group or a member of an organized crime group',
              },
              {
                index: 5,
                content:
                  ' A related company or association of an organized crime group',
              },
              {
                index: 6,
                content:
                  ' A corporate racketeer, a criminal group posing as social movements or special intelligence crime group',
              },
              {
                index: 7,
                content:
                  ' Other equivalent person or organization of any category above',
              },
            ],
          },
          {
            index: 2,
            content:
              ' The user guarantees that they do not have a relationship with those who are in close relations with antisocial forces or antisocial forces mentioned in the preceding paragraph (hereinafter referred to as "antisocial forces etc.") that falls under any of the following items, and promises that they will not have such a relationship in the future.                    ',
            innerList: [
              {
                index: 1,
                content:
                  ' Relationship in which the management is controlled by antisocial forces, etc.',
              },
              {
                index: 2,
                content:
                  ' Relationship in which antisocial forces etc. are substantially involved in their management',
              },
              {
                index: 3,
                content:
                  ' Relationship in which funds or convenience are provided to anti-social forces',
              },
              {
                index: 4,
                content:
                  ' Other relationships with antisocial forces, etc. that should be criticized by the society',
              },
            ],
          },
          {
            index: 3,
            content:
              ' The user promises that they will not perform any of the following acts.                    ',
            innerList: [
              {
                index: 1,
                content: ' A demand with violence',
              },
              {
                index: 2,
                content: ' An unreasonable demand beyond its legal entitlement',
              },
              {
                index: 3,
                content:
                  ' Use of intimidating words or actions in relation to transactions',
              },
              {
                index: 4,
                content:
                  ' An action to defame the reputation or interfere with the business of the Company by spreading a rumor, using fraudulent means or resorting to force',
              },
              {
                index: 5,
                content: ' Other equivalent actions of the above',
              },
            ],
          },
          {
            index: 4,
            content:
              ' The Company shall be able to immediately cancel this Contract without any notice if the user has any circumstances that violate the preceding paragraphs or if the possibility is high. In such a case, the Company shall not be liable for any damages caused to the user even if such cancellation causes any damage to the user.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Article 19 (Period of Validity/Term of Continuation)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' This Contract shall survive the establishment under Article 4 paragraph 3 until such time as the following cases arise.                    ',
            innerList: [
              {
                index: 1,
                content:
                  ' In the case where a user leaves an affiliated company that they were employed at the time of user registration',
              },
              {
                index: 2,
                content:
                  ' In the case where the user offers to cancel the user registration by the dedicated application',
              },
              {
                index: 3,
                content:
                  ' In the case where the Company has exercised the right to cancel the Contract in accordance with the provisions of Article 18 paragraph 4 or Article 20',
              },
              {
                index: 4,
                content:
                  ' In the case where the affiliated company contract is terminated for any reason',
              },
            ],
          },
          {
            index: 2,
            content:
              ' Regardless of the reason, if this Contract is terminated, the user will not be able to use the Service thereafter. In addition, even if an application for the use of the Service pursuant to Article 5 is made during the term of the Contract, if the withdrawal is not executed at the end of the Contract, the application shall be handled as invalid.',
          },
          {
            index: 3,
            content:
              ' The provisions of Articles 13, 14 and 21 to 26 shall survive the termination of the Contract, regardless of expiration, premature termination, or any other termination reason.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Article 20 (Cancellation of Contract)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' The Company shall be able to immediately cancel the Contract without giving any notice if the user has any of the following reasons.                    ',
            innerList: [
              {
                index: 1,
                content: ' When any of the articles of the Terms are violated',
              },
              {
                index: 2,
                content:
                  ' When it is found that registration information or other information submitted by the user to the Company is false',
              },
              {
                index: 3,
                content:
                  ' When you use or try to use the Service for any purpose or method that may cause damage to the Company, other users or other third parties',
              },
              {
                index: 4,
                content:
                  ' When the user interrupts or attempts to interrupt the operation of the Service by any means',
              },
              {
                index: 5,
                content:
                  ' When applying for or was applied for bankruptcy proceedings',
              },
              {
                index: 6,
                content:
                  ' When becoming in a state of a payment suspension by such as by non-payment procedures like self-issued dishonored bills or checks',
              },
              {
                index: 7,
                content:
                  ' When there is a petition for seizure, temporary seizure, temporary disposition, forced execution or auction',
              },
              {
                index: 8,
                content:
                  ' When users have received a disposition for failure to pay taxes or public dues',
              },
              {
                index: 9,
                content:
                  ' In the case of death, or when you receive an appeal for the commencement of guardianship, the commencement of curatorship, or the commencement of assistance',
              },
              {
                index: 10,
                content:
                  ' When there is no response to the communication from our Company',
              },
              {
                index: 11,
                content:
                  ' When the Company decides that the continuation of user registration is not appropriate',
              },
              {
                index: 12,
                content:
                  ' When other situations conforming to the preceding items occur',
              },
            ],
          },
          {
            index: 2,
            content:
              ' If there is an event in which the Company should release or cancel an affiliated company contract due to reasons such as credit insecurity, or due to other reasons in which the Company becomes unable to provide the Service to the affiliated company, this contract may be terminated immediately without notice to the user.',
          },
        ],
        bracketList: [],
      },
      {
        title: 'Article 21 (Compensation of Damages)',
        content:
          "Users shall be liable for any damages caused to the Company with respect to the Contract due to unlawful acts, default on financial obligations or any other user's responsibility.",
        list: [],
        bracketList: [],
      },
      {
        title: 'Article 22 (Disclaimer)',
        content: '',
        list: [
          {
            index: 1,
            content:
              ' We do not take any responsibility for any damage caused to the user due to the following reasons.                    ',
            innerList: [
              {
                index: 1,
                content:
                  ' Impossibility of performance, performance delay or other debt nonfulfillment caused by fire, blackout, natural disaster or other force majeure',
              },
              {
                index: 2,
                content:
                  ' The leak of authentication information such as ID and passwords even though reasonable security measures were taken for this Service provided by our Company (including, but not limited to, cyber-attacks by third parties and leaks due to unauthorized acquisition)',
              },
              {
                index: 3,
                content:
                  ' Communication troubles etc. that occurred despite taking reasonable safety measures for the system of this Service provided by our Company',
              },
            ],
          },
          {
            index: 2,
            content:
              ' With regard to the Service, if any complaints or disputes related to wage payment, other debts and credits, or labor-management relations, etc., occur between the user and the affiliated company, the problem shall be settled between the user and the affiliated company, and We will not take any responsibility.',
          },
          {
            index: 3,
            content:
              " Upon use of the Service by the user, if the Company causes damages to the user, the Company's liability shall be limited to the total amount of the usage fee actually incurred by the user in the past one year, going back from the point of occurrence of the damage event. However, this does not apply if the Company has intentional or gross negligence.",
          },
        ],
        bracketList: [],
      },
      {
        title: 'Article 23 (Separate Independence)',
        content:
          'Even if any of the articles of the Terms is invalidated or unenforceable, such invalidity or imprisonment shall not affect the validity or binding of the other articles.',
        list: [],
        bracketList: [],
      },
      {
        title: 'Article 24 (Government Law)',
        content:
          'This Contract is established under Japanese law and shall be interpreted and operated in accordance with Japanese law.',
        list: [],
        bracketList: [],
      },
      {
        title: 'Article 25 (Agreement Jurisdiction)',
        content:
          'If the user and the Company are to resolve a dispute between the parties under this Contract by trial, the Tokyo District Court or Tokyo Summary Court shall be the exclusive agreement jurisdiction court of the first trial, depending on the amount of the complaint.',
        list: [],
        bracketList: [],
      },
      {
        title: 'Article 26 (Consultation)',
        content:
          'If there is any doubt about the interpretation of matters not stipulated in the Terms, etc. or the provisions of the Terms, etc., the user and the Company shall resolve them through good-faith consultation on the basis of their credibility.',
        list: [],
        bracketList: [],
      },
    ],
    endOfDocument: 'End of Document',
    corporateName: 'Metaps Payment Inc.',
    forUsers: 'For Users',
    privacyPolicy: 'Privacy Policy',
    updateHistories: [
      'First Edition Formulated Date: June 5, 2018',
      'Revised on June 22, 2018',
      'Revised on July 19, 2018',
      'Revised on September 7, 2018',
      'Revised on August 26, 2019',
    ],
    privacyPolicies: [
      {
        title: '1. Regarding the Acquiring and Use of Personal Information',
        content:
          'We will collect and use personal information only within the scope of the purposes specified in advance. In addition, we will take measures to prevent unauthorized use. If it is necessary to use personal information beyond the scope of the specified purpose, we will inform the purpose in advance. If you do not agree with the new purposes in which your personal information is to be used, you may refuse such use at your own discretion. (Regarding the purpose of use of personal information, please refer to Published Announcement 1)',
        list: [],
        bracketList: [],
      },
      {
        title:
          '2. Disclosure and Provision of Personal Information to Third Parties',
        content:
          'We will not disclose or provide personal information to third parties except in the following cases.',
        list: [
          {
            index: 1,
            content: 'Cases where we have agreement from the concerned person.',
          },
          {
            index: 2,
            content:
              'Cases where the information is disclosed in such a way so that identification of the individual is not possible.',
          },
          {
            index: 3,
            content:
              'Cases where disclosure and provision are done to the extent necessary to companies that have signed a confidentiality agreement with our company in advance (for example, an outsourcing company).',
          },
          {
            index: 4,
            content:
              'In cases of joint use (For cases in which our company uses personal information for joint use, please refer to the Published Announcement 1(4)).',
          },
          {
            index: 5,
            content:
              'Cases where payment is required for using paid services or ordering products. (Personal information may be exchanged with financial institutions, etc. This is to confirm the validity of bank accounts and credit cards.)',
          },
          {
            index: 6,
            content:
              'When required by laws and regulations.(For correspondence to requests for disclosure of personal information, etc., please refer to Published Announcement 3)',
          },
        ],
        bracketList: [],
      },
      {
        title: '3. Management of Personal Information',
        content:
          'We will conduct preventive and corrective measures to prevent the leakage, damage, and loss of personal information, and we will manage personal information safely.',
        list: [],
        bracketList: [],
      },
      {
        title:
          '4. Compliance with Relevant Laws and Regulations and Other Norms',
        content:
          'We will comply with applicable laws and regulations, country guidelines, and other norms regarding the provided personal information.',
        list: [],
        bracketList: [],
      },
      {
        title: '5. Review of Personal Information Protection Management System',
        content:
          'We will maintain an internal system to protect personal information held by our company and use it as effective. In addition, we will strive to continuously improve our personal information protection management system (including personal information protection regulations and related regulations) as needed.',
        list: [],
        bracketList: [],
      },
      {
        title:
          '6. Response to Complaints and Consultations Regarding Personal Information',
        content:
          'We will accept complaints and consultations regarding personal information held by our company at the following consultation desk.\nCustomer Consultation Desk e-mail: c-check@metaps-payment.com\nRecruitment Applicant Consultation e-mail: saiyo@metaps-payment.com',
        list: [],
        bracketList: [],
      },
    ],
    faq: 'FAQ',
    logoutConfirm: 'Logging out. Are you sure?',
    logoutConfirmDescription:
      'Re-entering customer number and password is needed when you log in again. ',
    languageSettings: 'Language settings',
    japanese: 'Japanese',
    english: 'English',
    vietnamese: 'Vietnamese',
    howTo: 'How To Use CRIA',
    bioAuth: 'Bio authentication',
    bioAuthNotification:
      'If you choose ON, biometric authentication will be enabled when you enter the app password at registration time. ',
    appVersion: 'app version',
    goBack: 'Return to setting screen',
    needUpdate: 'You need to restart the app for the setting to take effect. ',
    showJa:
      ' Some notifications are not supposed to be translated. Some will be displayed in Japanese. ',
  },
  userInfo: {
    information: 'Registration Information',
    company: "affiliated company's name",
    partners: 'Partners',
    employeeCode: 'Employee Code',
    account: 'Registered account',
    mailAddress: 'Mail Address',
    branchCode: 'Branch Code',
    depositType: 'Deposit Type',
    disabled: 'Application',
    accountHolder: 'Account Holder',
    phoneNumber: 'Phone Number',
    status: 'Currently inoperative',
  },
  howToCash: {
    annotation: 'How to Withdraw Cash from 7-Eleven',
    anyone: `Available for anyone${'\n'}to use, hassle-free.`,
    selectTop:
      'Select “Transaction without card using codes” on the top right corner of the screen on a Seven Bank ATM.',
    enterPartnerCode: 'Enter partner code.',
    enterCustomerNumber: 'Enter Customer Number.',
    enterConfirmationNumber: 'Enter confirmation number.',
    checkAndConfirm: 'Check and confirm the transaction details and amount.',
    takeMoney: 'Take your money from the machine.',
    takeReceipt: 'Take your receipt.',
  },
  alert: {
    error: 'Error',
    inputError: 'Input Error',
    notice: 'Notice',
    confirm: 'Confirm',
    customerNumberEmpty: 'Please enter your Customer Number',
    passwordEmpty: 'Please enter your password',
    customerNumberNotInt: 'Customer Number must consist of numbers',
    customerNumberNoMatchLength: 'Customer Number must be 8 digits long',
    passwordTooLong: 'Password must be shorter than 256 characters',
    loginNotAllowed: 'Either your customer number or password is wrong',
    contactEmpty: 'Enter email address or phone number',
    mailAddressInvalid: 'Email address is not in proper format',
    forceUpdate: 'Please update the app to the latest version',
    moveAppStore: 'App Store will open now. Continue?',
    movePlayStore: 'Google Play will open now. Continue?',
    maintenance: 'We are under maintenance. Please try again later.',
    serverError: 'A server error has occurred',
  },
  initial: {
    first: 'Begining',
    didGetMail: 'Have you received the invitation email?',
    gotMail:
      'After your company registers your customer information with CRIA, you will receive an invitation email. ',
    received: 'Received',
    notReceived: 'NOT Received',
    contactCompany: 'Please contact your affiliated company',
    contactCompanyDescription:
      'Invitation email will be sent from your affiliated company. Please contact your company and restart CRIA ',
    didSetPassword: 'Have you set the password ?',
    setPassword:
      'You can set your password via the URL in the invitation email. ',
    set: 'Setting is done',
    notSet: 'Not set yet',
    settingPassword: 'Please set your password',
    settingPasswordDescription:
      'You will need a preset password to be able to log in. Please set a password from the URL provided in the invitation email. If you cannot find the invitation email, please ask your company to resend it. ',
  },
  initial2: {
    title: 'Attention',
    description:
      'The displayed registration amount will be updated by your affiliated company. Please contact the affiliated company for inquiries regarding the renewed amount and confirmation / change of registration information. ',
    start: 'Start',
  },
};

export default dictionary;
