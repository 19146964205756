import ReportError from './reportError';

export const VERIFY_AUTH_TOKEN = 'VERIFY_AUTH_TOKEN';
export const VERIFY_AUTH_TOKEN_SUCCESS = 'VERIFY_AUTH_TOKEN_SUCCESS';
export const VERIFY_AUTH_TOKEN_ERROR = 'VERIFY_AUTH_TOKEN_ERROR';

const verifyAuthToken = () => ({
  type: VERIFY_AUTH_TOKEN as typeof VERIFY_AUTH_TOKEN,
});

interface VerifyAuthTokenSuccess {
  type: typeof VERIFY_AUTH_TOKEN_SUCCESS;
  payload: {
    authToken: string;
    isAppPasswordSet: boolean;
  };
}

export type VerifyAuthTokenAction =
  | ReturnType<typeof verifyAuthToken>
  | VerifyAuthTokenSuccess
  | ReportError<typeof VERIFY_AUTH_TOKEN_ERROR>;

export default verifyAuthToken;
